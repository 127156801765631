import React from 'react';
import { Card, CardBody, Col, Label, Modal, Row } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from "yup"
import PrimaryButton from '../Buttons/PrimaryButton';
import SecountryBtn from '../Buttons/SecountryBtn';
import { useDispatch } from 'react-redux';
import { approveOrRejectSignupRequest } from 'store/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SignupRequestStatusChange = ({ showModal, toggle, modalTitle, id }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const tipForm = {
        description: "",
    }

    const tipSchema = Yup.object().shape({
        description: Yup.string().required("Enter your description").trim().max(250, 'Description should not exceed 250 characters'),
    })

    const { register, handleSubmit, reset, formState: { errors, }, } = useForm({
        resolver: yupResolver(tipSchema),
        defaultValues: tipForm
    });

    const handleUpdate = () => {
        toggle();
        history.push("/marketgenius")
    }

    const onSubmit = (values) => {
        const data = {};
        data['reqId'] = id;
        data['reqStatus'] = "REJECTED";
        data['desc'] = values?.description;
        dispatch(approveOrRejectSignupRequest(data, handleUpdate))
    }

    return (
        <div>
            <Modal size="md" isOpen={showModal} centered={true}
                className='doc-modal confirmation'
            // toggle={toggle}
            >
                <div className="modal-header px-0 border-0">
                    <div className='head-item'>
                        <h1 className='page-title font-size-18 p-0'>{modalTitle}</h1>
                        <span onClick={toggle} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                    </div>
                </div>
                <div className="modal-body px-0">
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                        className="form-horizontal" >
                        <Card className='mb-0'>
                            <CardBody className='pb-0'>
                                <Row>
                                    <Col>
                                        <div className="mb-3">
                                            <Label className="form-label f-size-14">Description <span>*</span></Label>
                                            <textarea style={{ height: '180px' }} {...register("description")} className={errors.description?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                            {errors.description?.message ? <p className={`invalid-feedback`} >{errors.description?.message}</p> : null}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="mb-3 pt-3 d-flex btn-group">
                                    <PrimaryButton btnName="Save" showIcon={false} btnType={'submit'} />
                                    <SecountryBtn btnType='button' onClick={() => {
                                        reset();
                                    }} btnName='Clear' showIcon={false} />

                                </div>
                            </CardBody>
                        </Card>
                    </form>
                </div>
            </Modal>
        </div>
    );
};

export default SignupRequestStatusChange; 