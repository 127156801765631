import { combineReducers } from "redux"
import Layout from "./layout/reducer"
import authInfo from "./authInfo/reducer"
import { Login, ForgetPassword, ResetPassword, ForgetPasswordResendCode } from "./auth/reducers"
import { GetAdmin, AddAdmin, GetAdminById, ChangeStatus } from "./admin/reducers"
import { UpdateProfile, LoginPasswordChange, UpdateProfileImg } from './profile/reducer'
import { GetManageAdvisors, AddAdvisor, GetAdvisorById, AdvisorStatus, GetActiveAdvisors, GetSubmittedAdvisors, GetSubmittedById, ApproveOrRejectSignupRequest } from "./advisors/reducers"
import { GetManageTips, AddNewTips, TipsStatus, GetTipType, DeleteTips, GetTipsById, CloseTip, SetTipTarget } from "./tips/reducers"
import { GetMainCategory, GetSubCategory, AddCategory, AddSubCategory, GetSubCategoryById, GetCategoryById, SubCategoryStatus, ChangeCategoryStatus, GetSubCategoryDefaultById, GetCategoryDefaultById } from "./category/reducers"
import { GetFaq, AddFaq, GetFaqById, DeleteFaqById, FaqStatus } from "./faq/reducers"
import { GetAllUser, AddUser, GetUserById, UserStatus } from "./users/reducers"
import { SettingsUpdate, GetSettings, TradingSettingsUpdate, socialMediaLinks, UpdateSocialInfo } from "./settings/reducer"
import { GetAdminRoles, GetAdminRolesType } from "./roles/reducers"
import { GetReviewList, GetReviewDetails, GetReviewPublish } from './review/reducer';
import { GetMarketto, SuggestionType ,GetCount} from './dashboard/reducers';

const rootReducer = combineReducers({
  Layout,
  Login,
  ForgetPassword,
  authInfo,
  GetAdmin,
  AddAdmin,
  GetAdminById,
  ChangeStatus,
  ResetPassword,
  ForgetPasswordResendCode,
  UpdateProfile,
  LoginPasswordChange,
  UpdateProfileImg,
  AddAdvisor,
  GetManageAdvisors,
  GetAdvisorById,
  AdvisorStatus,
  GetActiveAdvisors,
  GetSubmittedAdvisors,
  GetSubmittedById,
  GetManageTips,
  AddNewTips,
  GetTipsById,
  TipsStatus,
  DeleteTips,
  GetTipType,
  GetMainCategory,
  GetSubCategory,
  GetSubCategoryDefaultById,
  AddCategory,
  AddSubCategory,
  GetSubCategoryById,
  GetCategoryById,
  SubCategoryStatus,
  ChangeCategoryStatus,
  GetCategoryDefaultById,
  GetFaq,
  AddFaq,
  GetFaqById,
  DeleteFaqById,
  FaqStatus,
  GetAllUser,
  AddUser,
  GetUserById,
  UserStatus,
  SettingsUpdate,
  GetSettings,
  GetAdminRoles,
  GetAdminRolesType,
  GetReviewList,
  GetReviewDetails,
  GetReviewPublish,
  ApproveOrRejectSignupRequest,
  GetCount,
  GetMarketto,
  SuggestionType,
  TradingSettingsUpdate,
  socialMediaLinks,
  UpdateSocialInfo,
  CloseTip,
  SetTipTarget
})

export default rootReducer
