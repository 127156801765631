import React, { useState } from 'react';
import { Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useHistory } from "react-router-dom"
import { changeAdvisorStatus, userStatusChange } from 'store/actions';
import { useDispatch } from 'react-redux';
import Confirmation from '../Modals/Confirmation';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ManageUserCard = ({ user }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isadvisorId, setadvisorId] = useState();
    const [changeStatusModal, setChangeStatusModal] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const viewUserPath = (id) => {
        history.push(`/users/view-user/${id}`);
    }
    function activeToggleModal() {
        setChangeStatusModal(!changeStatusModal);
    }


    //===Users Call to Actions====
    //User Status Update
    const userUpdateStatus = () => {
        let status
        if (user?.status === "INACTIVE") {
            status = "ACTIVE"
        } else {
            status = "INACTIVE"
        }
        dispatch(userStatusChange(status, isadvisorId));
        setChangeStatusModal(false);
    };
    const userDetailsUpdate = () => {
        history.push(`/users/update-user/${user._id}`)
    }
    return (
        <>
            <div className='manage-card'>
                <Card className='p-0 m-0'>
                    <CardBody className='p-0'>
                        <div className='d-flex justify-content-between align-items-center p-3'>
                            <div className='d-flex p-1'>
                                <div className={`profile ${user?.status?.toLowerCase()}`}>
                                    <div className={`active-status`}></div>
                                    {
                                        user?.profilePath ? <span className='profile-img' ><img src={user?.profilePath} alt="profile" /></span> :
                                            <span>{user?.firstName?.charAt(0)?.toUpperCase()}</span>
                                    }
                                </div>
                                <div className='ps-3'>
                                    <h5 className='m-0 text-capitalize'>{user?.firstName}</h5>
                                    <p className='m-0'>{user?.email}</p>
                                </div>
                            </div>
                            <div className=''>

                                <div className="d-flex">
                                    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'top'} end={true}>
                                        <DropdownToggle caret className='more-option'>
                                            <i className='bx bx-dots-vertical-rounded'></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => { viewUserPath(user?._id) }}>View</DropdownItem>

                                            <DropdownItem onClick={() => { userDetailsUpdate() }}>Edit</DropdownItem>


                                            <DropdownItem disabled={user?.status === "INCOMPLETE" || user?.status === "ACTIVE"}>
                                                <div onClick={() => {
                                                    activeToggleModal();
                                                    setadvisorId(user?._id)
                                                }}>Activate</div>
                                            </DropdownItem>
                                            <DropdownItem disabled={user?.status === "INCOMPLETE" || user?.status === "INACTIVE"}>
                                                <div onClick={() => {
                                                    activeToggleModal();
                                                    setadvisorId(user?._id)
                                                }}>Suspend</div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className='crd-details p-3'>
                            <div>
                                <span>Mobile Number</span>
                                <p>{user?.mobileNumber}</p>
                            </div>
                            {/* <div className='self-end'>
                                <span>Status</span>
                                <p className='text-capitalize m-0'>{user?.status}</p>
                            </div> */}

                        </div>
                    </CardBody>
                </Card>
                <Confirmation
                    showModal={changeStatusModal}
                    toggle={activeToggleModal}
                    primaryAction={userUpdateStatus}
                    modalTitle={`Confirmation!`}
                    primaryBtn={`${user?.status === "INACTIVE" ? 'Activate' : 'Suspend'}`}
                    secountBtn="Cancel"
                    description={`Are you sure, do you want to  ${user?.status === "ACTIVE" ? 'suspend' : 'activate'} this user "${user?.firstName} ${user?.lastName}"?`}
                />
            </div>
        </>
    );
};

export default ManageUserCard;