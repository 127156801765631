import {
    GET_ALL_ADVISOR,
    GET_ALL_ADVISOR_SUCCESS,
    GET_ALL_ADVISOR_FAIL,
    ADD_ADVISOR,
    ADD_ADVISOR_SUCCESS,
    ADD_ADVISOR_FAIL,
    ADVISOR_LOADING,
    GET_ADVISOR_BY_ID,
    GET_ADVISOR_BY_ID_SUCCESS,
    GET_ADVISOR_BY_ID_FAIL,
    ADVISOR_STATUS,
    ADVISOR_STATUS_SUCCESS,
    ADVISOR_STATUS_FAIL,
    ACTIVE_ADVISROR,
    ACTIVE_ADVISROR_SUCCESS,
    ACTIVE_ADVISROR_FAIL,
    GET_SUBMITTED_ADVISROR,
    GET_SUBMITTED_ADVISROR_SUCCESS,
    GET_SUBMITTED_ADVISROR_FAIL,
    SUBMITTED_ADVISROR_ID,
    SUBMITTED_ADVISROR_ID_SUCCESS,
    SUBMITTED_ADVISROR_ID_FAIL,
    APPROVE_OR_REJECT_SIGNUP_REQUEST,
    APPROVE_OR_REJECT_SIGNUP_REQUEST_SUCCESS,
    APPROVE_OR_REJECT_SIGNUP_REQUEST_FAIL,
} from "./actionTypes"

//Get Advisor
export const getAllAdvisor = ({ search, currentPage, dataPerPage }) => ({
    type: GET_ALL_ADVISOR,
    payload: { search, currentPage, dataPerPage }
});
export const getAllAdvisorSuccess = Advisors => ({
    type: GET_ALL_ADVISOR_SUCCESS,
    payload: Advisors,
});
export const getAllAdvisorFail = error => ({
    type: GET_ALL_ADVISOR_FAIL,
    payload: error,
});

//Get Advisor By Id
export const getAdvisorById = id => ({
    type: GET_ADVISOR_BY_ID,
    payload: id,
})
export const getAdvisorByIdSuccess = Advisors => ({
    type: GET_ADVISOR_BY_ID_SUCCESS,
    payload: Advisors,
})
export const getAdvisorIdFail = error => ({
    type: GET_ADVISOR_BY_ID_FAIL,
    payload: error,
})

//Change Advisor Status
export const changeAdvisorStatus = (status, id) => {

    return {
        type: ADVISOR_STATUS,
        payload: { status, id },
    }
}
export const changeAdvisorStatusSuccess = admin => {
    return {
        type: ADVISOR_STATUS_SUCCESS,
        payload: admin,
    }
}
export const changeAdvisorStatusError = error => {
    return {
        type: ADVISOR_STATUS_FAIL,
        payload: error,
    }
}

//Add new Advisor

export const AddNewAdvisor = (advisor, history, id) => {
    return {
        type: ADD_ADVISOR,
        payload: { advisor, history, id }
    }
}
export const AddNewAdvisorSuccess = advisor => {
    return {
        type: ADD_ADVISOR_SUCCESS,
        payload: advisor
    }
}

export const AddNewAdvisorError = error => {
    return {
        type: ADD_ADVISOR_FAIL,
        payload: error
    }
}
export const AddNewAdvisorLoading = (loading) => {
    return {
        type: ADVISOR_LOADING,
        payload: loading
    }
}

//Get Active ADvisors
export const getActiveAdvisor = () => ({
    type: ACTIVE_ADVISROR,
});
export const getActiveAdvisorSuccess = activeList => ({
    type: ACTIVE_ADVISROR_SUCCESS,
    payload: activeList,
});
export const getActiveAdvisorFail = error => ({
    type: ACTIVE_ADVISROR_FAIL,
    payload: error,
});

//Get Submitted Advisors
export const getSubmittedAdvisor = (data) => ({
    type: GET_SUBMITTED_ADVISROR,
    payload: data
});
export const getSubmittedAdvisorSuccess = submittedList => ({
    type: GET_SUBMITTED_ADVISROR_SUCCESS,
    payload: submittedList,
});
export const getSubmittedAdvisorFail = error => ({
    type: GET_SUBMITTED_ADVISROR_FAIL,
    payload: error,
});

//Get Submitted Advisors Id
export const getSubmittedAdvisorId = id => ({
    type: SUBMITTED_ADVISROR_ID,
    payload: id,
})
export const getSubmittedAdvisorIdSuccess = submittedList => ({
    type: SUBMITTED_ADVISROR_ID_SUCCESS,
    payload: submittedList,
})
export const getSubmittedAdvisorIdFail = error => ({
    type: SUBMITTED_ADVISROR_ID_FAIL,
    payload: error,
})

//Get Submitted Advisors Id
export const approveOrRejectSignupRequest = (data, handleUpdate) => ({
    type: APPROVE_OR_REJECT_SIGNUP_REQUEST,
    payload: { data, handleUpdate },
})

export const approveOrRejectSignupRequestSuccess = data => ({
    type: APPROVE_OR_REJECT_SIGNUP_REQUEST_SUCCESS,
    payload: data,
})
export const approveOrRejectSignupRequestFail = error => ({
    type: APPROVE_OR_REJECT_SIGNUP_REQUEST_FAIL,
    payload: error,
})