import React from "react"
import { Redirect } from "react-router-dom"
// Profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "../pages/Authentication/ResetPassword"
import AdminList from "pages/Admin/AdminList"
import AdminForm from "pages/Admin/AdminForm"
import UnAuthorized from "components/UnAuthorized"
import InternalServer from "components/InternalServer"
import BadRequest from "components/BadRequest"
import InnerScreenNotFound from "components/InnerScreenNotFound"
import Dashboard from "pages/Dashboard/Dashboard"
import Advisors from "pages/Advisors/Advisors"
import ViewAdvisor from "pages/Advisors/ViewAdvisor"
import AdvisorForm from "pages/Advisors/AdvisorForm"
import ManageTips from "pages/Tips/ManageTips"
import AddTips from "pages/Tips/AddTips"
import FaqList from "pages/Faq/FaqList"
import FaqForm from "pages/Faq/FaqForm"
import ViewFaq from "pages/Faq/ViewFaq"
import Users from "pages/users/Users"
import UserForm from "pages/users/UserForm"
import ViewTips from "pages/Tips/ViewTips"
import Settings from "pages/Settings/Settings"
import UserDetails from "pages/users/UserDetails"
import Review from "pages/Reviews/Review"
import SubmittedAdvisor from "pages/Advisors/SubmittedAdvisor"
import ViewSubmitted from "pages/Advisors/ViewSubmitted"

import Category from "pages/Category/Category"
import subCategory from "pages/Category/CategoryDetails"
import AdvisorsMainComponent from "pages/Advisors/AdvisorsMainComponent"

const authProtectedRoutes = [
  { path: "/profile", component: UserProfile, role: ["SUPER_ADMIN", "ADMIN", "ADVISOR"] },
  { path: "/admin", component: AdminList, role: ["SUPER_ADMIN"] },
  { path: "/admin/add-new-admin", component: AdminForm, role: ["SUPER_ADMIN"] },
  { path: "/admin/update-admin/:id", component: AdminForm, role: ["SUPER_ADMIN"] },
  { path: "/notfound", component: InnerScreenNotFound, role: ["SUPER_ADMIN", "ADMIN", "ADVISOR"] },
  { path: "/dashboard", component: Dashboard, role: ["SUPER_ADMIN", "ADMIN", "ADVISOR"] },
  { path: "/marketgenius", component: AdvisorsMainComponent, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/marketgenius/:tab", component: AdvisorsMainComponent, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/marketgenius/add-new-marketgenius", component: AdvisorForm, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/marketgenius/update-marketgenius/:id", component: AdvisorForm, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/view-marketgenius/:id", component: ViewAdvisor, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/marketgenius/view-submitted/:id", component: ViewSubmitted, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/geniustalk", component: ManageTips, role: ["SUPER_ADMIN", "ADMIN", "ADVISOR"] },
  { path: "/geniustalk/add-new-geniustalk", component: AddTips, role: ["ADVISOR"] },
  // { path: "/tips/update-tips/:id", component: AddTips, role: ["SUPER_ADMIN", "ADMIN", "ADVISOR"] },
  { path: "/geniustalk/view-geniustalk/:id", component: ViewTips, role: ["SUPER_ADMIN", "ADMIN", "ADVISOR"] },
  { path: "/faq", component: FaqList, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/faq/add-new-faq", component: FaqForm, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/faq/update-faq/:id", component: FaqForm, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/faq-details/:id", component: ViewFaq, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/users", component: Users, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/users/add-new-user", component: UserForm, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/users/update-user/:id", component: UserForm, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/users/view-user/:id", component: UserDetails, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/settings", component: Settings, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/category", component: Category, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/category/:id", component: Category, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/category/sub-category/:id", component: subCategory, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/category/update-subcategory/:id", component: subCategory, role: ["SUPER_ADMIN", "ADMIN"] },
  { path: "/reviews", component: Review, role: ["SUPER_ADMIN", "ADMIN", "ADVISOR"] },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "*", component: () => <Redirect to="/notfound" /> },
]
const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:email", component: ResetPassword },
  { path: "/unauthorized", component: UnAuthorized },
  { path: "/servererror", component: InternalServer },
  { path: "/badrequest", component: BadRequest },



]


export { authProtectedRoutes, publicRoutes }
