import {
  GET_SETTINGS_FAIL,
  GET_SETTINGS_LOADING,
  GET_SETTINGS_SUCCESS,
  SETTINGS_UPDATE,
  SETTINGS_UPDATE_FAIL,
  SETTINGS_UPDATE_LOADING,
  SETTINGS_UPDATE_SUCCESS,
  TRADINGMETER,
  TRADINGMETER_SUCCESS,
  TRADINGMETER_FAIL,
  GET_SOCIAL_LINKS_SUCCESS,
  GET_SOCIAL_LINKS_FAIL,
  UPDATE_SOCIAL_LINKS_SUCCESS,
  UPDATE_SOCIAL_LINKS_FAIL,
  UPDATE_SOCIAL_LINKS
} from "./actionType"

//update settings
const initialState = {
  error: "",
  success: "",
  loading: false
}

export const SettingsUpdate = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_UPDATE:
      state = {
        ...state,
      }
      break
    case SETTINGS_UPDATE_SUCCESS:

      state = { ...state, success: action.payload, loading: false }
      break
    case SETTINGS_UPDATE_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case SETTINGS_UPDATE_LOADING:
      state = {
        loading: action.payload
      }
  }
  return state
}

// Get settings
const initialState_GetSettings = {
  data: [],
  error: "",
  success: "",
  loading: false
}

export const GetSettings = (state = initialState_GetSettings, action) => {
  switch (action.type) {
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case GET_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_SETTINGS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
  }
  return state
}

//update settings
const tradinginitialState = {
  error: "",
  success: "",
  loading: false
}

export const TradingSettingsUpdate = (state = tradinginitialState, action) => {
  switch (action.type) {
    case TRADINGMETER:
      state = {
        error: "",
        success: "",
        loading: true,
      }
      break
    case TRADINGMETER_SUCCESS:

      state = { ...state, success: action.payload, loading: false }
      break
    case TRADINGMETER_FAIL:
      state = { ...state, error: action.payload, loading: false }

  }
  return state
}

//Social Media Links

const socialMediaInitialState = {
  data: [],
  error: "",
  success: "",
  loading: false
}

export const socialMediaLinks = (state = socialMediaInitialState, action) => {
  switch (action.type) {
    case GET_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case GET_SOCIAL_LINKS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

  }
  return state
}

//update Social info
const socialMediaInitial = {
  data: null,
  error: null,
  loading: false
}

export const UpdateSocialInfo = (state = socialMediaInitial, action) => {
  switch (action.type) {
    case UPDATE_SOCIAL_LINKS:
      return {
        error: null,
        data: null,
        loading: true
      }
    case UPDATE_SOCIAL_LINKS_SUCCESS:
      return {
        error: null,
        data: action.payload,
        loading: false
      }
    case UPDATE_SOCIAL_LINKS_FAIL:
      return {
        data: null,
        error: action.payload,
        loading: false
      }
  }
  return state
}