import { REVIEW_DETAILS, REVIEW_DETAILS_FAIL, REVIEW_DETAILS_SUCCESS, REVIEW_LIST, REVIEW_LIST_FAIL, REVIEW_LIST_SUCCESS, REVIEW_PUBLISH, REVIEW_PUBLISH_FAIL, REVIEW_PUBLISH_SUCCESS } from "./actionTypes"

//get Review list
const initialStateReviewList = {
    error: "",
    success: "",
    loading: false,
    data: null,
}

export const GetReviewList = (state = initialStateReviewList, action) => {
    switch (action.type) {
        case REVIEW_LIST:
            state = {
                ...state,
                loading: true,
            }
            break
        case REVIEW_LIST_SUCCESS:
            state = {
                ...state,
                success: "success",
                loading: false,
                data: action.payload,
            }
            break
        case REVIEW_LIST_FAIL:
            state = {
                ...state,
                error: action.payload,
                loading: false,
                data: null,
            }
            break
    }
    return state
}

//get Review list
const initialStateReviewDetails = {
    error: "",
    success: "",
    loading: false,
    data: null,
}

export const GetReviewDetails = (state = initialStateReviewDetails, action) => {
    switch (action.type) {
        case REVIEW_DETAILS:
            state = {
                ...state,
                loading: true,
            }
            break
        case REVIEW_DETAILS_SUCCESS:
            state = {
                ...state,
                success: "success",
                loading: false,
                data: action.payload,
            }
            break
        case REVIEW_DETAILS_FAIL:
            state = {
                ...state,
                error: action.payload,
                loading: false,
                data: null,
            }
            break
    }
    return state
}

//get Review list
const initialStateReviewPublish = {
    error: "",
    success: "",
    loading: false,
    data: null,
}

export const GetReviewPublish = (state = initialStateReviewPublish, action) => { 
    switch (action.type) {
        case REVIEW_PUBLISH:
            state = {
                ...state,
                loading: true,
            }
            break
        case REVIEW_PUBLISH_SUCCESS:
            state = {
                ...state,
                success: "success",
                loading: false,
                data: action.payload,
            }
            break
        case REVIEW_PUBLISH_FAIL:
            state = {
                ...state,
                error: action.payload,
                loading: false,
                data: null,
            }
            break
    }
    return state
}

