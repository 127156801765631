import ViewAdvisorSkeleton from 'components/Common/Skeleton/ViewAdvisorSkeleton';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelect } from 'react-select-search';
import { Card, CardBody, Container } from 'reactstrap';
import { getAdvisorById, getTipsById } from 'store/actions';

const ViewTips = () => {
    let { id } = useParams();
    const dispatch = useDispatch();

    document.title = "View MarketGenius | FiDeal AdminPanel"

    const tipsData = useSelector(state => (state?.GetTipsById?.tipsData?.tips));
    const tipsDataLoading = useSelector(state => (state?.GetAdvisorById));

    useEffect(() => {
        if (id) {
            dispatch(getTipsById(id));
        }
    }, [id])
    return (
        <React.Fragment>
            <div className="page-content view-advisor">
                <Container fluid>
                    <h1 className='page-title'>View GeniusTalk</h1>

                    <div>
                        {tipsDataLoading === false ? (
                            <ViewAdvisorSkeleton />
                        ) : (
                            <div className='manage-card'>
                                <Card className='p-0'>
                                    <CardBody className='p-0'>
                                        <div className='d-flex justify-content-between align-items-center p-3'>
                                            <div className='d-flex p-1'>
                                                <div className='profile'>
                                                    <div className={`active-status ${tipsData?.status?.toLowerCase()}`}></div>
                                                    <span>{tipsData?.advisor?.firstName?.charAt(0)?.toUpperCase()}</span>
                                                </div>
                                                <div className='ps-3 name-head'>
                                                    <h5 className='m-0 text-capitalize'>{tipsData?.advisor?.firstName}</h5>
                                                    <span>SEBI 1567136548</span>
                                                </div>
                                            </div>
                                            <div className={`view-status ${tipsData?.status.toLowerCase()}`}>
                                                <span className={`text-capitalize`}> {tipsData?.status.toLowerCase()}</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='advisor-body'>
                                            <div className='crd-details'>
                                                <div>
                                                    <span>Category</span>
                                                    <p>{tipsData?.category.label}</p>
                                                </div>
                                                <div>
                                                    <span>Subcategory
                                                    </span>
                                                    <p>{tipsData?.subcategory?.label}</p>
                                                </div>
                                                <div>
                                                    <span>GeniusTalk Type</span>
                                                    <p>{tipsData?.tipType?.label}</p>
                                                </div>
                                                <div>
                                                    <span>Stock Tip</span>
                                                    <p>{tipsData?.stockTip}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        )
                        }

                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ViewTips;