import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Container,
  CardTitle,
} from "reactstrap"
import { useParams } from "react-router-dom"
import Select from "react-select";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup"
import { useSelector, useDispatch } from "react-redux"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AddNew, getAdminById, getAdminRoleType, getAdminRoles } from "../../store/actions"
import PageLoader from '../../components/Common/PageLoader';
import PrimaryButton from "components/Common/Buttons/PrimaryButton";
import { GetRoles as roles } from "constants/AdminRoles";
import SecountryBtn from "components/Common/Buttons/SecountryBtn";

const AdminForm = props => {
  let { id } = useParams()
  document.title = `${!id ? "Add New Admin" : "Update Admin"} | FiDeal AdminPanel`

  const dispatch = useDispatch();

  const [rolelistvalue, setrolelistvalue] = useState(null);
  const [defaultRole, setDefaultRole] = useState()

  const { admin } = useSelector(state => ({ admin: state.GetAdminById.admin }));
  const loading = useSelector(state => ({ loaderstatus: state.AddAdmin.loading }));
  const userInfo = useSelector(state => (state?.authInfo?.data?.user));

  const isEditAdminEmail = !!id;

  const adminForm = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    role: "",
  }

  const adminFormSchema = Yup.object().shape({
    firstName: Yup.string().required("Enter first name").max(30, 'First name should not exceed 30 characters').trim(),
    lastName: Yup.string().required("Enter last name").max(30, 'Last name should not exceed 30 characters').trim(),
    email: Yup.string().email('Enter valid email address').required("Enter email address").max(60, 'Email address should not exceed 60 characters').trim(),
    mobileNumber: Yup.string().required("Enter phone number").max(15, 'Phone number should not exceed 15 characters').trim(),
    role: Yup.string().required("Select role").trim(),
  })

  const { register, handleSubmit, setValue, getValues, reset, clearErrors, formState: { errors, }, } = useForm({
    resolver: yupResolver(adminFormSchema),
    defaultValues: adminForm
  });

  const onSubmit = (values) => {
    if (id) {
      dispatch(AddNew(values, props.history, id))
    } else {
      dispatch(AddNew(values, props.history))
    }
  }

  const getUserData = () => {
    dispatch(getAdminById(id))
  }

  useEffect(() => {
    if (id) {
      getUserData()
    }
  }, [id])

  const adminRoles = useSelector(state => (state?.GetAdminRoles?.roles));
  const filterRole = adminRoles.filter((item) => {
    return item.roleType === "admin";
  });

  useEffect(() => {
    dispatch(getAdminRoles());
  }, []);

  useEffect(() => {
    if (roles) setrolelistvalue([{ label: "Select role", value: '' }, ...roles])
  }, [roles]);

  useEffect(() => {
    if (id) {
      setFormValues()
    }
  }, [admin, id, adminRoles]);

  const setFormValues = () => {
    reset(
      {
        firstName: admin?.firstName || "",
        lastName: admin?.lastName || "",
        email: admin?.email || "",
        mobileNumber: admin?.mobileNumber || "",
        role: admin?.role?._id || "",
      },
    )
    setDefaultRole({
      label: admin?.role?.label,
      value: admin?.role?.label._id
    });
    // setDefaultRole(admin?.role?.label)
    // const currentRole = roles?.filter((item) => admin?.role === item?.value);
    // if (currentRole.length == 1) {
    //   setDefaultRole(currentRole[0])
    // }
  }

  // useEffect(() => {
  //   dispatch(getAdminRoleType('admin'));
  // }, []);

  return (
    <React.Fragment>
      {loading.loaderstatus ? <PageLoader /> : ""}
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Admins" dashurl="/admin" breadcrumbItem={!id ? "Add New Admin" : "Update Admin"}
          />
          {
            !id &&
            <Card color="info" className="text-white-50">
              <CardBody>
                <CardTitle className="m-0 text-white">
                  <i className="mdi mdi-alert-circle-outline me-3" />After successful creation of account, login credential will be sent to email address of this account.
                </CardTitle>
              </CardBody>
            </Card>
          }
          <Row>
            <Col xl="12">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                className="form-horizontal" >

                <Card>
                  <CardBody>
                    <Row>
                      <Col md="4">
                        <div className="mb-3">
                          <Label className="form-label f-size-14">First name <span>*</span></Label>
                          <input type="text" {...register("firstName")} className={errors.firstName?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                          {errors.firstName?.message ? <p className={`invalid-feedback`} >{errors.firstName?.message}</p> : null}
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <Label className="form-label f-size-14">Last name <span>*</span></Label>
                          <input type="text" {...register("lastName")} className={errors.lastName?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                          {errors.lastName?.message ? <p className={`invalid-feedback`} >{errors.lastName?.message}</p> : null}
                        </div>
                      </Col>
                      {id === userInfo?.id ? (
                        ""
                      ) : (
                        <Col md="4">
                          <div className="mb-3">
                            <Label className="form-label f-size-14">Email address<span>*</span></Label>
                            <input type="email" {...register("email")} className={errors.email?.message ? 'form-control is-invalid' : '' + 'form-control'}
                              disabled={isEditAdminEmail}
                            />
                            {errors.email?.message ? <p className={`invalid-feedback`} >{errors.email?.message}</p> : null}
                          </div>
                        </Col>
                      )}
                      <Col md="4">
                        <div className="mb-3">
                          <div className={`position-relative drop-down`}>
                            <Label className="form-label f-size-14">Role<span>*</span></Label>
                            <Select
                              options={filterRole}
                              className={`${errors.role?.message ? 'is-invalid ' : ''} form-control p-0`}
                              classNamePrefix="custom_select"
                              search={true}
                              value={defaultRole}
                              name="role"
                              placeholder={`Select role`}
                              onChange={(value) => {
                                setDefaultRole(value)
                                setValue('role', value?._id)
                                clearErrors('role')
                              }}
                            />
                            {errors.role?.message ? <p className={`error-field`} >{errors.role?.message}</p> : null}
                          </div>
                        </div>
                      </Col>
                      <Col md="4">
                        <div className="mb-3">
                          <Label className="form-label f-size-14">Phone number <span>*</span></Label>
                          <input type="number" {...register("mobileNumber")} className={errors.mobileNumber?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                          {errors.mobileNumber?.message ? <p className={`invalid-feedback`} >{errors.mobileNumber?.message}</p> : null}
                        </div>
                      </Col>
                    </Row>
                    <div className="mb-3 pt-3 d-flex btn-group">
                      <PrimaryButton btnName="Save" showIcon={false} btnType={'submit'} />
                      <SecountryBtn btnType='button' onClick={() => {
                        if (id) {
                          setFormValues()
                        } else {
                          reset();
                          setDefaultRole({ label: "Select role", value: '' })
                        }
                      }} btnName='Clear' showIcon={false} />

                    </div>
                  </CardBody >
                </Card >
              </form >
            </Col >
          </Row >
        </Container >
      </div>
    </React.Fragment >
  )
}

export default AdminForm;
