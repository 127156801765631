import React, { useState } from "react"
import {
  Badge,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import PrimaryButton from "../Buttons/PrimaryButton"
import Confirmation from "../Modals/Confirmation"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { CategoryDefaultChange, categoryStatusChange, getMainCategory } from "store/actions"


const CategoryCard = ({ category, pop }) => {
  const dispatch = useDispatch()

  const history = useHistory()

  const [changecategoryModal, setChangeCategoryModal] = useState(false)
  const [getCategoryStatus, setgetCategoryStatus] = useState(null)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen(prevState => !prevState)

  //View Subcategory
  const viewSubCategory = id => {
    history.push(`/category/sub-category/${id}`)
  }

  // subcategory status update
  function activeToggleModal() {
    setChangeCategoryModal(!changecategoryModal)
  }

  const categoryUpdateStatus = () => {
    const status = {
      status: getCategoryStatus?.status === "INACTIVE" ? "ACTIVE" : "INACTIVE",
    }
    dispatch(categoryStatusChange(status, getCategoryStatus._id))
    setChangeCategoryModal(false)
  }

  const handleReloadCategory = () => {
    dispatch(getMainCategory());
  }

  //set default 
  const setAsDefault = () => {
    const isDefault = { isDefault: "YES" };
    const categoryId = category?._id;
    dispatch(CategoryDefaultChange(isDefault, categoryId, handleReloadCategory));
  };

  //publish and unpublish badge
  const getSubcategoryBadgeColor = status => {
    return status === "ACTIVE" ? "success" : "danger"
  }

  return (
    <>
      <div className="dashboard-card">
        <Card className="p-0 m-0">
          <CardBody className="p-0 ">
            <div className="card-details   d-flex justify-content-between align-items-center  p-3">
              <div className="d-flex align-items-center">
                <div className={`profile ${category?.status?.toLowerCase()}`}>
                  <div className={`active-status`}></div>
                  {/* <img src='https://picsum.photos/318/180' alt='category-img' /> */}
                </div>
                <div className="ps-3">
                  <h5 className=" m-0 text-capitalize">
                    {category?.label}
                    {category?.isDefault === "YES" ? (
                      <Badge className="text-dark ms-2" color="light">
                        {"Default"}
                      </Badge>
                    ) : (

                      ""
                    )}
                  </h5>
                  <span className=" m-0 text-capitalize">
                    {category?.callType?.toLowerCase()}
                  </span>
                  <div> <Badge
                    className="ms-0"
                    color={getSubcategoryBadgeColor(
                      category?.status,
                    )}
                  >
                    {category?.status === "ACTIVE"
                      ? "Published"
                      : "Unpublished"}
                  </Badge></div>
                </div>
              </div>
              <div className="d-flex justify-content-end p-1">

                <div>
                  <div className="d-flex">
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggle}
                      direction={"top"}
                      end={true}
                    >
                      <DropdownToggle caret className="more-option">
                        <i className="bx bx-dots-vertical-rounded"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            viewSubCategory(category?._id)
                          }}
                        >
                          View
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            pop(category)
                          }}
                        >
                          Edit
                        </DropdownItem>

                        <DropdownItem
                          disabled={category?.status === "ACTIVE" ? true : false}
                        >
                          <div
                            onClick={() => {
                              activeToggleModal()
                              setgetCategoryStatus(category)

                            }}
                          >
                            Publish
                          </div>
                        </DropdownItem>
                        <DropdownItem
                          disabled={category?.status === "INACTIVE" || category?.isDefault === "YES"}
                        >
                          <div
                            onClick={() => {
                              activeToggleModal()
                              setgetCategoryStatus(category)
                            }}
                          >
                            Unpublish
                          </div>
                        </DropdownItem>
                        {!(
                          category?.status === "INACTIVE" ||
                          category?.isDefault === "YES"
                        ) && (
                            <DropdownItem
                              onClick={() => {
                                setAsDefault();
                              }}
                            >
                              Set as Default
                            </DropdownItem>
                          )}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        {/* confirmation modal for publish and unpublish */}
        <Confirmation
          showModal={changecategoryModal}
          toggle={activeToggleModal}
          primaryAction={categoryUpdateStatus}
          modalTitle={`${//
            getCategoryStatus?.status === "INACTIVE" ? "Publish" : "Unpublish"
            } category`}
          primaryBtn={`${getCategoryStatus?.status === "INACTIVE" ? "Publish" : "Unpublish"
            }`}
          secountBtn="Cancel"
          description={`Are you sure, do you want to ${getCategoryStatus?.status === "ACTIVE" ? "unpublish" : "publish"
            } this category "${category?.label}"?`}
        />
      </div>
    </>
  )
}

export default CategoryCard
