// Get All Category
export const GET_CATEGORY = "GET_CATEGORY"
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS"
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL"

//Add  Category
export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY"
export const ADD_NEW_CATEGORY_SUCCESS = "ADD_NEW_CATEGORY_SUCCESS"
export const ADD_NEW_CATEGORY_FAIL = "ADD_NEW_CATEGORY_FAIL"

//Get Category by ID
export const GET_CATEGORY_ID = "GET_CATEGORY_ID"
export const GET_CATEGORY_ID_SUCCESS = "GET_CATEGORY_ID_SUCCESS"
export const GET_CATEGORY_ID_FAIL = "GET_CATEGORY_ID_FAIL"

//GEt category Status
export const GET_CATEGORY_STATUS = "GET_CATEGORY_STATUS"
export const GET_CATEGORY_STATUS_SUCCESS = "GET_CATEGORY_STATUS_SUCCESS"
export const GET_CATEGORY_STATUS_FAIL = "GET_CATEGORY_STATUS_FAIL"
//Get All SubCategory
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY"
export const GET_SUB_CATEGORY_SUCCESS = "GET_SUB_CATEGORY_SUCCESS"
export const GET_SUB_CATEGORY_FAIL = "GET_SUB_CATEGORY_FAIL"

//Add SubCategory
export const ADD_SUB_CATEGORY = "ADD_SUB_CATEGORY"
export const ADD_SUB_CATEGORY_SUCCESS = "ADD_SUB_CATEGORY_SUCCESS"
export const ADD_SUB_CATEGORY_FAIL = "ADD_SUB_CATEGORY_FAIL"

//Get  SubCategory By ID
export const GET_SUBCATEGORY_ID = "GET_SUBCATEGORY_ID"
export const GET_SUBCATEGORY_ID_SUCCESS = "GET_SUBCATEGORY_ID_SUCCESS"
export const GET_SUBCATEGORY_ID_FAIL = "GET_SUBCATEGORY_ID_FAIL"

//GEt Subcategory Status
export const GET_SUBCATEGORY_STATUS = "GET_SUBCATEGORY_STATUS"
export const GET_SUBCATEGORY_STATUS_SUCCESS = "GET_SUBCATEGORY_STATUS_SUCCESS"
export const GET_SUBCATEGORY_STATUS_FAIL = "GET_SUBCATEGORY_STATUS_FAIL"

//category Default
export const GET_CATEGORY_DEFAULT = " GET_CATEGORY_DEFAULT"
export const GET_CATEGORY_DEFAULT_SUCCESS = "GET_CATEGORY_DEFAULT_SUCCESS"
export const GET_CATEGORY_DEFAULT_FAIL = "GET_CATEGORY_DEFAULT_FAIL"

//subcategory Default
export const GET_SUBCATEGORY_DEFAULT = " GET_SUBCATEGORY_DEFAULT"
export const GET_SUBCATEGORY_DEFAULT_SUCCESS = "GET_SUBCATEGORY_DEFAULT_SUCCESS"
export const GET_SUBCATEGORY_DEFAULT_FAIL = "GET_SUBCATEGORY_DEFAULT_FAIL"