import {
    GET_ALL_ADMIN,
    GET_ALL_ADMIN_SUCCESS,
    GET_ALL_ADMIN_FAIL,
    GET_ADMIN_ROLES,
    GET_ADMIN_ROLES_FAIL,
    GET_ADMIN_ROLES_SUCCESS,
    ADD_ADMIN, ADD_ADMIN_FAIL,
    ADD_ADMIN_SUCCESS, ADMIN_LOADING,
    GET_ADMIN_BY_ID,
    GET_ADMIN_BY_ID_SUCCESS,
    GET_ADMIN_BY_ID_FAIL,
    STATUS, STATUS_FAIL,
    STATUS_SUCCESS
} from "./actionTypes"

//Get Admins
export const getAllAdmin = ({ search, currentPage, dataPerPage }) => ({
    type: GET_ALL_ADMIN,
    payload: { search, currentPage, dataPerPage }
});
export const getAllAdminSuccess = admin => ({
    type: GET_ALL_ADMIN_SUCCESS,
    payload: admin,
});
export const getAllAdminFail = error => ({
    type: GET_ALL_ADMIN_FAIL,
    payload: error,
});

// Add Admin
export const AddNew = (admin, history, id) => {
    return {
        type: ADD_ADMIN,
        payload: { admin, history, id },
    }
}
export const addNewAdminSuccess = admin => {
    return {
        type: ADD_ADMIN_SUCCESS,
        payload: admin,
    }
}
export const addNewAdminError = error => {
    return {
        type: ADD_ADMIN_FAIL,
        payload: error,
    }
}
export const adminLoading = (loading) => {
    return {
        type: ADMIN_LOADING,
        payload: loading,
    }
}

//Get Admin By Id
export const getAdminById = id => ({
    type: GET_ADMIN_BY_ID,
    payload: id,
})
export const getAdminByIdSuccess = admin => ({
    type: GET_ADMIN_BY_ID_SUCCESS,
    payload: admin,
})
export const getAdminByIdFail = error => ({
    type: GET_ADMIN_BY_ID_FAIL,
    payload: error,
})

// Change Admin Status
export const statusChange = (status, id) => {
    return {
        type: STATUS,
        payload: { status, id },
    }
}
export const statusSuccess = admin => {
    return {
        type: STATUS_SUCCESS,
        payload: admin,
    }
}
export const statusError = error => {
    return {
        type: STATUS_FAIL,
        payload: error,
    }
}