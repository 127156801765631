// Get All Tips
export const GET_ALL_TIPS = "GET_ALL_TIPS"
export const GET_ALL_TIPS_SUCCESS = "GET_ALL_TIPS_SUCCESS"
export const GET_ALL_TIPS_FAIL = "GET_ALL_TIPS_FAIL"

//Add New Tips
export const ADD_TIPS = "ADD_TIPS"
export const ADD_TIPS_SUCCESS = "ADD_TIPS_SUCCESS"
export const ADD_TIPS_FAIL = "ADD_TIPS_FAIL"

//Add New Tips
export const GET_TIPS_ID = "GET_ID_TIPS"
export const GET_TIPS_ID_SUCCESS = "GET_TIPS_ID_SUCCESS"
export const GET_TIPS_ID_FAIL = "GET_TIPS_ID_FAIL"

//Change Tips Status
export const TIPS_STATUS = "TIPS_STATUS"
export const TIPS_STATUS_SUCCESS = "TIPS_STATUS_SUCCESS"
export const TIPS_STATUS_FAIL = "TIPS_STATUS_FAIL"

//Delete Tips
export const DELETE_TIPS = "DELETE_TIPS"
export const DELETE_TIPS_SUCCESS = "DELETE_TIPS_SUCCESS"
export const DELETE_TIPS_FAIL = "DELETE_TIPS_FAIL"

//Get TipType
export const GET_TIPTYPE = "GET_TIPTYPE"
export const GET_TIPTYPE_SUCCESS = "GET_TIPTYPE_SUCCESS"
export const GET_TIPTYPE_FAIL = "GET_TIPTYPE_FAIL"

//close tip
export const CLOSE_TIP = "CLOSE_TIP"
export const CLOSE_TIP_SUCCESS = "CLOSE_TIP_SUCCESS"
export const CLOSE_TIP_FAIL = "CLOSE_TIP_FAIL"

//tip target status change
export const TIP_TARGET_STATUS_CHANGE = "TIP_TARGET_STATUS_CHANGE"
export const TIP_TARGET_STATUS_CHANGE_SUCCESS = "TIP_TARGET_STATUS_CHANGE_SUCCESS"
export const TIP_TARGET_STATUS_CHANGE_FAIL = "TIP_TARGET_STATUS_CHANGE_FAIL"