import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { GET_NOTIFICATION, GET_SETTINGS, GET_SOCIAL_LINKS, SETTINGS_UPDATE, TRADINGMETER, UPDATE_SOCIAL_LINKS } from "./actionType"
import { settingsUpdateError, settingsUpdateSuccess, settingsUpdateLoading, getSettingsLoading, getSettingsError, getSettingsSuccess, getNotificationError, getNotificationLoading, getNotificationSuccess, tradingMeterSuccess, tradingMeterError, getSocialURLSuccess, getSocialURLError } from "./action"
//Include Both Helper File with needed methods
import { postSettingsUpdate, getSettings, getNotificationUrl, postSettingsUpdateToemail, postTradingUpdate, getNetwork, updateSocialInfo } from "../../helpers/backend_helper"
import "toastr/build/toastr.min.css"
import toast from "react-hot-toast";

//Get settings
function* fetchSettings() {
  try {
    yield put(getSettingsLoading(true))
    const response = yield call(getSettings)
    yield put(getSettingsSuccess(response))
    getSettingsLoading(false)
  } catch (error) {
    yield put(getSettingsError(error))
  }
}
export function* GetSettingsSaga() {
  yield takeEvery(GET_SETTINGS, fetchSettings)
}

//Update settings
function* settingsUpdate({ payload: { data, setDisabled, id, getSettingsDataFun } }) {
  try {
    yield put(settingsUpdateLoading(true))
    let response
    if (data?.form === "settings") {
      response = yield call(postSettingsUpdate, data, id)
    } else {
      response = yield call(postSettingsUpdateToemail, data, id)
    }
    yield put(settingsUpdateLoading(false))
    if (response.status === 'error') {
      toast.error(response.message)
    } else {
      yield put(settingsUpdateSuccess(response))
      toast.success(response.message)
      setDisabled(true)
      getSettingsDataFun()
    }
  } catch (error) {
    yield put(settingsUpdateError(error))
  }
}

export function* SettingsUpdateSaga() {
  yield takeEvery(SETTINGS_UPDATE, settingsUpdate)
}

//Update settings
function* tradingUpdate({ payload: { data, setSettingsEdit, id } }) {
  try {
    const response = yield call(postTradingUpdate, data, id)
    yield put(settingsUpdateLoading(false))
    if (response.status?.toLowerCase() === 'error') {
      toast.error(response.message)
    } else {
      yield put(tradingMeterSuccess(response))
      toast.success(response.message)
      setSettingsEdit(true)
    }
  } catch (error) {
    toast.error(error?.response?.data?.message)
    yield put(tradingMeterError(error))
  }
}

export function* TradingMetterUpdateSaga() {
  yield takeEvery(TRADINGMETER, tradingUpdate)
}

// Get SocialLinks

//Get settings
function* fetchSocialLinks() {
  try {
    // yield put(getSettingsLoading(true))
    const response = yield call(getNetwork)
    yield put(getSocialURLSuccess(response))
    // getSettingsLoading(false)
  } catch (error) {
    yield put(getSocialURLError(error))
  }
}
export function* GetSocialMediaSaga() {
  yield takeEvery(GET_SOCIAL_LINKS, fetchSocialLinks)
}

//update social info
function* updatesocial({ payload: { data, afterUpdate } }) {
  try {
    const response = yield call(updateSocialInfo, data)
    if (response?.status?.toLowerCase() === "success") {
      yield put(getSocialURLSuccess(response))
      toast.success(response?.message)
      afterUpdate()
    } else {
      yield put(getSocialURLError(error))
      toast.error(response?.message)
    }
  } catch (error) {
    yield put(getSocialURLError(error))
    toast.error(error?.response?.data?.message)
  }
}
export function* UpdateSocialInfoSaga() {
  yield takeEvery(UPDATE_SOCIAL_LINKS, updatesocial)
}