import React from 'react'
import { Col, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import email from "../../../assets/images/svg/mail.svg";
import phone from "../../../assets/images/svg/phone-call.svg";
import usericon from "../../../assets/images/svg/user.svg";

{/* View Admin/User => pop-up*/ }
const ViewAdmin = ({ modal, setModal, user, client, roles }) => {
    const togglemodal = () => {
        setModal(!modal);
    };

    return (
        <div>
            <Modal
                isOpen={modal}
                role="dialog"
                size="md"
                autoFocus={true}
                centered
                id="verificationModal"
                toggle={togglemodal}
                className="profile"
            >
                <ModalHeader className='border-0 p-0 border-0' toggle={togglemodal}>
                    <div className="avatar-img avatar-md b-4">

                        {user?.profilePath ? <div className='profile avatar-txt rounded-circle bg-soft bg-'> <img src={user?.profilePath} alt="profile" /> </div> :
                            <span
                                className={
                                    "avatar-txt avatar-title rounded-circle bg-soft bg-" +
                                    user.color
                                }
                            >
                                {user.firstName.charAt(0).toUpperCase()}
                            </span>
                        }
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="content clearfix d-block">
                        <Form>
                            <Row>
                                <Col className='text-center'>
                                    <Label className="form-label user-name">
                                        {user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)}
                                        {"  "}
                                        {user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)}
                                    </Label>
                                    <FormGroup className="mb-3">
                                        <Label className="form-label">
                                            {user.status?.toUpperCase() === 'ACTIVE' ?
                                                <div className='badge badge-pill font-size-11 badge-soft-success font-size-13 py-2 px-3'>
                                                    {" Active"}
                                                </div> : <div className='badge badge-pill font-size-11 bg-danger font-size-13 py-2 px-3'>
                                                    {'Suspended'}
                                                </div>}
                                        </Label>
                                    </FormGroup>
                                    <div className='ps-5 pb-4'>
                                        <FormGroup className="user-info">
                                            <div className='d-flex'>
                                                <div className='icon-filed'>
                                                    <img src={email} alt={"email"} />
                                                </div>
                                                <Label className="form-label ms-3">
                                                    {user.email}
                                                </Label>
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="user-info">
                                            <div className='d-flex'>
                                                <div className='icon-filed'>
                                                    <img src={phone} alt={"email"} />
                                                </div>
                                                <Label className="form-label ms-3">
                                                    {user.mobileNumber}
                                                </Label>
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="user-info">
                                            <div className='d-flex'>
                                                <div className='icon-filed '>
                                                    <img src={usericon} alt={"email"} />
                                                </div>
                                                <Label className="form-label ms-3 text-capitalize">
                                                    {user?.role?.label}
                                                </Label>
                                            </div>
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ViewAdmin
