import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Skeleton from "react-loading-skeleton";

const ViewAdvisorSkeleton = () => {
    return (
        <div className='manage-card'>
            <Card className='p-0'>
                <CardBody className='p-0'>
                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <div className='d-flex p-1'>
                            <div className='profile'>
                                <div className={`active-status`}></div>
                                <Skeleton height={"55px"} width={'55px'} borderRadius={'50px'} />
                            </div>
                            <div className='ps-3 name-head'>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />
                            </div>
                        </div>
                        <div className={`view-status `}>
                            <Skeleton height={"35px"} width={'110px'} borderRadius={'50px'} />
                        </div>
                    </div>
                    <hr />
                    <div className='advisor-body'>
                        <div className='crd-details'>
                            <div>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />

                            </div>
                            <div>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />
                            </div>
                            <div>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />
                            </div>
                            <div>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />
                            </div>
                        </div>
                        <div className='crd-details'>
                            <div>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />
                            </div>
                            <div>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />
                            </div>
                            <div>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />
                            </div>
                            <div>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />
                            </div>
                        </div>
                        <div className='crd-details'>
                            <div>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />
                            </div>
                            <div>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />
                            </div>
                            <div>
                                <Skeleton height={"16px"} width={'120px'} />
                                <Skeleton height={"16px"} width={'90px'} />
                            </div>

                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default ViewAdvisorSkeleton;