import React, { memo } from 'react';

const SecountryBtn = ({ btnType, btnName, btnIcon, showIcon, onClick, disabled }) => {
    return (
        <>
            <button className="btn-rounded btn-common secountry-btn" type={btnType} onClick={onClick} disabled={disabled ? disabled : false}>
                {showIcon ? <img src={btnIcon} alt='btnicon' /> : ''}

                <span>{btnName}</span>
            </button>
        </>
    );
};

export default memo(SecountryBtn);