
import { call, put, takeEvery } from "redux-saga/effects"
import { GetAdvisors, changeAdvoicerStatus, getAdvisorId, addNewAdvisor, updateAdvisor, activeAdvisors, pendingAdvisors, submmitedAdvisors, getsubmmitedAdvisor, approveOrRejectRequest } from "helpers/backend_helper";
import { AddNewAdvisorSuccess, AddNewAdvisorError, AddNewAdvisorLoading, getAllAdvisorSuccess, getAllAdvisorFail, getAdvisorByIdSuccess, getAdvisorIdFail, changeAdvisorStatusSuccess, changeAdvisorStatusError, addAdvisorSuccess, addAdvisorError, getActiveAdvisorSuccess, getActiveAdvisorFail, getSubmittedAdvisorSuccess, getSubmittedAdvisorFail, getSubmittedAdvisorIdSuccess, getSubmittedAdvisorIdFail, approveOrRejectSignupRequestSuccess, approveOrRejectSignupRequestFail } from "./actions";
import { ACTIVE_ADVISROR, ADD_ADVISOR, ADVISOR_STATUS, APPROVE_OR_REJECT_SIGNUP_REQUEST, GET_ADVISOR_BY_ID, GET_ALL_ADVISOR, GET_SUBMITTED_ADVISROR, SUBMITTED_ADVISROR_ID } from "./actionTypes";
import toast from "react-hot-toast";

//Get Advisors
function* fetchAdvisors({ payload: { search, currentPage, dataPerPage } }) {
    try {
        const response = yield call(GetAdvisors, search, currentPage, dataPerPage);
        yield put(getAllAdvisorSuccess(response))
    } catch (error) {
        yield put(getAllAdvisorFail(error))
    }
}
export function* AdvisorsSaga() {
    yield takeEvery(GET_ALL_ADVISOR, fetchAdvisors)
}

//Add Advisor
function* newAdvisor({ payload: { advisor, history, id } }) {
    try {
        yield put(AddNewAdvisorLoading(true));
        const data = {
            firstName: advisor.firstName,
            lastName: advisor.lastName,
            email: advisor.email,
            mobileNumber: advisor.mobileNumber,
            description: advisor.description,
        };
        const apiCall = id ? updateAdvisor : addNewAdvisor;
        const response = yield call(apiCall, data, id);
        yield put(AddNewAdvisorLoading(false))
        if (response.status === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(AddNewAdvisorSuccess(response))
            history.push("/marketgenius");
        }
    } catch (error) {
        yield put(AddNewAdvisorLoading(false))
        yield put(AddNewAdvisorError(error))
        toast.error(error.response.data.message)
    }
}
export function* AddAdvisorSaga() {
    yield takeEvery(ADD_ADVISOR, newAdvisor)
}

//Get Advisor ID
function* advisorId({ payload: id }) {
    try {
        const response = yield call(getAdvisorId, id)
        yield put(getAdvisorByIdSuccess(response))
    } catch (error) {
        yield put(getAdvisorIdFail(error))
    }
}
export function* GetAdvisorIdSaga() {
    yield takeEvery(GET_ADVISOR_BY_ID, advisorId)
}

// Change Advisor Status
function* advisorUpdate({ payload: { status, id } }) {
    try {
        const data = {}
        data["status"] = status
        const response = yield call(changeAdvoicerStatus, data, id)
        if (response.status?.toLowerCase() === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(changeAdvisorStatusSuccess(response))
            // history.push(`/${url}`);
        }
    } catch (error) {
        yield put(changeAdvisorStatusError(error))
        toast.error(error.response.data.message)
    }
}
export function* AdvisorStatusSaga() {
    yield takeEvery(ADVISOR_STATUS, advisorUpdate)
}

//Get Active Advisors
function* getactiveAdvisors() {
    try {
        const response = yield call(activeAdvisors);
        yield put(getActiveAdvisorSuccess(response))
    } catch (error) {
        yield put(getActiveAdvisorFail(error))
    }
}
export function* ActiveAdvisorsSaga() {
    yield takeEvery(ACTIVE_ADVISROR, getactiveAdvisors)
}

//Submitted Advisors
function* getsubmmitedAdvisors({ payload: data }) {
    try {
        const response = yield call(submmitedAdvisors, data);
        yield put(getSubmittedAdvisorSuccess(response))
    } catch (error) {
        yield put(getSubmittedAdvisorFail(error))
    }
}
export function* SubmittedAdvisorsSaga() {
    yield takeEvery(GET_SUBMITTED_ADVISROR, getsubmmitedAdvisors)
}

//Get Submitted Advisor Id
function* submittedId({ payload: id }) {
    try {
        const response = yield call(getsubmmitedAdvisor, id)
        yield put(getSubmittedAdvisorIdSuccess(response))
    } catch (error) {
        yield put(getSubmittedAdvisorIdFail(error))
    }
}
export function* GetSubmittedIdSaga() {
    yield takeEvery(SUBMITTED_ADVISROR_ID, submittedId)
}

//approve or reject signup request
function* signUpRequest({ payload: { data, handleUpdate } }) {
    try {
        const response = yield call(approveOrRejectRequest, data)
        if (response?.status?.toLowerCase() === "success") {
            toast.success(response?.message)
            yield put(approveOrRejectSignupRequestSuccess(response))
            handleUpdate()
        } else {
            yield put(approveOrRejectSignupRequestFail(response))
            toast.error(response?.message) 
        }
    } catch (error) {
        toast.error(error?.response?.data?.message)
        yield put(approveOrRejectSignupRequestFail(error))
    }
}

export function* ApproveOrRejectSignupRequestSaga() {
    yield takeEvery(APPROVE_OR_REJECT_SIGNUP_REQUEST, signUpRequest)
}