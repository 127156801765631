import { getAdminById, getAllAdmins, postNewAdmin, updateAdmin, updateStatus } from "helpers/backend_helper"
import {
    addNewAdminError, addNewAdminSuccess, adminLoading, getAdminByIdFail, getAdminByIdSuccess,
    getAllAdminFail, getAllAdminSuccess, statusError, statusSuccess
} from "./actions"
import { call, put, takeEvery } from "redux-saga/effects"
import { ADD_ADMIN, GET_ADMIN_BY_ID, GET_ALL_ADMIN, STATUS } from "./actionTypes"
import toast from "react-hot-toast"

//Get All Admnins
function* fetchUsers({ payload: { search, currentPage, dataPerPage } }) {
    try {
        const response = yield call(getAllAdmins, search, currentPage, dataPerPage);
        yield put(getAllAdminSuccess(response))
    } catch (error) {
        yield put(getAllAdminFail(error))
    }
}
export function* AdminSaga() {
    yield takeEvery(GET_ALL_ADMIN, fetchUsers)
}


//Add Admin 
function* newAdmin({ payload: { admin, history, id } }) {
    try {
        const data = {}
        data["firstName"] = admin.firstName
        data["lastName"] = admin.lastName
        data["email"] = admin.email
        data["mobileNumber"] = admin.mobileNumber
        data["role"] = admin.role
        if (id) {
            data["userId"] = id
        }
        yield put(adminLoading(true))
        let response
        if (id) {
            response = yield call(updateAdmin, data, id)
        } else {
            response = yield call(postNewAdmin, data)
        }

        yield put(adminLoading(false))
        if (response.status === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(addNewAdminSuccess(response))
            history.push("/admin");
        }
    } catch (error) {
        yield put(adminLoading(false))
        yield put(addNewAdminError(error))
        toast.error(error.response.data.message)
    }
}
export function* AddAdminSaga() {
    yield takeEvery(ADD_ADMIN, newAdmin)
}

//Get Admin ID
function* fetchAdmin({ payload: id }) {
    try {
        const response = yield call(getAdminById, id)
        yield put(getAdminByIdSuccess(response.admin))
    } catch (error) {
        yield put(getAdminByIdFail(error))
    }
}
export function* GetAdminByIdSaga() {
    yield takeEvery(GET_ADMIN_BY_ID, fetchAdmin)
}

// Change Admin Status
function* statusUpdate({ payload: { status, id } }) {
    try {
        const data = {}
        data["status"] = status
        const response = yield call(updateStatus, data, id)
        if (response.status?.toLowerCase() === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(statusSuccess(response))
            // history.push(`/${url}`);
        }
    } catch (error) {
        yield put(statusError(error))
        toast.error(error.response.data.message)
    }
}
export function* StatusSaga() {
    yield takeEvery(STATUS, statusUpdate)
}