import {
    GET_ALL_USERS_FAIL,
    GET_ALL_USERS_SUCCESS,
    ADD_NEW_USER,
    ADD_NEW_USER_SUCCESS,
    ADD_NEW_USER_FAIL,
    GET_USER_ID,
    GET_USER_ID_SUCCESS,
    GET_USER_ID_FAIL,
    USER_STATUS,
    USER_STATUS_SUCCESS,
    USER_STATUS_FAIL,
    USER_LOADING,
    GET_ALL_USERS,
     
} from "./actionsTypes"

//Get All Users
const initialState = {
    users: [],
    error: {},
    loading: false
}
export const GetAllUser = (state = initialState, action) => {
    switch (action.type) {
        
        case GET_ALL_USERS:
            return {
                ...state,
                loading: true,
            }
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload,
                loading: false
            }

        case GET_ALL_USERS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//add user
const addUserInitialState = {
    error: "",
    success: "",
    loading: false
}
export const AddUser = (state = addUserInitialState, action) => {
    switch (action.type) {
        case ADD_NEW_USER:
            state = {
                ...state,
            }
            break
        case ADD_NEW_USER_SUCCESS:
            state = { ...state, success: action.payload, loading: false }
            break
        case ADD_NEW_USER_FAIL:
            state = { ...state, error: action.payload, loading: false }
            break
        case USER_LOADING:
            state = {
                loading: action.payload
            }
    }
    return state
}

// Get User Id
const getUserIdInitialState = {
    error: "",
    success: "",
    loading: false
}
export const GetUserById = (state = getUserIdInitialState, action) => {
    switch (action.type) {
        case GET_USER_ID:
            return {
                ...state,
                loading: true,
            }
        case GET_USER_ID_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false
            }

        case GET_USER_ID_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
    }
    return state
}

//Change User Status
const UserStatusInitialState = {
    error: "",
    success: "",
    loader: false,
}
export const UserStatus = (state = UserStatusInitialState, action) => {
    switch (action.type) {
        case USER_STATUS:
            state = {
                ...state,
                success: "",
                loader: true,
            }
            break
        case USER_STATUS_SUCCESS:
            state = { ...state, user: action.payload, loader: false, }
            break
        case USER_STATUS_FAIL:
            state = { ...state, error: action.payload, loader: false, }
            break
    }
    return state
}