import { call, put, takeEvery } from "redux-saga/effects";
import { ADD_NEW_USER, GET_ALL_USERS, GET_USER_ID, USER_STATUS } from "./actionsTypes";
import { addNewUserError, addNewUserLoading, addNewUserSuccess, getAllUserFail, getAllUserSuccess, getUserByIdFail, getUserByIdSuccess, userStatusError, userStatusSuccess } from "./actions";
import { addNewUser, changeUserStatus, getAllUserList, getByUserId, updateUser } from "helpers/backend_helper";
import toast from "react-hot-toast";

//Get All Admnins
function* getUsers({ payload: { search, currentPage, dataPerPage } }) {
    try {
        const response = yield call(getAllUserList, search, currentPage, dataPerPage);
        yield put(getAllUserSuccess(response))
    } catch (error) {
        yield put(getAllUserFail(error))
    }
}
export function* ManageUsersSaga() {
    yield takeEvery(GET_ALL_USERS, getUsers)
}

//Add & Update User
function* createNewUser({ payload: { user, history, id } }) {
    try {
        yield put(addNewUserLoading(true));
        let response
        if (id) {
            response = yield call(updateUser, user, id)
        } else {
            response = yield call(addNewUser, user)
        }
        yield put(addNewUserLoading(false));
        if (response.status?.toLowerCase() === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(addNewUserSuccess(response))
            history.push("/users");
        }
    } catch (error) {
        yield put(addNewUserLoading(false));
        yield put(addNewUserError(error))
        toast.error(error.response.data.message)
    }
}
export function* AddUserSaga() {
    yield takeEvery(ADD_NEW_USER, createNewUser)
}

//Get User ID
function* fetchUserId({ payload: id }) {
    try {
        const response = yield call(getByUserId, id)
        yield put(getUserByIdSuccess(response?.user))
    } catch (error) {
        yield put(getUserByIdFail(error))
    }
}
export function* GetUserByIdSaga() {
    yield takeEvery(GET_USER_ID, fetchUserId)
}

// Change User Status
function* userStatusUpdate({ payload: { status, id } }) {
    try {
        const data = {}
        data["status"] = status
        const response = yield call(changeUserStatus, data, id)
        if (response.status?.toLowerCase() === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(userStatusSuccess(response))
            // history.push(`/${url}`);
        }
    } catch (error) {
        yield put(userStatusError(error))
        toast.error(error.response.data.message)
    }
}
export function* UserStatusSaga() {
    yield takeEvery(USER_STATUS, userStatusUpdate)
}