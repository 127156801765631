import React from "react"
import {
  Card,
  CardBody,
  Col,
  CardFooter,
} from "reactstrap";
import Skeleton from "react-loading-skeleton";

const SkeletonUserCard = () => {
  return (
    <React.Fragment>
      <Col className="p-0">
        <Card className="text-center user-details mb-0">
          <CardBody className="user-info">
            <div className="d-flex">
              <Skeleton height={"70px"} width={'70px'} borderRadius={'50px'} />
              <div className="ps-3 text-left">
                <Skeleton height={"16px"} width={'180px'} />
                <Skeleton height={"12px"} width={'180px'} />
                <Skeleton height={"30px"} width={'180px'} />
              </div>
            </div>
          </CardBody>
          <CardFooter className="bg-transparent border-top">
            <div className="admin-card-footer ">
              <Skeleton height={"30px"} width={'30px'} />
              <Skeleton height={"30px"} width={'30px'} />
              <Skeleton height={"30px"} width={'30px'} />
              <Skeleton height={"30px"} width={'30px'} />
            </div>
          </CardFooter>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default SkeletonUserCard
