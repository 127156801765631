
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap'
import { getFaqById } from 'store/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb"

const ViewFaq = () => {
    let { id } = useParams();
    const dispatch = useDispatch();
    document.title = "FAQ's Details | FiDeal AdminPanel"

    const viewFaqData = useSelector(state => (state?.GetFaqById?.faq?.faq));

    useEffect(() => {
        dispatch(getFaqById(id));
    }, [id]);

    return (
        <React.Fragment>

            <div className='page-content view-Faq'>
                <Container fluid>
                    <Breadcrumbs title="Faq" dashurl="/faq" breadcrumbItem="FAQ Detail" />
                    <div className='manage-card'>
                        <Card className='p-0'>
                            <CardBody className='p-0'>

                                <div className='faq-body'>
                                    <div className='crd-details d-block'>
                                        <div>
                                            <p>Question</p>
                                            <span>{viewFaqData?.question}</span>
                                            <div className='pt-2 mt-2'>
                                                <p>Answer</p>
                                                <span className='text-area'>{viewFaqData?.answer}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ViewFaq