import React from "react";
import { Card, CardBody } from "reactstrap";
import arrow from '../../../assets/images/svgicons/arrow.svg';
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const DashboardListCard = ({ title, count, onClick, icon }) => {



    return (
        <>
            <div className="manage-card">
                <Card className="p-0 m-0">
                    <CardBody className="p-0 ">
                        <div className="card-detail   p-3">
                            <div className="d-flex align-items-center">
                                <div className={`profile`}>
                                    <img src={icon} alt='icon' />
                                </div>
                                <div className="ps-3">
                                    <h5 className="m-0 text-capitalize">{title}</h5>
                                    <p className="m-0 number-count">{count}</p>
                                </div>
                            </div>
                            <div onClick={onClick} className="arrow-rightView ">
                                <img src={arrow} alt='arrow' />
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </>
    );
};

export default DashboardListCard;
