import { useDispatch } from 'react-redux';
import { ErrorRequest } from 'store/errorscreen/actions';
import { axiosApi } from './api_helper'

export const WithAxios = ({ children }) => {
    const dispatch = useDispatch();
    axiosApi.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 400 || error.response.status === 401 || error.response.status === 404 || error.response.status === 500) {
                dispatch(ErrorRequest(error.response.status))
            }
            return Promise.reject(error)
        }
    );
    return children
}

