import admin from '../assets/images/menu-icons/admin.svg';
import advisor from '../assets/images/menu-icons/advisors.svg';
import category from '../assets/images/menu-icons/category.svg';
import dasshboard from '../assets/images/menu-icons/dashboard.svg';
import faq from '../assets/images/menu-icons/faq.svg';
import reviews from '../assets/images/menu-icons/reviews.svg';
import settings from '../assets/images/menu-icons/settings.svg';
import tips from '../assets/images/menu-icons/tips_calls.svg';
import users from '../assets/images/menu-icons/users.svg';

export const MenuItems = [
    {
        name: 'Dashboard',
        path: '/dashboard',
        icon: dasshboard,
        role: ["SUPER_ADMIN", "ADMIN", "ADVISOR"]
    },
    {
        name: 'Users',
        path: '/users',
        icon: users,
        role: ["SUPER_ADMIN", "ADMIN",]
    },
    {
        name: 'MarketGenius',
        path: '/marketgenius',
        icon: advisor,
        role: ["SUPER_ADMIN", "ADMIN"]
    },
    // {
    //     name: 'Signup Request',
    //     path: '/request',
    //     icon: advisor,
    //     role: ["SUPER_ADMIN","ADMIN"]
    // },
    {
        name: 'Admin',
        path: '/admin',
        icon: admin,
        role: ["SUPER_ADMIN"]
    },
    {
        name: 'GeniusTalk/Calls',
        path: '/geniustalk',
        icon: tips,
        role: ["SUPER_ADMIN", "ADMIN", "ADVISOR"]
    },
    {
        name: 'Reviews',
        path: '/reviews',
        icon: reviews,
        role: ["SUPER_ADMIN", "ADMIN", "ADVISOR"]
    },
    {
        name: 'Categories',
        path: '/category',
        icon: category,
        role: ["SUPER_ADMIN", "ADMIN"]
    },
    {
        name: 'FAQ',
        path: '/faq',
        icon: faq,
        role: ["SUPER_ADMIN", "ADMIN"]
    },
    {
        name: 'Settings',
        path: '/settings',
        icon: settings,
        role: ["SUPER_ADMIN", "ADMIN"]
    },
]