import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import AppLogo from '../../assets/images/logo.png'
// Hook Form Validation
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import AuthLoader from "components/Common/AuthLoader";

const ForgetPasswordPage = props => {

  //meta title
  document.title = "Forgot Password | FiDeal AdminPanel";

  const dispatch = useDispatch();

  const forgetFormValues = {
    email: '',
  }

  const forgetFormSchema = Yup.object({
    email: Yup.string().required("Enter email address").test('email', "Enter valid email address", val => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) return false
      else return true
    }).trim(),
  })

  const { register, handleSubmit, formState: { errors, }, } = useForm({
    resolver: yupResolver(forgetFormSchema),
    defaultValues: forgetFormValues
  });

  const onSubmit = (values) => {
    dispatch(userForgetPassword(values, props.history));
  }

  const { forgetLoader } = useSelector(state => ({
    forgetLoader: state.ForgetPassword.forgetLoader,
  }));

  return (
    <React.Fragment>
      <div className="account-pages">
        <Row className="h-100 w-100">
          <Col lg={8} className="login-bg">
            <div className="logo">
              <img src={AppLogo} alt="AppLogoWhite" />
            </div>
            <div className="welcome-content">
              <h1>Welcome to <br />FiDeal AdminPanel</h1>
            </div>
          </Col>
          <Col lg={4}>
            <div className="welcome-form">
              <div className="text-center auth-logo">
                <img src={AppLogo} alt="Logo" />
              </div>
              <div className="text-center auth-content">
                <h4>Forgot Password</h4>
                <p>Enter your email for the verification process. We will send 6 digits code to your email.</p>
              </div>
              <form
                noValidate
                className="form-horizontal"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
              >
                <div>
                  <Label className="form-label mt-3 f-size-14">Email address<span>*</span></Label>
                  <input type="email" {...register("email")} placeholder="email@address.com" className={errors.email?.message ? 'form-control mb-0 is-invalid' : '' + 'form-control mb-0'} />
                  {errors.email?.message ? <p className={`invalid-feedback`} >{errors.email?.message}</p> : null}
                </div>

                {forgetLoader === true ? <AuthLoader /> :
                  <div className="d-grid">
                    <button
                      className="btn btn-primary btn-block primary-button"
                      type="submit"
                    >
                      Send code
                    </button>
                  </div>
                }
                <p className="go-back f-size-12 pt-3 text-center">
                  Go back to{" "}
                  <Link to="/login" className="font-weight-medium primary-color f-size-12">
                    Login
                  </Link>{" "}
                </p>
              </form>

            </div>
          </Col>

        </Row>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
