// Get All Admin 
export const GET_ALL_FAQ = "GET_ALL_FAQ"
export const GET_ALL_FAQ_SUCCESS = "GET_ALL_FAQ_SUCCESS"
export const GET_ALL_FAQ_FAIL = "GET_ALL_FAQ_FAIL"
//Get faq by ID

export const GET_FAQ_BY_ID = "GET_FAQ_BY_ID"
export const GET_FAQ_BY_ID_SUCCESS = "GET_FAQ_BY_ID_SUCCESS"
export const GET_FAQ_BY_ID_FAIL = "GET_FAQ_BY_ID_FAIL"

//Add Faq
export const ADD_FAQ = "ADD_FAQ"
export const ADD_FAQ_SUCCESS = "ADD_FAQ_SUCCESS"
export const ADD_FAQ_FAIL = "ADD_FAQ_FAIL"
export const FAQ_LOADING = "FAQ_LOADING"

//Delete Faq
export const DEL_FAQ = "DEL_FAQ"
export const DEL_FAQ_SUCCESS = "DEL_FAQ__SUCCESS"
export const DEL_FAQ__FAIL = "DEL_FAQ__FAIL"

//Faq status 
export const FAQ_STATUS = "FAQ_STATUS"
export const FAQ_STATUS_SUCCESS = "FAQ_STATUS_SUCCESS"
export const FAQ_STATUS_FAIL = "FAQ_STATUS_FAIL"