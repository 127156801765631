import { REVIEW_DETAILS, REVIEW_DETAILS_FAIL, REVIEW_DETAILS_SUCCESS, REVIEW_LIST, REVIEW_LIST_FAIL, REVIEW_LIST_SUCCESS, REVIEW_PUBLISH, REVIEW_PUBLISH_FAIL, REVIEW_PUBLISH_SUCCESS } from "./actionTypes"; 

//GET REVIEW LIST
export const getReviewList = (currentPage,dataPerPage) => {
    return {
        type: REVIEW_LIST, 
        payload: {currentPage,dataPerPage},
    }
}
export const getReviewListSuccess = user => {
    return {
        type: REVIEW_LIST_SUCCESS,
        payload: user,
    }
}
export const getReviewListError = error => {
    return {
        type: REVIEW_LIST_FAIL,
        payload: error,
    }
}

//GET REVIEW DETAILS
export const getReviewDetails = (user) => {
    return {
        type: REVIEW_DETAILS, 
        payload: {user},
    }
}
export const getReviewDetailsSuccess = user => {
    return {
        type: REVIEW_DETAILS_SUCCESS,
        payload: user,
    }
}
export const getReviewDetailsError = error => {
    return {
        type: REVIEW_DETAILS_FAIL,
        payload: error,
    }
}

//GET REVIEW PUBLISH
export const getReviewPublish = (data, handleUpdate) => {
    return {
        type: REVIEW_PUBLISH, 
        payload: {data, handleUpdate},
    }
}
export const getReviewPublishSuccess = user => {
    return {
        type: REVIEW_PUBLISH_SUCCESS,
        payload: user,
    }
}
export const getReviewPublishError = error => {
    return {
        type: REVIEW_PUBLISH_FAIL,
        payload: error,
    }
}
