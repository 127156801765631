import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
  Badge,
  Card,
  CardBody,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"

import PrimaryButton from "components/Common/Buttons/PrimaryButton"
import SecountryBtn from "components/Common/Buttons/SecountryBtn"
import NoDataFound from "components/Common/NoDataFound"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import plus from "../../assets/images/svgicons/plus.svg"

import CategoryDetailsSkeleton from "components/Common/Skeleton/CategoryDetailsSkeleton"
import Confirmation from "components/Common/Modals/Confirmation"
import SubCategoryModal from "components/Common/Modals/SubCategoryModal"
import { getSubCategory, subcategoryDefaultChange, subcategoryStatusChange } from "store/actions"


const CategoryDetails = () => {

  let { id } = useParams()

  document.title = "Categories Details | FiDeal AdminPanel"

  const dispatch = useDispatch()

  const [showAddSubCategoryModal, setShowAddSubCategoryModal] = useState(false)
  const [showEditSubCategoryModal, setShowEditSubCategoryModal] = useState(false)
  const [subcategoryValue, setsubCategoryValue] = useState(null)
  const [categoryId, setCategoryId] = useState(id)
  const [changeSubcategoryModal, setChangeSubcategoryModal] = useState(false)
  const [isgetStatus, setGetStatus] = useState()

  const subcategoryReload = useSelector(state => state?.AddSubCategory)
  const statusReload = useSelector(state => state?.SubCategoryStatus)
  const Subcategoryloading = useSelector(state => state?.GetSubCategory?.loading)


  const subCategoryData = useSelector(state => state.GetSubCategory?.subcat?.subcategory)
  const CategoryList = useSelector(state => state.GetMainCategory?.category?.category)
  const specificCategory = CategoryList?.length > 0 && CategoryList.find(category => category._id === id)

  //drop down mapping
  const [dropdownOpenMap, setDropdownOpenMap] = useState({})
  const toggleDropdown = subcategoryKey => {
    setDropdownOpenMap(prevState => ({ ...prevState, [subcategoryKey]: !prevState[subcategoryKey] }))
  }

  //publish and unpublish badge
  const getSubcategoryBadgeColor = status => {
    return status === "ACTIVE" ? "success" : "danger"
  }

  useEffect(() => {
    if (id) {
      dispatch(getSubCategory(id))
    }
  }, [id])

  //add subcategory modal 
  const openModal = () => {
    setShowAddSubCategoryModal(true)
  }
  const closeModal = () => {
    setShowAddSubCategoryModal(false)
  }

  // subcategory status update
  function activeToggleModal() {
    setChangeSubcategoryModal(!changeSubcategoryModal)
  }

  //subcategory status change
  const subcategoryUpdateStatus = () => {
    const status = {
      status: isgetStatus?.status === "INACTIVE" ? "ACTIVE" : "INACTIVE",
    }
    dispatch(subcategoryStatusChange(status, isgetStatus?._id))
    setChangeSubcategoryModal(false)
  }

  //subcategory reload
  useEffect(() => {
    if (subcategoryReload?.subcategory?.status === "SUCCESS") {
      dispatch(getSubCategory(id))
    }
  }, [subcategoryReload])

  //status reload
  useEffect(() => {
    if (statusReload?.subcategory?.status === "SUCCESS") {
      dispatch(getSubCategory(id))
    }
  }, [statusReload, id])

  //subcategory reload
  const handleReload = () => {
    dispatch(getSubCategory(id))
  }

  //set as  subcategory default
  const setAsDefault = (id) => {
    const isDefault = {
      isDefault: "YES",
      category: categoryId
    };
    dispatch(subcategoryDefaultChange(isDefault, id, handleReload));
  };


  return (
    <React.Fragment>
      <div className="page-content  subcategory-screen">
        <Container fluid>
          <Breadcrumbs
            title="Categories"
            dashurl="/category"
            breadcrumbItem={"Sub-Categories"}
          />
          <div className="manage-card">
            <Card className="p-0 m-0">
              {Subcategoryloading ? (
                <CategoryDetailsSkeleton />
              ) : (
                <CardBody className="p-0">
                  <div className="subcat-content subcat-header p-5">
                    <div className="d-flex align-items-center p-1">
                      {/* <div
                        className={`profile ${specificCategory?.status?.toLowerCase()}`}
                      >
                        <div className={`active-status`}></div>
                      </div> */}
                      <div>
                        <h5 className=" m-0 text-capitalize">
                          {specificCategory?.label}
                        </h5>
                        <span className=" m-0 text-capitalize">
                          {specificCategory?.callType?.toLowerCase()}
                        </span>
                        <div> <Badge
                          className="ms-0"
                          color={getSubcategoryBadgeColor(
                            specificCategory?.status,
                          )}
                        >
                          {specificCategory?.status === "ACTIVE"
                            ? "Published"
                            : "Unpublished"}
                        </Badge></div>
                      </div>
                    </div>
                    <div className="d-flex p-1 ">
                      <div className="ps-3 ">
                        <SecountryBtn
                          btnName={"Add Sub-Category"}
                          btnType="button"
                          showIcon={false}
                          onClick={() => {
                            openModal()
                            setsubCategoryValue(null)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="p-5">
                    <div className="subcategory-card-view">
                      <div className={`card-details ${!subCategoryData || subCategoryData.length === 0 ? "empty" : ""}`}>
                        {subCategoryData && subCategoryData.length > 0 ? (
                          subCategoryData.map((subcategory, index) => (
                            <div key={index}>
                              <Card className="p-0 m-0">
                                <CardBody className="p-0">
                                  <div className="subcat-details p-3 card-design">
                                    <div className="d-flex p-1">
                                      <div className="ps-0">
                                        <h5 className=" m-0 text-capitalize">
                                          {subcategory?.label}

                                          {subcategory?.isDefault === "YES" ? (
                                            <Badge
                                              className="text-dark ms-2"
                                              color="light"
                                            >
                                              {"Default"}
                                            </Badge>
                                          ) : (
                                            ""
                                          )}
                                        </h5>
                                        <Badge
                                          className="ms-0"
                                          color={getSubcategoryBadgeColor(
                                            subcategory?.status,
                                          )}
                                        >
                                          {subcategory?.status === "ACTIVE"
                                            ? "Published"
                                            : "Unpublished"}
                                        </Badge>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">

                                      <div>
                                        <div className="d-flex">
                                          <Dropdown
                                            isOpen={
                                              dropdownOpenMap[
                                              subcategory._id
                                              ] || false
                                            }
                                            toggle={() =>
                                              toggleDropdown(subcategory._id)
                                            }
                                            direction={"top"}
                                            end={true}
                                          >
                                            <DropdownToggle
                                              caret
                                              className="more-option"
                                            >
                                              <i className="bx bx-dots-vertical-rounded"></i>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              <DropdownItem
                                                onClick={() => {
                                                  setShowEditSubCategoryModal(true)
                                                  setsubCategoryValue(subcategory)
                                                }}
                                              >
                                                Edit
                                              </DropdownItem>

                                              <DropdownItem
                                                disabled={
                                                  subcategory?.status ===
                                                    "ACTIVE"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <div
                                                  onClick={() => {
                                                    activeToggleModal()
                                                    setGetStatus(subcategory)
                                                  }}
                                                >
                                                  Publish
                                                </div>
                                              </DropdownItem>
                                              <DropdownItem
                                                disabled={
                                                  subcategory?.status === "INACTIVE" || subcategory?.isDefault === "YES"
                                                }
                                              >
                                                <div
                                                  onClick={() => {
                                                    activeToggleModal()
                                                    setGetStatus(subcategory)
                                                  }}
                                                >
                                                  Unpublish
                                                </div>
                                              </DropdownItem>

                                              <DropdownItem
                                                onClick={() => {
                                                  setAsDefault(subcategory?._id)
                                                }}
                                                disabled={
                                                  subcategory?.status === "INACTIVE" || subcategory?.isDefault === "YES"
                                                }
                                              >
                                                Set as Default
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </div>
                          ))
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    </div>
                  </div>
                </CardBody>
              )}
            </Card>
          </div>
        </Container>
      </div>

      {/* Modal popup and Confirmation modal components */}
      <Confirmation
        showModal={changeSubcategoryModal}
        toggle={activeToggleModal}
        primaryAction={subcategoryUpdateStatus}
        modalTitle={`Confirmation!`}
        primaryBtn={`${isgetStatus?.status === "INACTIVE" ? "Publish" : "Unpublish"
          }`}
        secountBtn="Cancel"
        description={`Are you sure, do you want to ${isgetStatus?.status === "ACTIVE" ? "unpublish" : "publish"
          } this subcategory "${isgetStatus?.label}"?`}
      />
      {/* Add Subcategory Modal */}
      <SubCategoryModal
        showModal={showAddSubCategoryModal}
        toggle={closeModal}
        modalTitle="Add Sub-Category"
        primaryBtn="Save"
        secountBtn="Close"
        categoryId={categoryId}
        isEdit={false}
      />
      {/* Edit SubCategory Modal */}
      <SubCategoryModal
        showModal={showEditSubCategoryModal}
        toggle={() => setShowEditSubCategoryModal(!showEditSubCategoryModal)}
        modalTitle="Edit Sub-Category"
        primaryBtn="Update"
        secountBtn="Close"
        categoryId={categoryId}
        subCategoryData={subcategoryValue}
        isEdit={true}
      />
    </React.Fragment>
  )
}

export default CategoryDetails
