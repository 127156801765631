import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.png";
import { getAdminById } from "store/actions";

const ProfileMenu = () => {
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(false);

  const userInfo = useSelector(state => (state?.authInfo?.data?.user));
  const profileKey = useSelector(state => (state.UpdateProfileImg.data), shallowEqual);

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  // setusername(obj.user.firstName);
  // setRole(obj.user.role);
  // setProfileImg(profileKey)
  //   }
  // }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >{
            profileKey ?
              <img
                className="rounded-circle header-profile-user"
                src={profileKey || user1}
                alt="Header Avatar"
              /> :
              <div
                className={
                  "rounded-circle header-profile-user d-flex align-items-center justify-content-center"
                }
              >
                <span className="font-weight-bold">{userInfo?.firstName?.charAt(0).toUpperCase()}</span>
              </div>
          }
          <p className="d-none d-xl-inline-block ms-2 me-1 mb-0 text-capitalize">{userInfo?.firstName + " " + userInfo?.lastName}</p>
          {/* <span className="d-none d-xl-inline-block ms-2 me-1">{isRole}</span> */}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile">
            <DropdownItem>
              {" "}
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {` Profile`}
            </DropdownItem>
          </Link>
          {/* Do not delete these code's, it will be needed */}
          {/* <Link to="#">
          <DropdownItem>
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
         </Link>
         <Link to="#">
          <DropdownItem>
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem>
         </Link> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{` Logout`}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

export default ProfileMenu