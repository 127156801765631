import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import TipsBlogCard from 'components/Common/Cards/TipsBlogCard';
import NoDataFound from 'components/Common/NoDataFound';
import TipsCardSkeleton from 'components/Common/Skeleton/TipsCardSkeleton';
import { dateOptions } from 'constants/data/DateOptions';
// import BlogCard from 'components/Common/Cards/TipsBlogCard';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { Container, Row } from 'reactstrap';
import Pagination from 'components/Common/pagination/Pagination';
import { getAllTips } from 'store/actions';

const ManageTips = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    document.title = "Manage GeniusTalk | FiDeal AdminPanel";

    const tipsData = useSelector(state => (state?.GetManageTips?.tips?.data));
    const { loading } = useSelector(state => (state?.GetManageTips));
    const targetLoading = useSelector(state => (state?.SetTipTarget?.loading));
    const closeLoading = useSelector(state => (state?.CloseTip?.loading));

    const [currentPage, setCurrentPage] = useState(1);
    const dataPerPage = process.env.REACT_APP_DATA_PERPAGE_TIPS || 14;
    const { totalCount } = useSelector(state => (state?.GetManageTips?.tips));

    const tipsDataReload = useSelector(state => (state?.TipsStatus));
    const userInfo = useSelector(state => (state?.authInfo?.data?.user));

    const [date, setDate] = useState({ label: "Today", value: 0 });
    const [filter, setFilter] = useState({
        dateFilter: 0,
    });

    useEffect(() => {
        onLoad()
    }, [filter, currentPage, dataPerPage]);

    const onLoad = () => {
        const payload = {
            dateFilter: filter.dateFilter,
        };
        dispatch(getAllTips(payload, currentPage, dataPerPage));
    }

    const handleDateFilter = (value) => {
        const newFilter = { ...filter, dateFilter: value?.value };
        setFilter(newFilter);
        setDate(value);
        setCurrentPage(1);
    };

    return (
        <React.Fragment>
            <div className="page-content advisors-screen">
                <Container fluid>
                    <div className='title-grp'>
                        <h1 className='page-title'>Manage GeniusTalk</h1>
                        <div className='d-flex'>
                            <div className='select-drop'>
                                <Select
                                    options={dateOptions}
                                    classNamePrefix="custom_select"
                                    // search={true}
                                    value={date}
                                    name="role"
                                    placeholder={`Select date`}
                                    onChange={handleDateFilter}
                                    mainClass="md"
                                />

                            </div>
                            {userInfo?.role !== "ADVISOR" ? '' :
                                <div className='ps-4'>
                                    <PrimaryButton btnName="Add New" showIcon={false} btnType={'button'} onClick={() => { history.push("/geniustalk/add-new-geniustalk") }} />
                                </div>
                            }
                        </div>

                    </div>
                    <div>
                        <>
                            {loading || closeLoading || targetLoading ? <Row className='manage-tip'>
                                {[...Array(12)].map((item, index) => {
                                    return <TipsCardSkeleton key={index} />;
                                })}
                            </Row> : <>
                                {tipsData?.length === 0 ? <NoDataFound /> : <div className='manage-tip'>
                                    {tipsData && tipsData?.map((tips, key, index) => (
                                        <TipsBlogCard tips={tips} key={"_user_" + key} onUpdate={onLoad} />
                                    ))}
                                </div>
                                }
                            </>}
                        </>

                    </div>
                    {totalCount > 0 && (
                        <Pagination
                            className="pagination-bar custom-pagination"
                            currentPage={currentPage}
                            totalCount={totalCount}
                            pageSize={dataPerPage}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    )}

                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageTips;