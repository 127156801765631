import { all, fork } from "redux-saga/effects"
import LayoutSaga from "./layout/saga"
import { AuthSaga, ResetPasswordSaga, ForgetPasswordResendCodeSaga, ForgetPasswordSaga } from "./auth/sagas"
import { AdminSaga, AddAdminSaga, GetAdminByIdSaga, StatusSaga } from './admin/sagas'
import { UpdateProfileImageSaga, UpdateProfileSaga, LoginPasswordChangeSaga, getProfileImagePathSaga } from './profile/saga'
import { AdvisorsSaga, AddAdvisorSaga, GetAdvisorIdSaga, AdvisorStatusSaga, ActiveAdvisorsSaga, SubmittedAdvisorsSaga, GetSubmittedIdSaga, ApproveOrRejectSignupRequestSaga } from "./advisors/sagas"
import { AddTipsSaga, TipsSaga, TipsStatusSaga, DeleteTipsSaga, TipTypeSaga, GetTipsIdSaga, CloseTipSaga, SetTipTargetSaga } from "./tips/sagas"
import { AddCategorySaga, AddSubCategorySaga, CategorySaga, GetCategoryDefaultByIdSaga, GetSubCategoryDefaultByIdSaga, GetSubcategoryByIdSaga, GetcategoryByIdSaga, SubCategorySaga, SubcategoryStatusSaga, categoryStatusSaga } from "./category/sagas"
import { AddFaqSaga, FaqSaga, GetFaqIdSaga, DeleteFaqIdSaga, FaqtStatusSaga } from "./faq/sagas"
import { AddUserSaga, GetUserByIdSaga, ManageUsersSaga, UserStatusSaga } from "./users/sagas"
import { GetSettingsSaga, GetSocialMediaSaga, SettingsUpdateSaga, TradingMetterUpdateSaga, UpdateSocialInfoSaga } from "./settings/saga"
import { AdminRoleSaga } from "./roles/sagas"
import { GetReviewListSaga, GetReviewDeatilsSaga, GetReviewPublishSaga } from "./review/saga"
import { MarkettoSaga, suggestionTypeSaga ,TotalCountSaga} from "./dashboard/sagas"

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(ForgetPasswordSaga),
    fork(LayoutSaga),
    fork(AdminSaga),
    fork(AddAdminSaga),
    fork(GetAdminByIdSaga),
    fork(StatusSaga),
    fork(ResetPasswordSaga),
    fork(ForgetPasswordResendCodeSaga),
    fork(UpdateProfileSaga),
    fork(LoginPasswordChangeSaga),
    fork(UpdateProfileImageSaga),
    fork(getProfileImagePathSaga),
    fork(AddAdvisorSaga),
    fork(AdvisorsSaga),
    fork(GetAdvisorIdSaga),
    fork(AdvisorStatusSaga),
    fork(ActiveAdvisorsSaga),
    fork(SubmittedAdvisorsSaga),
    fork(GetSubmittedIdSaga),
    fork(TipsSaga),
    fork(AddTipsSaga),
    fork(GetTipsIdSaga),
    fork(TipsStatusSaga),
    fork(DeleteTipsSaga),
    fork(TipTypeSaga),
    fork(CategorySaga),
    fork(SubCategorySaga),
    fork(AddCategorySaga),
    fork(AddSubCategorySaga),
    fork(GetSubcategoryByIdSaga),
    fork(GetSubCategoryDefaultByIdSaga),
    fork(GetcategoryByIdSaga),
    fork(SubcategoryStatusSaga),
    fork(categoryStatusSaga),
    fork(GetCategoryDefaultByIdSaga),
    fork(FaqSaga),
    fork(AddFaqSaga),
    fork(GetFaqIdSaga),
    fork(DeleteFaqIdSaga),
    fork(FaqtStatusSaga),
    fork(ManageUsersSaga),
    fork(AddUserSaga),
    fork(GetUserByIdSaga),
    fork(UserStatusSaga),
    fork(SettingsUpdateSaga),
    fork(GetSettingsSaga),
    fork(AdminRoleSaga),
    fork(GetReviewListSaga),
    fork(GetReviewDeatilsSaga),
    fork(GetReviewPublishSaga),
    fork(ApproveOrRejectSignupRequestSaga),
    fork(MarkettoSaga),
    fork(TotalCountSaga),
    fork(suggestionTypeSaga),
    fork(TradingMetterUpdateSaga),
    fork(GetSocialMediaSaga),
    fork(UpdateSocialInfoSaga),
    fork(CloseTipSaga),
    fork(SetTipTargetSaga)
  ])
}