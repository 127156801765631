import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { REVIEW_DETAILS, REVIEW_LIST, REVIEW_PUBLISH } from "./actionTypes";
import { getReviewDetails, getReviewPendingList, getReviewPublish } from "helpers/backend_helper";
import { getReviewDetailsError, getReviewDetailsSuccess, getReviewListError, getReviewListSuccess, getReviewPublishError, getReviewPublishSuccess } from "./actions";
import toast from "react-hot-toast";

//GetReviewList
function* GetReviewList({ payload: { currentPage, dataPerPage } }) {
    try {
        const response = yield call(getReviewPendingList, currentPage, dataPerPage);
        if (response.status === 'ERROR') {
            yield put(getReviewListError(response));
        } else if (response.status === "SUCCESS") {
            yield put(getReviewListSuccess(response));
        }
        
    } catch (error) {
        yield put(getReviewListError(error));
    }
}

export function* GetReviewListSaga() {
    yield takeEvery(REVIEW_LIST, GetReviewList)
}

//GetReviewDeatils
function* GetReviewDeatils({ payload: { user } }) {
    try {
        const response = yield call(getReviewDetails, user);
        if (response.status === 'ERROR') {
            yield put(getReviewDetailsError(response));
        } else if (response.status === "SUCCESS") {
            yield put(getReviewDetailsSuccess(response?.rating));
        }
    } catch (error) {
        yield put(getReviewDetailsError(error));
    }
}

export function* GetReviewDeatilsSaga() {
    yield takeEvery(REVIEW_DETAILS, GetReviewDeatils)
}


//GetReviewPublish
function* GetReviewPublish({ payload: {data, handleUpdate } }) {
    try {
        const response = yield call(getReviewPublish,data);
        if (response.status === 'ERROR') {
            toast.success(response.message)
            yield put(getReviewPublishError(response));
        } else if (response.status === "SUCCESS") {
            yield put(getReviewPublishSuccess(response?.rating));
            toast.success(response.message)
            handleUpdate();
        }
    } catch (error) {
        toast.success(error.message)
        yield put(getReviewPublishError(error));
    }
}

export function* GetReviewPublishSaga() {
    yield takeEvery(REVIEW_PUBLISH, GetReviewPublish)
}