import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom"
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import { Toaster } from 'react-hot-toast';
import jwt_decode from "jwt-decode";
import { getImagePath } from 'store/actions';
import { authInfoSuccess } from 'store/authInfo/action';
import { logoutUser } from "./store/actions"
import AuthVerify from "./helpers/AuthVerify";
// layouts Format
import 'react-loading-skeleton/dist/skeleton.css';
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { useState } from 'react';

const App = props => {
  const [profileImage, setProfileImage] = useState("")
  const accessToken = localStorage.getItem('authUser');
  useEffect(() => {
    if (accessToken) {
      const decoded = jwt_decode(accessToken);
      dispatch(authInfoSuccess(decoded))
      setProfileImage(decoded?.profile)
    }
  }, [accessToken]);

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  const dispatch = useDispatch()
  // const obj = JSON.parse(localStorage.getItem("authUser"));
  // useEffect(() => {
  //   if (obj) {
  //     const imageKey = { imageKey: obj?.user?.profileImageKey }
  //     if (obj?.user?.profileImageKey) {
  //       dispatch(getImagePath(imageKey))
  //     }

  //   }
  // }, [obj?.user?.role])

  useEffect(() => {
    if (profileImage) {
      dispatch(getImagePath({ key: profileImage }))
    }
  }, [profileImage])

  const Layout = getLayout();

  const history = useHistory();

  const logOut = () => {
    dispatch(logoutUser(history))
  };

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              role={route.role}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
        {<AuthVerify logOut={logOut} />}
      </Router>
      <Toaster position="bottom-center" reverseOrder={false} toastOptions={{
        success: {
          iconTheme: {
            primary: '#fff',
            secondary: '#34c38f',
          },
          style: {
            background: '#34c38f',
            color: '#fff',
          },

        },
        error: {
          iconTheme: {
            primary: 'white',
            secondary: '#f46a6a',
          },
          style: {
            background: '#f46a6a',
            color: '#fff',
          },
        },
      }} />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
