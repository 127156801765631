import {
    GET_ALL_TIPS,
    GET_ALL_TIPS_FAIL,
    GET_ALL_TIPS_SUCCESS,
    ADD_TIPS,
    ADD_TIPS_SUCCESS,
    ADD_TIPS_FAIL,
    GET_TIPS_ID,
    GET_TIPS_ID_SUCCESS,
    GET_TIPS_ID_FAIL,
    TIPS_STATUS,
    TIPS_STATUS_FAIL,
    TIPS_STATUS_SUCCESS,
    DELETE_TIPS,
    DELETE_TIPS_SUCCESS,
    DELETE_TIPS_FAIL,
    GET_TIPTYPE,
    GET_TIPTYPE_FAIL,
    GET_TIPTYPE_SUCCESS,
    CLOSE_TIP,
    CLOSE_TIP_SUCCESS,
    CLOSE_TIP_FAIL,
    TIP_TARGET_STATUS_CHANGE_SUCCESS,
    TIP_TARGET_STATUS_CHANGE_FAIL,
    TIP_TARGET_STATUS_CHANGE,
} from "./actionTypes";
//Get all tips
export const getAllTips = (filterPayload,currentPage,dataPerPage) => ({
    type: GET_ALL_TIPS,
    payload: { filterPayload,currentPage,dataPerPage}
});
export const getAllTipsSuccess = tips => ({
    type: GET_ALL_TIPS_SUCCESS,
    payload: tips,
});
export const getAllTipsFail = error => ({
    type: GET_ALL_TIPS_FAIL,
    payload: error,
});

//Add New Tips
export const addTips = (tipData, history, id, handleUpdate) => {
    
    return {
        type: ADD_TIPS,
        payload: { tipData, history, id, handleUpdate },
    }
}
export const addTipsSuccess = tipData => {
    return {
        type: ADD_TIPS_SUCCESS,
        payload: tipData,
    }
}
export const addTipsError = error => {
    return {
        type: ADD_TIPS_FAIL,
        payload: error,
    }
}

//Get Tips By Id
export const getTipsById = id => ({
    type: GET_TIPS_ID,
    payload: id,
})
export const getTipsByIdSuccess = tipData => ({
    type: GET_TIPS_ID_SUCCESS,
    payload: tipData,
})
export const getTipsyIdFail = error => ({
    type: GET_TIPS_ID_FAIL,
    payload: error,
})

//Change Tips Status
export const changeTipsStatus = (status, id, onUpdate) => {
    return {
        type: TIPS_STATUS,
        payload: { status, id, onUpdate },
    }
}
export const changeTipsStatusSuccess = tips => {
    return {
        type: TIPS_STATUS_SUCCESS,
        payload: tips,
    }
}
export const changeTipsStatusError = error => {
    return {
        type: TIPS_STATUS_FAIL,
        payload: error,
    }
}

//Delete Tips
export const deleteTips = (id, history) => {
    return {
        type: DELETE_TIPS,
        payload: { id, history },
    }
}
export const deleteTipsSuccess = tips => {
    return {
        type: DELETE_TIPS_SUCCESS,
        payload: tips,
    }
}
export const deleteTipsError = error => {
    return {
        type: DELETE_TIPS_FAIL,
        payload: error,
    }
}

//Get TipType
export const getTipType = () => ({
    type: GET_TIPTYPE,
});
export const getTipTypeSuccess = tiptype => ({
    type: GET_TIPTYPE_SUCCESS,
    payload: tiptype,
});
export const getTipTypeFail = error => ({
    type: GET_TIPTYPE_FAIL,
    payload: error,
});

//Close tip
export const closeTip = (data, afterSuccess) => ({
    type: CLOSE_TIP,
    payload: { data, afterSuccess }
});
export const closeTipSuccess = tiptype => ({
    type: CLOSE_TIP_SUCCESS,
    payload: tiptype,
});
export const closeTipFail = error => ({
    type: CLOSE_TIP_FAIL,
    payload: error,
});

//tip target status change
export const tipTargetUpdate = (data, afterSuccess) => ({
    type: TIP_TARGET_STATUS_CHANGE,
    payload: { data, afterSuccess }
});
export const tipTargetUpdateSuccess = tiptype => ({
    type: TIP_TARGET_STATUS_CHANGE_SUCCESS,
    payload: tiptype,
});
export const tipTargetUpdateFail = error => ({
    type: TIP_TARGET_STATUS_CHANGE_FAIL,
    payload: error,
});