export const StockTip = [
    {
        "label": "Buy",
        "value": "BUY"
    },
    {
        "label": "Sell",
        "value": "SELL"
    },
    {
        "label": "Valuable Ideas",
        "value": "VALUABLE_IDEAS"
    },
    {
        "label": "Market Info",
        "value": "MARKET_INFO"
    }
];