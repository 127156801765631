import React, { useEffect, useState } from "react"
import StarRatings from "react-star-ratings"
import usetAvather from "../../assets/images/svg/userAvather.svg"
import PrimaryButton from "components/Common/Buttons/PrimaryButton"
import SecountryButton from "components/Common/Buttons/SecountryBtn"
import View from "../../assets/images/svg/eye.svg"
import { Modal } from "reactstrap"
import ReviewView from "./ReviewView"
import { useSelector } from "react-redux"

const ReviewCard = ({ items, onCheckboxChange, handleMUltipleId, sellectAll }) => {

  const [showDetails, setShowDetails] = useState(false)
  const [userId, setUserId] = useState(null)
  const [isChecked, setIsChecked] = useState(false)

  const userInfo = useSelector(state => state.authInfo.data.user)
  const isAdvisor = userInfo?.role === "ADVISOR"

  const toggle = () => {
    setShowDetails(false)
  }

  const showDetailsHandler = id => {
    setShowDetails(true)
    setUserId(id)
  }

  const handleCheckboxChange = (items) => {
    setIsChecked(!isChecked);
    handleMUltipleId(items?._id)
    onCheckboxChange(items?._id);
  };

  return (
    <React.Fragment>
      <div className={`reviewCard ${isChecked || sellectAll ? "lightGreenBg" : ""} ${userInfo?.role === "SUPER_ADMIN" && "cursor-pointer"}`} onClick={() => {
        if (userInfo?.role === "SUPER_ADMIN") {
          handleCheckboxChange(items)
        }
      }}>
        <div className="header">
          {!isAdvisor && (
            <div className="avather">
              <>
                <img src={usetAvather} />
                <p className="name m-0">
                  {items?.advisorId?.firstName} {items?.advisorId?.lastName}
                </p>
              </>
            </div>
          )}
          {
            userInfo?.role === "SUPER_ADMIN" &&
            <input
              type="checkbox"
              id={`checkbox-${items?._id}`}
              name={`checkbox-${items?._id}`}
              checked={isChecked}
              className="form-check-input"
              onChange={() => handleCheckboxChange(items)}
            />
          }

        </div>
        <div className="body-content">
          <p className="textContent">{items?.review}</p>
          <div>
            <StarRatings
              rating={items?.rating}
              starEmptyColor="#d2d2d2"
              starRatedColor="#ffd701"
              numberOfStars={5}
              name="rating"
              starDimension="25px"
              starSpacing="0"
            />
          </div>
          {!isAdvisor && (
            <p className="authDetails mt-2">
              Post by{" "}
              <a href="#">
                {items?.userId?.firstName} {items?.userId?.lastName}
              </a>
            </p>
          )}
        </div>
        <div className={"actionSection"}>
          <PrimaryButton
            btnName="View"
            showIcon={false}
            btnType={"button"}
            btnIcon={View}
            onClick={(e) => {
              e.stopPropagation();
              showDetailsHandler(items?._id)
            }}
            customClass="greenButton"
          />
        </div>
      </div>
      <>
        <Modal
          isOpen={showDetails}
          centered={true}
          className="doc-modal confirmation review-model"
          toggle={toggle}
        >
          <ReviewView toggle={toggle} userId={userId} />
        </Modal>
      </>
    </React.Fragment>
  )
}

export default ReviewCard
