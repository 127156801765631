import React from 'react';
import { Card, CardBody, Col, Label, Modal, Row } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from "yup"
import PrimaryButton from '../Buttons/PrimaryButton';
import SecountryBtn from '../Buttons/SecountryBtn';
import { useDispatch } from 'react-redux';
import { getReviewPublish, getReviewDetails } from 'store/actions';

const ViewImage = ({ showModal, toggle, modalTitle, path }) => {

    return (
        <div>
            <Modal size="lg" isOpen={showModal} centered={true}
                className='doc-modal confirmation view-image'
            // toggle={toggle}
            >
                <div className="modal-header px-0 border-0">
                    <div className='head-item'>
                        <h1 className='page-title font-size-18 p-0'>{modalTitle}</h1>
                        <span onClick={toggle} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                    </div>
                </div>
                <div className="modal-body px-0">
                    <img className='image' src={path} alt="image" />
                </div>
            </Modal>
        </div>
    );
};

export default ViewImage; 