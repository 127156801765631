import {
  GET_SETTINGS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_LOADING,
  GET_SETTINGS_SUCCESS,
  SETTINGS_UPDATE,
  SETTINGS_UPDATE_FAIL,
  SETTINGS_UPDATE_LOADING,
  SETTINGS_UPDATE_SUCCESS,
  TRADINGMETER,
  TRADINGMETER_SUCCESS,
  TRADINGMETER_FAIL,
  GET_SOCIAL_LINKS,
  GET_SOCIAL_LINKS_SUCCESS,
  GET_SOCIAL_LINKS_FAIL,
  UPDATE_SOCIAL_LINKS,
  UPDATE_SOCIAL_LINKS_SUCCESS,
  UPDATE_SOCIAL_LINKS_FAIL
} from "./actionType"

//Update settings
export const settingsUpdate = (data, setDisabled, id, getSettingsDataFun) => {
  return {
    type: SETTINGS_UPDATE,
    payload: { data, setDisabled, id, getSettingsDataFun }
  }
}
export const settingsUpdateSuccess = data => {
  return {
    type: SETTINGS_UPDATE_SUCCESS,
    payload: data,
  }
}
export const settingsUpdateError = error => {
  return {
    type: SETTINGS_UPDATE_FAIL,
    payload: error
  }
}
export const settingsUpdateLoading = (loading) => {
  return {
    type: SETTINGS_UPDATE_LOADING,
    payload: loading
  }
}

//Get settings
export const getSettings = () => {
  return {
    type: GET_SETTINGS
  }
}
export const getSettingsSuccess = data => {
  return {
    type: GET_SETTINGS_SUCCESS,
    payload: data,
  }
}
export const getSettingsError = error => {
  return {
    type: GET_SETTINGS_FAIL,
    payload: error
  }
}
export const getSettingsLoading = (loading) => {
  return {
    type: GET_SETTINGS_LOADING,
    payload: loading
  }
}

//TRADINGMETER 
export const addTradingMeter = (data, setSettingsEdit, id) => {
  return {
    type: TRADINGMETER,
    payload: { data, setSettingsEdit, id }
  }
}

export const tradingMeterSuccess = data => {
  return {
    type: TRADINGMETER_SUCCESS,
    payload: data,
  }
}
export const tradingMeterError = error => {
  return {
    type: TRADINGMETER_FAIL,
    payload: error
  }
}

//Social links
export const getSocialURL = () => {
  return {
    type: GET_SOCIAL_LINKS,
  }
}
export const getSocialURLSuccess = data => {
  return {
    type: GET_SOCIAL_LINKS_SUCCESS,
    payload: data,
  }
}
export const getSocialURLError = error => {
  return {
    type: GET_SOCIAL_LINKS_FAIL,
    payload: error
  }
}

//update Social links
export const updateSocialInfo = (data, afterUpdate) => {
  return {
    type: UPDATE_SOCIAL_LINKS,
    payload: { data, afterUpdate }
  }
}
export const updateSocialInfoSuccess = data => {
  return {
    type: UPDATE_SOCIAL_LINKS_SUCCESS,
    payload: data,
  }
}
export const updateSocialInfoError = error => {
  return {
    type: UPDATE_SOCIAL_LINKS_FAIL,
    payload: error
  }
}