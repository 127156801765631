import ViewAdvisorSkeleton from 'components/Common/Skeleton/ViewAdvisorSkeleton';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelect } from 'react-select-search';
import { Card, CardBody, Container } from 'reactstrap';
import { getAdvisorById } from 'store/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import RatingProgress from 'components/RatingStats/RatingProgress';

const ViewAdvisor = () => {
    let { id } = useParams();
    const dispatch = useDispatch();

    document.title = "View MarketGenius | FiDeal AdminPanel"

    const viewData = useSelector(state => (state?.GetAdvisorById?.advisor?.advisor));
    const ratingCounts = useSelector(state => (state?.GetAdvisorById?.advisor?.ratingCounts));
    const viewDataLoading = useSelector(state => (state?.GetAdvisorById?.loading));

    useEffect(() => {
        dispatch(getAdvisorById(id));
    }, [id]);

    return (
        <React.Fragment>
            <div className="page-content view-advisor">
                <Container fluid>
                    <Breadcrumbs title="MarketGenius" dashurl="/marketgenius" breadcrumbItem={"MarketGenius Detail"} />

                    <div>
                        {viewDataLoading ? (
                            <ViewAdvisorSkeleton />
                        ) : (
                            <div className='manage-card'>
                                <Card className='p-0'>
                                    <CardBody className='p-0'>
                                        <div className='d-flex justify-content-between align-items-center p-3 advisor-profile'>
                                            <div className='d-flex align-items-center p-1'>
                                                <div className='profile'>
                                                    <div className={`active-status ${viewData && viewData?.status?.toLowerCase()}`}></div>
                                                    {viewData?.profilePath ? <span className='profile-img'><img src={viewData?.profilePath} alt="profile-img" /> </span>:
                                                        <span>{viewData && viewData?.firstName?.charAt(0)?.toUpperCase()}</span>
                                                    }
                                                </div>
                                                <div className='ps-3 name-head'>
                                                    <h5 className='m-0 text-capitalize'>{viewData && viewData?.firstName}&nbsp;{viewData && viewData?.lastName}</h5>
                                                    <span>{viewData && viewData?.email}</span>
                                                </div>
                                            </div>
                                            <div className={`view-status ${viewData && viewData?.status?.toLowerCase()}`}>
                                                <span className={`text-capitalize`}> {viewData && viewData?.status && viewData?.status?.toLowerCase() === 'active' ? "active" : "suspended"}</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='advisor-body'>
                                            <div className='crd-details'>
                                                <div>
                                                    <span>First Name</span>
                                                    <p>{viewData && viewData?.firstName}</p>
                                                </div>
                                                <div>
                                                    <span>Last name</span>
                                                    <p>{viewData && viewData?.lastName}</p>
                                                </div>
                                                <div>
                                                    <span>SEBI number</span>
                                                    <p>{viewData && viewData?.kycDoc?.sebiNumber}</p>
                                                </div>
                                            </div>

                                            <div className='crd-details'>
                                                <div>
                                                    <span>Decription</span>
                                                    <p>{viewData && viewData?.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                {viewData ? <div className=''>
                                    <h2>Ratings & Review</h2>
                                    <div className='p-2'>
                                        <RatingProgress rating={viewData && viewData?.rating} ratingCounts={ratingCounts} viewData={viewData} />
                                    </div>
                                </div> : ""}

                            </div>
                        )
                        }

                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ViewAdvisor;