import {
    GET_ALL_FAQ_SUCCESS,
    GET_ALL_FAQ_FAIL,
    GET_FAQ_BY_ID,
    GET_FAQ_BY_ID_SUCCESS,
    GET_FAQ_BY_ID_FAIL,
    ADD_FAQ,
    ADD_FAQ_SUCCESS,
    ADD_FAQ_FAIL,
    FAQ_LOADING,
    DEL_FAQ,
    DEL_FAQ_SUCCESS,
    DEL_FAQ__FAIL,
    FAQ_STATUS,
    FAQ_STATUS_SUCCESS,
    FAQ_STATUS_FAIL,
    GET_ALL_FAQ,
} from "./actionTypes"

//Get All Faq
const initialState = {
    faq: [],
    error: null,
    loading: false
}
export const GetFaq = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_FAQ:
            return {
                ...state,
                faq: action.payload,
                loading: true
            }
        case GET_ALL_FAQ_SUCCESS:
            return {
                ...state,
                faq: action.payload,
                loading: false
            }

        case GET_ALL_FAQ_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//get faq by ID 
const getFaqIdInitialState = {
    error: "",
    success: "",
    loading: false
}
export const GetFaqById = (state = getFaqIdInitialState, action) => {
    switch (action.type) {
        case GET_FAQ_BY_ID:
            return {
                ...state,
                loading: true,
            }
        case GET_FAQ_BY_ID_SUCCESS:
            return {
                ...state,
                faq: action.payload,
                loading: false
            }

        case GET_FAQ_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
    }
    return state
}

//Add Faq
const addFaqInitialState = {
    error: "",
    success: "",
    loading: false
}
export const AddFaq = (state = addFaqInitialState, action) => {
    switch (action.type) {
        case ADD_FAQ:
            state = {
                ...state,
            }
            break
        case ADD_FAQ_SUCCESS:
            state = { ...state, success: action.payload, loading: false }
            break
        case ADD_FAQ_FAIL:
            state = { ...state, error: action.payload, loading: false }
            break
        case FAQ_LOADING:
            state = {
                loading: action.payload
            }
    }
    return state
}

//Delete faq
const deleteFaqInitialState = {
    error: "",
    success: "",
    loading: false
}
export const DeleteFaqById = (state = deleteFaqInitialState, action) => {
    switch (action.type) {
        case DEL_FAQ:
            return {
                ...state,
                loading: true,
            }
        case DEL_FAQ_SUCCESS:
            return {
                ...state,
                faq: action.payload,
                loading: false
            }

        case DEL_FAQ__FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
    }
    return state
}

//faq status
const statusInitialState = {
    error: "",
    success: "",
    loader: false,
}
export const FaqStatus = (state = statusInitialState, action) => {
    switch (action.type) {
        case FAQ_STATUS:
            state = {
                ...state,
                success: "",
                loader: true,
            }
            break
        case FAQ_STATUS_SUCCESS:
            state = { ...state, faq: action.payload, loader: false, }
            break
        case FAQ_STATUS_FAIL:
            state = { ...state, error: action.payload, loader: false, }
            break
    }
    return state
}