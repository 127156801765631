import { ADD_SUGGESTION_TYPE, ADD_SUGGESTION_TYPE_FAIL, ADD_SUGGESTION_TYPE_SUCCESS, GET_DASHBOARD_COUNT, GET_DASHBOARD_COUNT_FAIL, GET_DASHBOARD_COUNT_SUCCESS, GET_MARKETTO, GET_MARKETTO_FAIL, GET_MARKETTO__SUCCESS } from "./actionTypes";

// Get Marketto Meteer
export const getMarketto = () => ({
    type: GET_MARKETTO,
});
export const getMarkettoSuccess = tradingMeter => ({
    type: GET_MARKETTO__SUCCESS,
    payload: tradingMeter,
});
export const getMarkettoFail = error => ({
    type: GET_MARKETTO_FAIL,
    payload: error,
});


// Add suggestionType

export const addSuggestionType = (suggestionType, afterSuccess,afterFail) => ({
    type: ADD_SUGGESTION_TYPE,
    payload: { suggestionType, afterSuccess,afterFail },
});
export const addSuggestionTypeSuccess = tradingMeter => ({
    type: ADD_SUGGESTION_TYPE_SUCCESS,
    payload: tradingMeter,
});
export const addSuggestionTypeFail = error => ({
    type: ADD_SUGGESTION_TYPE_FAIL,
    payload: error,
});

//get dashboard count

export const getCount = () => ({
    type: GET_DASHBOARD_COUNT,
});
export const getCountSuccess = count => ({
    type: GET_DASHBOARD_COUNT_SUCCESS,
    payload: count,
});
export const getCountFail = error => ({
    type: GET_DASHBOARD_COUNT_FAIL,
    payload: error,
});
