import React, { memo } from 'react';
import { Modal } from 'reactstrap';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecountryBtn from '../Buttons/SecountryBtn';

const Confirmation = ({ showModal, toggle, close, modalTitle, description, primaryBtn, secountBtn, primaryAction, hideCancel, secondaryAction }) => {
    return (
        <div>
            <Modal size="sm" isOpen={showModal} centered={true}
                className='doc-modal confirmation'
                toggle={toggle}
            >
                <div className="modal-header px-0 border-0">
                    <div className='head-item'>
                        <h1 className='page-title font-size-18 p-0'>{modalTitle}</h1>
                        <span onClick={toggle} aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                    </div>
                </div>
                <div className="modal-body px-0">
                    <div className='text-center'>
                        <p>{description}</p>
                    </div>
                    <div className='filter-btn d-flex justify-content-center'>
                        <PrimaryButton btnName={primaryBtn} showIcon={false} onClick={primaryAction} />
                        {!hideCancel && <span onClick={close}><SecountryBtn btnName={secountBtn} showIcon={false} onClick={() => secondaryAction ? secondaryAction() : toggle()} /></span>}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default memo(Confirmation);