import React, { useEffect, useState } from "react"

import {
    Row,
    Col,
    Card,
    CardBody,
    Label,
    Container,
    CardTitle,
} from "reactstrap"
import { useParams, useHistory } from "react-router-dom"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup"
import { useSelector, useDispatch } from "react-redux"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AddNewUser, getUserById } from "../../store/actions"
import PageLoader from '../../components/Common/PageLoader';
import PrimaryButton from "components/Common/Buttons/PrimaryButton";
import { GetRoles as roles } from "constants/AdminRoles";
import SecountryBtn from "components/Common/Buttons/SecountryBtn";

const UserForm = props => {

    let { id } = useParams()

    document.title = `${!id ? "Add New User" : "Update User"} | FiDeal AdminPanel`

    const dispatch = useDispatch();
    const history = useHistory();

    const userData = useSelector(state => (state.GetUserById?.user));
    const addUserLoading = useSelector((state) => state.AddUser.loading);



    const isEditUserEmail = !!id;


    const UserForm = {
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
    }
    const UserFormSchema = Yup.object().shape({
        firstName: Yup.string().required("Enter first name").max(30, 'First name should not exceed 30 characters').trim(),
        lastName: Yup.string().required("Enter last name").max(30, 'Last name should not exceed 30 characters').trim(),
        email: Yup.string().email('Enter valid email address').required("Enter email address").max(30, 'Email address should not exceed 30 characters').trim(),
        mobileNumber: Yup.string().required("Enter phone number").max(15, 'Phone number should not exceed 15 characters').trim(),
    })

    const { register, handleSubmit, setValue, getValues, reset, clearErrors, formState: { errors, }, } = useForm({
        resolver: yupResolver(UserFormSchema),
        defaultValues: UserForm
    });

    const onSubmit = (values) => {
        if (id) {
            dispatch(AddNewUser(values, props.history, id))
        } else {
            dispatch(AddNewUser(values, props.history))
        }
    }

    const getUserData = () => {
        dispatch(getUserById(id));
    }

    useEffect(() => {
        if (id) {
            getUserData()
        }
    }, [id])

    useEffect(() => {
        if (id) {
            setFormValues()
        }
    }, [userData, id])

    const setFormValues = () => {
        reset(
            {
                firstName: userData?.firstName || "",
                lastName: userData?.lastName || "",
                email: userData?.email || "",
                mobileNumber: userData?.mobileNumber || "",
            }
        )

    }

    return (
        <React.Fragment>
            {addUserLoading ? <PageLoader /> : ""}
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Users" dashurl="/users" breadcrumbItem={!id ? "Add New User" : "Update User"}
                    />

                    <Row>
                        <Col xl="12">
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                                className="form-horizontal" >
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md="4">
                                                <div className="mb-3">
                                                    <Label className="form-label f-size-14">First name<span>*</span></Label>
                                                    <input type="text" {...register("firstName")} className={errors.firstName?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                                    {errors.firstName?.message ? <p className={`invalid-feedback`} >{errors.firstName?.message}</p> : null}
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                <div className="mb-3">
                                                    <Label className="form-label f-size-14">Last name<span>*</span></Label>
                                                    <input type="text" {...register("lastName")} className={errors.lastName?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                                    {errors.lastName?.message ? <p className={`invalid-feedback`} >{errors.lastName?.message}</p> : null}
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                <div className="mb-3">
                                                    <Label className="form-label f-size-14">Email address<span>*</span></Label>
                                                    <input type="email" {...register("email")} className={errors.email?.message ? 'form-control is-invalid' : '' + 'form-control'}
                                                        disabled={isEditUserEmail}
                                                    />
                                                    {errors.email?.message ? <p className={`invalid-feedback`} >{errors.email?.message}</p> : null}
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                <div className="mb-3">
                                                    <Label className="form-label f-size-14">Phone Number<span>*</span></Label>
                                                    <input type="number" {...register("mobileNumber")} className={errors.mobileNumber?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                                    {errors.mobileNumber?.message ? <p className={`invalid-feedback`} >{errors.mobileNumber?.message}</p> : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="mb-3 pt-3 d-flex btn-group">
                                            <PrimaryButton btnName="Save" showIcon={false} btnType={'submit'} />
                                            <SecountryBtn btnType='button' onClick={() => {
                                                if (id) {
                                                    setFormValues()
                                                } else {
                                                    reset();
                                                }
                                            }} btnName='Clear' showIcon={false} />

                                        </div>
                                    </CardBody >
                                </Card >
                            </form >
                        </Col >
                    </Row >
                </Container >
            </div>
        </React.Fragment >
    )
}

export default UserForm;
