import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardTitle,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.png";
// actions
import { getAdminById, getSettings, loginPasswordChange, updateProfile, updateProfileImage } from "../../store/actions";
import PasswordStrengthMeter from "constants/PasswordStrengthMeter";
import edit from "./../../assets/images/svg/edit.svg"
import toast from "react-hot-toast";
import PageLoader from "components/Common/PageLoader";
import AuthLoader from "components/Common/AuthLoader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PrimaryButton from "components/Common/Buttons/PrimaryButton";

const UserProfile = () => {

  //meta title
  document.title = "Profile | FiDeal AdminPanel";

  const dispatch = useDispatch();

  const [id, setId] = useState('');
  const [passwordValidate, setPasswordValidate] = useState('')
  const [oldPasswordEye, setOldPasswordEye] = useState(false)
  const [newPasswordEye, setNewPasswordEye] = useState(false)
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false)
  const [avather, setavather] = useState()
  const [resetInput, setResetInput] = useState(false)
  const [getpassword, setGetPassword] = useState('');
  const [password, setPassword] = useState('');

  const { profileImgLoader, updateProfileState, updatePass, profileKey } = useSelector(state => ({
    profileImgLoader: state.UpdateProfileImg.loading,
    updateProfileState: state.UpdateProfile,
    updatePass: state.LoginPasswordChange,
    profileKey: state.UpdateProfileImg,
  }), shallowEqual);

  const userInfo = useSelector(state => (state?.authInfo?.data?.user));
  const getSettingsData = useSelector(state => (state?.GetSettings?.data));

  useEffect(() => {
    dispatch(getSettings())
  }, []);

  useEffect(() => {
    if (profileKey?.data) {
      setavather(profileKey?.data)
    }
  }, [profileKey]);


  // UserName Update
  const resetFormValues = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Enter first name").max(40, "Should not exceed 40 characters"),
    lastName: Yup.string().required("Enter last name").max(40, "Should not exceed 40 characters"),
    mobileNumber: Yup.string().required("Enter contact number").min(4, "Enter valid contact number").max(16, "Should not exceed 16 characters").trim(),
  })

  const { register, handleSubmit, setValue, formState: { errors, }, } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: resetFormValues
  });

  const onSubmit = (values) => {
    dispatch(updateProfile(values, history, userInfo?.id));

  }
  useEffect(() => {
    if (userInfo) {
      setValue('firstName', userInfo?.firstName);
      setValue('lastName', userInfo?.lastName);
      setValue('mobileNumber', userInfo?.mobileNumber);
    }
  }, [userInfo?.firstName, userInfo?.lastName, userInfo?.mobileNumber]);

  // password Update
  const passwordFormValues = {
    oldPassword: '',
    newPassword: '',
    ConfirmPassword: '',

  };
  const passwordSchema = Yup.object({
    oldPassword: Yup.string().required("Enter old password"),
    newPassword: Yup.string().notOneOf([Yup.ref('oldPassword'), null], 'Old password and new password must not be same').required("Enter new password").test("newPassword_length", "Password should reach atleast medium strength", val => passwordValidate >= 2),
    ConfirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'New password and confirm password must be same').required("Enter Confirm password"),
  });

  const { register: registerpws, handleSubmit: handleSubmitPws, reset: resetpws, formState: { errors: errorspws, }, } = useForm({
    resolver: yupResolver(passwordSchema),
    defaultValues: passwordFormValues,
    mode: "onSubmit",
  });

  const onSubmitPws = (values) => {
    const data = {}
    data["oldPassword"] = values.oldPassword
    data["newPassword"] = values.newPassword
    data["id"] = userInfo?.id
    dispatch(loginPasswordChange(data, history, resetFiled));
    resetpws();
    setPassword("")
  }

  const resetFiled = () => {
    setPassword("")
    setPasswordValidate('')
    resetpws();
  }

  const handleChange = (event) => {
    setGetPassword(event.target.value);
  };

  useEffect(() => {
    setPassword(getpassword)
  }, [getpassword]);


  setTimeout(() => {
    if (resetInput === true) setResetInput(false)
  }, 0);

  return (
    <React.Fragment>
      <div className="page-content profile-screen">
        <Container fluid>
          <Breadcrumb title="Dashboard" dashurl="/dashboard" breadcrumbItem="Profile" />
          {profileImgLoader ? <PageLoader /> : ""}

          <Card color="info" className="text-white-50">
            <CardBody>
              <CardTitle className="m-0 text-white">
                <i className="mdi mdi-alert-circle-outline me-3" />Please contact FiDeal support team at <a className="text-white text-decoration-underline" href={`tel:${getSettingsData[0]?.mobileNumber}`}>{getSettingsData[0]?.mobileNumber}</a> or <a className="text-white text-decoration-underline" href={`mailto:${getSettingsData[0]?.email}`}>{getSettingsData[0]?.email}</a> to update your email address
              </CardTitle>
            </CardBody>
          </Card>

          <Row>
            <Col lg="12">

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <div className="profile-edit">
                        <img src={avather ? avather : avatar} alt="avatar" className="avatar-md rounded-circle img-thumbnail" />
                        {resetInput == false ? <input type="file" id="profileUpload" accept="image/jpg,image/jpeg,image/png" name="profileUpload" hidden onChange={(e) => {
                          if (e.target.files && e.target.files.length > 0) {
                            const size = e.target.files[0].size / 1024 / 1024;
                            if (size < 1) {
                              if (e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/png') {
                                const data = e.target.files[0];
                                let img = new Image()
                                img.src = window.URL.createObjectURL(e.target.files[0]);
                                img.onload = () => {
                                  dispatch(updateProfileImage(data, dispatch))
                                }
                              } else {
                                toast.error("Supported file formats are PNG or JPEG")
                                setResetInput(true)
                              }
                            } else {
                              toast.error("Max allowed file size is 1 MB")
                              setResetInput(true)
                            }
                          }
                        }} /> : ""}
                        <label htmlFor="profileUpload" className="m-0 edit-icon">
                          <img src={edit} alt="Edit profile" />
                        </label>
                      </div>
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="fw-600 mb-0 text-capitalize">{userInfo?.firstName + " " + userInfo?.lastName}</h5>
                        <p className="mb-1">{userInfo?.email}</p>
                        <p className="mb-0 text-capitalize"> {userInfo?.role?.replace(/_/g, " ")?.toLowerCase()}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-between mb-4 user-info">
            <Col lg="6">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Update profile</h4>
                  <form className="form-horizontal"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-group mb-4">
                      <Label className="form-label">First name</Label>
                      <input type="text" {...register("firstName")} className={errors.firstName?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                      {errors.firstName?.message ? <p className={`invalid-feedback`} >{errors.firstName?.message}</p> : null}
                    </div>
                    <div className="form-group mb-4">
                      <Label className="form-label">Last name</Label>
                      <input type="text" {...register("lastName")} className={errors.lastName?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                      {errors.lastName?.message ? <p className={`invalid-feedback`} >{errors.lastName?.message}</p> : null}
                    </div>
                    <div className="form-group mb-4">
                      <Label className="form-label">Phone number</Label>
                      <input type="number" {...register("mobileNumber")} className={errors.mobileNumber?.message ? 'form-control is-invalid' : '' + 'form-control'} onWheel={() => document.activeElement.blur()} />
                      {errors.phoneNumber?.message ? <p className={`invalid-feedback`} >{errors.mobileNumber?.message}</p> : null}
                    </div>
                    <div className="text-center mt-4 d-flex">
                      {updateProfileState.loading === true ? <div className="action-btn-loader"><AuthLoader /></div> :
                        <PrimaryButton btnName="Save" showIcon={false} btnType={'submit'} />
                      }
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Change password</h4>
                  <form
                    className="form-horizontal"
                    onSubmit={handleSubmitPws(onSubmitPws)}
                  >
                    <div className="form-group mb-4 passwordChange-fields">
                      <Label className="form-label">Old password <span onClick={() => setOldPasswordEye(prevState => !prevState)}>{oldPasswordEye ? <i className="mdi mdi-eye-outline font-size-16"></i> : <i className="mdi mdi-eye-off-outline font-size-16"></i>}</span></Label>
                      <input type={oldPasswordEye ? 'text' : "password"} {...registerpws("oldPassword")} className={errorspws.oldPassword?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                      {errorspws.oldPassword?.message ? <p className={`invalid-feedback`} >{errorspws.oldPassword?.message}</p> : null}
                    </div>
                    <div className="form-group mb-4 passwordChange-fields">
                      <Label className="form-label">New password <span onClick={() => setNewPasswordEye(prevState => !prevState)}>{newPasswordEye ? <i className="mdi mdi-eye-outline font-size-16"></i> : <i className="mdi mdi-eye-off-outline font-size-16"></i>}</span></Label>
                      <input type={newPasswordEye ? 'text' : "password"} {...registerpws("newPassword")} onChange={handleChange} className={errorspws.newPassword?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                      <PasswordStrengthMeter password={password} setPasswordValidate={setPasswordValidate} />
                      {errorspws.newPassword?.message ? <p className={`invalid-feedback`} >{errorspws.newPassword?.message}</p> : null}
                    </div>
                    <div className="form-group mb-4 passwordChange-fields">
                      <Label className="form-label">Confirm password <span onClick={() => setConfirmPasswordEye(prevState => !prevState)}>{confirmPasswordEye ? <i className="mdi mdi-eye-outline font-size-16"></i> : <i className="mdi mdi-eye-off-outline font-size-16"></i>}</span> </Label>
                      <input type={confirmPasswordEye ? 'text' : "password"} {...registerpws("ConfirmPassword")} className={errorspws.ConfirmPassword?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                      {errorspws.ConfirmPassword?.message ? <p className={`invalid-feedback`} >{errorspws.ConfirmPassword?.message}</p> : null}
                    </div>
                    <div className="text-center mt-4 d-flex justify-content-center">
                      {updatePass.loading === true ? <div className="action-btn-loader"><AuthLoader /></div> :
                        <PrimaryButton btnName="Change password" showIcon={false} btnType={'submit'} />
                      }
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
