import { call, put, takeEvery } from "redux-saga/effects";
import {
    addNewCategorySuccess,
    addNewCategoryError,
    addSubCategorySuccess,
    addSubCategoryError,
    getMainCategorySuccess,
    getMainCategoryFail,
    getSubCategorySuccess,
    getSubCategoryFail,
    getSubCategoryIdSuccess,
    getSubCategoryIdFail,
    subCategoryStatusSuccess,
    subCategoryStatusError,
    getCategoryIdFail,
    getCategoryIdSuccess,
    getCategoryId,
    CategoryStatusSuccess,
    CategoryStatusError,
    CategoryDefaultSuccess,
    CategoryDefaultError,
    subCategoryDefaultSuccess,
    subCategoryDefaultError,
} from "./actions";
import { ADD_NEW_CATEGORY, ADD_SUB_CATEGORY, GET_CATEGORY, GET_CATEGORY_DEFAULT, GET_CATEGORY_ID, GET_CATEGORY_STATUS, GET_SUBCATEGORY_DEFAULT, GET_SUBCATEGORY_ID, GET_SUBCATEGORY_STATUS, GET_SUB_CATEGORY } from "./actionTypes";
import { addCategory, addSubCategory, changeCategoryStatus, changeSubCategoryStatus, getAllCategory, getSubCategory, getSubCategoryId, updateCategory, updateDefaultCategory, updateDefaultSubCategory, updateSubCategory } from "helpers/backend_helper";
import toast from "react-hot-toast";

//Get Main Category
function* fetchMainCat() {
    try {
        const response = yield call(getAllCategory);
        yield put(getMainCategorySuccess(response))
    } catch (error) {
        yield put(getMainCategoryFail(error))
    }
}
export function* CategorySaga() {
    yield takeEvery(GET_CATEGORY, fetchMainCat)
}
//Get Category ById
function* fetchCategoryById({ payload: id }) {
    try {
        const response = yield call(getCategoryId, id)
        yield put(getCategoryIdSuccess(response.category))
    } catch (error) {
        yield put(getCategoryIdFail(error))
    }
}
export function* GetcategoryByIdSaga() {
    yield takeEvery(GET_CATEGORY_ID, fetchCategoryById)
}

//Add Category
function* createNewCategory({ payload: category, id }) {
    try {
        let response
        if (id) {
            response = yield call(updateCategory, category, id)
        } else {
            response = yield call(addCategory, category)
        }
        if (response.status?.toLowerCase() === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(addNewCategorySuccess(response))
        }
    } catch (error) {
        yield put(addNewCategoryError(error))
        toast.error(error.response.data.message)
    }
}
export function* AddCategorySaga() {
    yield takeEvery(ADD_NEW_CATEGORY, createNewCategory)
}

//Category Status 
function* categoryStatusUpdate({ payload: { status, id } }) {
    try {

        const response = yield call(changeCategoryStatus, status, id)

        if (response.status?.toLowerCase() === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(CategoryStatusSuccess(response))
        }
    } catch (error) {

        yield put(CategoryStatusError(error))
        toast.error(error.response.data.message)
    }
}
export function* categoryStatusSaga() {
    yield takeEvery(GET_CATEGORY_STATUS, categoryStatusUpdate)
}
//Get SubCategory
function* fetchSubCat({ payload: id }) {
    try {
        const response = yield call(getSubCategory, id);
        yield put(getSubCategorySuccess(response));
    } catch (error) {
        yield put(getSubCategoryFail(error))
    }
}
export function* SubCategorySaga() {
    yield takeEvery(GET_SUB_CATEGORY, fetchSubCat)
}
//Get Subcategory by Id 
function* fetchSubCategoryById({ payload: id }) {
    try {
        const response = yield call(getSubCategoryId, id)
        yield put(getSubCategoryIdSuccess(response.subcategory))
    } catch (error) {
        yield put(getSubCategoryIdFail(error))
    }
}
export function* GetSubcategoryByIdSaga() {
    yield takeEvery(GET_SUBCATEGORY_ID, fetchSubCategoryById)
}
//Add SubCategory
function* createSubCategory({ payload: { subcategory, subCategoryId } }) {
    try {
        let response
        if (!subCategoryId) {
            response = yield call(addSubCategory, subcategory)
        } else {
            response = yield call(updateSubCategory, subcategory, subCategoryId)
        }
        if (response.status?.toLowerCase() === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(addSubCategorySuccess(response))
        }
    } catch (error) {
        yield put(addSubCategoryError(error))
        toast.error(error.response.data.message)
    }
}
export function* AddSubCategorySaga() {
    yield takeEvery(ADD_SUB_CATEGORY, createSubCategory)
}

//SubCategory Status 
function* subcategoryStatusUpdate({ payload: { status, id } }) {
    try {
        const response = yield call(changeSubCategoryStatus, status, id)
        if (response.status?.toLowerCase() === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(subCategoryStatusSuccess(response))
        }
    } catch (error) {
        yield put(subCategoryStatusError(error))
        toast.error(error.response.data.message)
    }
}
export function* SubcategoryStatusSaga() {
    yield takeEvery(GET_SUBCATEGORY_STATUS, subcategoryStatusUpdate)
}

//get category default 

function* fetchCategoryDefaultById({ payload: { isDefault, id, handleReloadCategory } }) {
    try {
        const response = yield call(updateDefaultCategory, isDefault, id)
        if (response?.status?.toLowerCase() === "success") {
            yield put(CategoryDefaultSuccess(response.category))
            handleReloadCategory()
        } else {
            yield put(CategoryDefaultError(response))
        }
    } catch (error) {
        yield put(CategoryDefaultError(error))
    }
}
export function* GetCategoryDefaultByIdSaga() {
    yield takeEvery(GET_CATEGORY_DEFAULT, fetchCategoryDefaultById)
}

//subcategory default

function* fetchSubCategoryDefaultById({ payload: { isDefault, id, handleReload } }) {
    try {
        const response = yield call(updateDefaultSubCategory, isDefault, id)
        if (response?.status?.toLowerCase() === "success") {
            yield put(subCategoryDefaultSuccess(response.subcategory))
            handleReload()
        } else {
            yield put(subCategoryDefaultError(response))
        }
    } catch (error) {
        yield put(subCategoryDefaultError(error))
    }
}
export function* GetSubCategoryDefaultByIdSaga() {
    yield takeEvery(GET_SUBCATEGORY_DEFAULT, fetchSubCategoryDefaultById)
}