import React, { useEffect, useState } from "react"
import { Modal, Label } from "reactstrap"
import PrimaryButton from "../Buttons/PrimaryButton"
import SecountryBtn from "../Buttons/SecountryBtn"

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import { useDispatch, useSelector } from "react-redux"
import { AddNewCategory, getCategoryId, getMainCategory } from "store/actions"

import Select from "react-select"
import { GetCategoryType } from "constants/DefaultCategoryType"


const CategoryModal = ({ showModal, toggle, modalTitle, primaryBtn, hideCancel, categoryData }) => {

  const id = categoryData?._id

  const dispatch = useDispatch();

  const categoryReload = useSelector((state) => state?.AddCategory);
  const [categorytypedata, setCategorytypedata] = useState();


  // Form validation
  const categoryForm = {
    label: '',
    callType: 'Intraday',
    isDefault: false,
  };

  const categoryFormSchema = Yup.object().shape({
    label: Yup.string().required('Enter Category ').trim(),
    callType: Yup.string().required('Select a Category Type'),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, },
  } = useForm({
    resolver: yupResolver(categoryFormSchema),
    defaultValues: categoryForm,
  });

  const onSubmit = (values) => {
    const Data = {}
    Data['label'] = values.label
    Data['callType'] = values.callType
    Data["isDefault"] = values.isDefault === true ? "YES" : "NO";
    if (id) {
      dispatch(AddNewCategory(Data, id));
    } else {
      dispatch(AddNewCategory(Data));
    }
    reset()
    toggle();
  };

  const getCategoryData = () => {
    dispatch(getCategoryId(id));
  };

  useEffect(() => {
    if (id) {
      getCategoryData();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      setCategoryValues();
    } else {
      reset()
      setDropdownValue("INTRADAY")
    }
  }, [categoryData, id]);

  const setDropdownValue = (value) => {
    const result = GetCategoryType?.filter((item) => item?.value === value)
    if (result?.length > 0) {
      setCategorytypedata(result[0]);
    }
    else {
      setCategorytypedata({ label: "Select call type", value: "" });
    }
  }

  //For handling form values
  const setCategoryValues = () => {
    setValue("label", categoryData?.label || "");
    setValue("isDefault", categoryData?.isDefault?.toLowerCase() === 'yes' ? true : false);
    setValue("callType", categoryData?.callType || "");
    setDropdownValue(categoryData?.callType);
  };

  //for category reloading
  useEffect(() => {
    if (categoryReload?.category?.status === 'SUCCESS') {
      dispatch(getMainCategory());
    }
  }, [categoryReload]);

  return (
    <Modal
      size="sm"
      isOpen={showModal}
      centered={true}
      className="doc-modal confirmation"
      toggle={toggle}
    >
      <div className="modal-header px-0 border-0">
        <div className="head-item">
          <h1 className="page-title font-size-18 p-0 text-left">
            {modalTitle}
          </h1>
          <span
            onClick={() => {
              if (categoryData) {
                setCategoryValues()
              } else {
                reset()
              }
              toggle()
            }}
            aria-hidden="true"
            className="close-icon"
          >
            <i className="bx bx-x"></i>
          </span>
        </div>
      </div>
      <div className="modal-body px-0">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="form-horizontal"
        >
          <div className="modal-body">
            <div>
              <div className="mb-3">
                <Label className="form-label f-size-14">
                  Category <span>*</span>
                </Label>
                <input
                  type="text"
                  {...register("label")}
                  className={
                    errors.label?.message
                      ? "form-control is-invalid"
                      : "" + "form-control"
                  }
                />
                {errors.label?.message ? (
                  <p className={`invalid-feedback`}>{errors.label?.message}</p>
                ) : null}
              </div>
              <div className="mb-3">
                <div className={`position-relative drop-down`}>
                  <Label className="form-label f-size-14">
                    Call Type <span>*</span>
                  </Label>
                  <Select
                    options={GetCategoryType}
                    className={`form-control p-0 ${errors.callType?.message ? "is-invalid" : ""
                      }`}
                    classNamePrefix="custom_select"
                    search={true}
                    value={categorytypedata}
                    name="callType"
                    placeholder={`Select callType`}
                    onChange={selectedOption => {
                      setCategorytypedata(selectedOption)
                      setValue("callType", selectedOption.value)
                    }}
                  />
                  {errors.callType?.message ? (
                    <p className={`error-field`}>
                      {errors.callType?.message}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="mb-3">
                <div className="form-check form-check-inline">
                  {!(
                    categoryData?.status === "INACTIVE" ||
                    categoryData?.isDefault === "YES"
                  ) && (
                      <>
                        <input
                          type="checkbox"
                          name="isDefault"
                          id="isDefault"
                          {...register("isDefault")}
                          // checked={categoryData?.isDefault === "YES"}
                          className={`form-check-input ${errors.isDefault ? "is-invalid" : ""
                            }`}
                        />
                        {errors.isDefault?.message ? (
                          <p className={`error-field`}>{errors.isDefault?.message}</p>
                        ) : null}

                        <Label htmlFor="isDefault" className="form-label f-size-14 text-input">
                          Set as Default Category
                        </Label>
                      </>
                    )}
                </div>
              </div>
            </div>
            <div className="filter-btn d-flex justify-content-right mt-3">
              <PrimaryButton
                btnName={primaryBtn}
                showIcon={false}
                btnType="submit"
              />
              {!hideCancel && (
                <span>
                  <SecountryBtn
                    btnType="button"
                    onClick={() => {
                      if (id) {
                        setCategoryValues()
                      } else {
                        reset()
                        setCategorytypedata({
                          label: "Intraday",
                          value: "INTRADAY",
                        })
                      }
                    }}
                    btnName="Clear"
                    showIcon={false}
                  />
                </span>
              )}
            </div>
          </div>

        </form>
      </div>
    </Modal>
  )
}

export default CategoryModal

