import React from "react"
import {
    Card,
    CardBody,
    Col,
    CardFooter,
} from "reactstrap";
import Skeleton from "react-loading-skeleton";

const TipsCardSkeleton = () => {
    return (
        <React.Fragment>
            <Col className="">
                <Card className="text-center user-details">
                    <CardBody className="user-info">
                        <div className="d-flex" style={{ justifyContent: "space-between" }}>
                            <div className="d-flex" style={{ alignItems: "center" }}>
                                <Skeleton height={"16px"} width={'150px'} className="me-3" />
                                <Skeleton height={"20px"} width={'120px'} borderRadius={'30px'} />
                            </div>
                            <div className="d-flex" style={{ alignItems: "center" }}>
                                <Skeleton height={"30px"} width={'60px'} className="me-3" borderRadius={'30px'} />
                                <Skeleton height={"30px"} width={'30px'} />
                            </div>
                        </div>
                        <div className="mt-3" style={{ textAlign: "left" }}>
                            <Skeleton height={"20px"} />
                            <Skeleton height={"20px"} width={"95%"} />
                            <Skeleton height={"20px"} width={"85%"} />
                        </div>
                    </CardBody>
                    <CardFooter className="bg-transparent">
                        <div className="d-flex" style={{ justifyContent: "space-between" }}>
                            <div className="d-flex" style={{ alignItems: "center" }}>
                                <Skeleton height={"16px"} width={'180px'} className="me-3" />
                                <Skeleton height={"20px"} width={'140px'} borderRadius={'30px'} />
                            </div>
                            <div className="d-flex" style={{ alignItems: "center" }}>
                                <Skeleton height={"15px"} width={'30px'} className="me-3" />
                                <Skeleton height={"15px"} width={'30px'} className="me-3" />
                                <Skeleton height={"15px"} width={'30px'} className="me-3" />
                                <Skeleton height={"15px"} width={'30px'} />
                            </div>
                        </div>
                    </CardFooter>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default TipsCardSkeleton
