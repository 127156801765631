//get Review List
export const REVIEW_LIST = "REVIEW_LIST";
export const REVIEW_LIST_FAIL = "REVIEW_LIST_FAIL";
export const REVIEW_LIST_SUCCESS = "REVIEW_LIST_SUCCESS"; 

//get Review Details
export const REVIEW_DETAILS = "REVIEW_DETAILS";
export const REVIEW_DETAILS_FAIL = "REVIEW_DETAILS_FAIL";
export const REVIEW_DETAILS_SUCCESS = "REVIEW_DETAILS_SUCCESS";

//get Review Publish
export const REVIEW_PUBLISH = "REVIEW_PUBLISH";
export const REVIEW_PUBLISH_FAIL = "REVIEW_PUBLISH_FAIL";
export const REVIEW_PUBLISH_SUCCESS = "REVIEW_PUBLISH_SUCCESS"; 