import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';

import Breadcrumbs from "../../components/Common/Breadcrumb"
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup"
import { AddNewAdvisor, getAdvisorById } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageLoader from 'components/Common/PageLoader';

const AdvisorForm = props => {
    let { id } = useParams();
    document.title = `${!id ? "Add New MarketGenius" : "Update MarketGenius"} | FiDeal AdminPanel`
    const dispatch = useDispatch();
    const advisor = useSelector(state => state?.GetAdvisorById?.advisor?.advisor);
    const loading = useSelector(state => ({ loaderstatus: state.AddAdvisor.loading }));
    const isEditAdvisorEmail = !!id;


    const advisorFormSchema = Yup.object().shape({
        firstName: Yup.string().required("Enter first name").max(30, 'First name should not exceed 30 characters').trim(),
        lastName: Yup.string().required("Enter last name").max(30, 'Last name should not exceed 30 characters').trim(),
        email: Yup.string().email('Enter valid email address').required("Enter email address").max(30, 'Enter email address should not exceed 30 characters').trim(),
        mobileNumber: Yup.string().required("Enter phone number").max(15, 'Phone number should not exceed 15 characters').trim(),
        description: Yup.string().required("Enter description")
    })
    const { register, handleSubmit, reset, formState: { errors, }, } = useForm({
        resolver: yupResolver(advisorFormSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            description: ""
        }
    });
    //submitting values
    const onSubmit = (values) => {
        if (id) {
            dispatch(AddNewAdvisor(values, props.history, id))
        } else {
            dispatch(AddNewAdvisor(values, props.history))
        }
    }
    const getAdvisorData = () => {
        dispatch(getAdvisorById(id))
    }
    useEffect(() => {
        if (id) {
            getAdvisorData()
        }
    }, [id])

    useEffect(() => {
        if (id) {
            setFormValues()
        }
    }, [advisor, id])
    //for handling form values
    const setFormValues = () => {
        reset(
            {
                firstName: advisor?.firstName || "",
                lastName: advisor?.lastName || "",
                email: advisor?.email || "",
                mobileNumber: advisor?.mobileNumber || "",
                description: advisor?.description || "",
            }
        )
    }

    return (
        <React.Fragment>
            {loading.loaderstatus ? <PageLoader /> : ""}
            <div className='page-content'>
                <Container fluid={true}>
                    <Breadcrumbs title='MarketGenius' dashurl='/marketgenius' breadcrumbItem={!id ? "Add New MarketGenius" : "Update MarketGenius"} />
                    <Row>
                        <Col xl='12'>
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                                className="form-horizontal">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg="8">
                                                <Row>
                                                    <Col md="6">
                                                        <div className='mb-3'>
                                                            <Label className="form-label f-size-14">FirstName<span>*</span></Label>
                                                            <input type="text" {...register("firstName")} className={errors.firstName?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                                            {errors.firstName?.message ? <p className={`invalid-feedback`} >{errors.firstName?.message}</p> : null}
                                                        </div>

                                                    </Col>

                                                    <Col md="6">
                                                        <div className='mb-3'>
                                                            <Label className="form-label f-size-14">LastName<span>*</span></Label>
                                                            <input type="text" {...register("lastName")} className={errors.lastName?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                                            {errors.lastName?.message ? <p className={`invalid-feedback`} >{errors.lastName?.message}</p> : null}
                                                        </div>
                                                    </Col>

                                                    <Col md="6">
                                                        <div className='mb-3' >
                                                            <Label className="form-label f-size-14">Email address<span>*</span></Label>
                                                            <input type="email" {...register("email")} className={errors.email?.message ? 'form-control is-invalid' : '' + 'form-control'} 
                                                            disabled={isEditAdvisorEmail}
                                                            />
                                                            {errors.email?.message ? <p className={`invalid-feedback`} >{errors.email?.message}</p> : null}
                                                        </div>
                                                    </Col>

                                                    <Col md="6">
                                                        <div className="mb-3">
                                                            <Label className="form-label f-size-14">Phone Number<span>*</span></Label>
                                                            <input type="number" {...register("mobileNumber")} className={errors.mobileNumber?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                                            {errors.mobileNumber?.message ? <p className={`invalid-feedback`} >{errors.mobileNumber?.message}</p> : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg="4">
                                                <Row>
                                                    <Col lg="12">
                                                        <div className='mb-3'>
                                                            <Label className="form-label f-size-14">Description<span>*</span></Label>
                                                            <textarea
                                                                {...register("description")}
                                                                className={errors.description?.message ? 'form-control text-area is-invalid' : 'form-control text-area'}
                                                            ></textarea>
                                                            {errors.description?.message ? (
                                                                <p className={`invalid-feedback`}>{errors.description?.message}</p>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>
                                        <div className='mb-3 pt-3 d-flex btn-group'>
                                            <PrimaryButton btnName="Save" showIcon={false} btnType={'submit'} />
                                            <SecountryBtn btnType='button' onClick={() => {
                                                if (id) {
                                                    setFormValues()
                                                } else {
                                                    reset();

                                                }
                                            }} btnName='Clear' showIcon={false} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AdvisorForm;