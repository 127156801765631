import classnames from "classnames";
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Card, CardBody, Container, Nav, NavItem, TabContent, TabPane } from 'reactstrap';
import Advisors from "./Advisors";
import SubmittedAdvisor from "./SubmittedAdvisor";

const AdvisorsMainComponent = () => {
    const { tab } = useParams();
    const history = useHistory();

    const [myDocActiveTab, setmyDocActiveTab] = useState("request");

    const toggleCustom = currentTab => {
        if (myDocActiveTab !== currentTab) {
            setmyDocActiveTab(currentTab);

            if (tab) {
                history.push(`/marketgenius/${currentTab}`);
            };
        };
    };
    
    useEffect(() => {
        if (tab) {
            setmyDocActiveTab(tab);
        }
    }, [tab])

    return (
        <>
            <div className="page-content settings">
                <Container fluid>
                    <h1 className='page-title'>{myDocActiveTab === "request" ? "Signup requests" : "MarketGenius"}</h1>
                    <div className='nav-tabs-custom nav-justified'>
                        <Nav className="">
                            <NavItem>
                                <div
                                    className={`tab-item ${classnames({
                                        active: myDocActiveTab === "request",
                                    })}`}
                                    onClick={() => { toggleCustom("request"); }}  >
                                    <span className="d-sm-block">Signup requests</span>

                                </div>
                            </NavItem>
                            <NavItem>
                                <div className={`tab-item ${classnames({ active: myDocActiveTab === "list", })}`}
                                    onClick={() => {
                                        toggleCustom("list");
                                    }}>
                                    <span className="d-sm-block">MarketGenius list</span>
                                </div>
                            </NavItem>
                        </Nav>
                    </div>
                    <TabContent activeTab={myDocActiveTab} className="table-content py-4">
                        {
                            myDocActiveTab == "request" &&
                            <TabPane tabId="request">
                                <SubmittedAdvisor />
                            </TabPane>
                        }
                        {
                            myDocActiveTab == "list" &&
                            <TabPane tabId="list">
                                <Advisors />
                            </TabPane>
                        }
                    </TabContent >

                </Container >
            </div >
        </>
    )
}

export default AdvisorsMainComponent;