import React, { useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getSubmittedAdvisor } from 'store/actions';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import ManageCard from 'components/Common/Cards/ManageCard';
import ManageCardSkeleton from 'components/Common/Skeleton/ManageCardSkeleton';
import { useHistory } from "react-router-dom";
import NoDataFound from 'components/Common/NoDataFound';
import { debounce } from 'lodash';

const SubmittedAdvisor = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    document.title = "Submitted MarketGenius | FiDeal AdminPanel"

    const submittedData = useSelector(state => (state?.GetSubmittedAdvisors?.submittedList?.data));

    const getList = (value) => {
        dispatch(getSubmittedAdvisor({ search: value }))
    }

    useEffect(() => {
        getList("")
    }, [])

    const handleSearch = debounce((value) => {
        getList(value);
    }, 1000)

    return (
        <React.Fragment>
            <div className="advisors-screen">
                <Container fluid>
                    <div className="title-grp">
                        <h1 className='page-title'></h1>
                        <div className="search-addNew-div">
                            <div className='d-flex justify-content-between filter-section'>
                                <div className="action-field">
                                    <div className="search-box d-inline-block me-2 field">
                                        <div className="position-relative">
                                            <div className="search-label m-0">
                                                <input
                                                    onChange={e => {
                                                        handleSearch(e.target.value)
                                                    }}
                                                    id="search-bar-0"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={`Search by name / email`}
                                                />
                                            </div>
                                            <i className="bx bx-search-alt search-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {!submittedData ? <Row className="user-card-view skeleton">
                            {[...Array(12)].map((item, index) => {
                                return <ManageCardSkeleton key={index} />;
                            })}
                        </Row> : <>{submittedData?.length === 0 ? <NoDataFound /> : <div className="user-card-view"> {submittedData?.map((user, key) => {
                            return (<ManageCard user={user} key={"_user_" + key} type="submitted" />);
                        })}</div>}</>}
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SubmittedAdvisor;