import React from 'react';
import Skeleton from "react-loading-skeleton";
import { Card, CardBody } from 'reactstrap';

const ManageCardSkeleton = () => {
    return (
        <div className='manage-card p-0'>
            <Card className='p-0 mb-0'>
                <CardBody className='p-0'>
                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <div className='d-flex p-1'>
                            <div className='profile'>
                                <div className={`active-status`}></div>
                                <Skeleton height={"35px"} width={'35px'} borderRadius={'50px'} />
                            </div>
                            <div className='ps-3 w-100'>
                                <Skeleton height={"16px"} width={'150px'} />
                                <div><Skeleton height={"14px"} width={'100%'} /></div>
                            </div>
                        </div>
                        <div className=''>
                            <Skeleton height={"26px"} width={'26px'} />
                        </div>
                    </div>
                    <hr />
                    <div className='crd-details p-3'>
                        <div>
                            <Skeleton height={"16px"} width={'100%'} />
                            <Skeleton height={"16px"} width={'70%'} />
                        </div>
                        <div>
                            <Skeleton height={"16px"} width={'100%'} />
                            <Skeleton height={"16px"} width={'70%'} />
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default ManageCardSkeleton;