import ViewAdvisorSkeleton from 'components/Common/Skeleton/ViewAdvisorSkeleton';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelect } from 'react-select-search';
import { Card, CardBody, Container } from 'reactstrap';
import { getUserById } from 'store/actions';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PageLoader from 'components/Common/PageLoader';

const UserDetails = () => {
    let { id } = useParams();
    const dispatch = useDispatch();

    document.title = "View MarketGenius | FiDeal AdminPanel"

    const viewData = useSelector(state => (state?.GetUserById?.user));
    const viewDataLoading = useSelector(state => (state?.GetUserById));
    const UserDetailsLoading = useSelector(state => (state?.GetUserById?.loading))

    useEffect(() => {
        dispatch(getUserById(id));
    }, [id]);

    return (
        <React.Fragment>
            {UserDetailsLoading ? <PageLoader /> : ""}
            <div className="page-content view-advisor">
                <Container fluid>
                    <Breadcrumbs title="Users" dashurl="/users" breadcrumbItem={"User Detail"} />

                    <div>
                        {viewDataLoading === false ? (
                            <ViewAdvisorSkeleton />
                        ) : (
                            <div className='manage-card'>
                                <Card className='p-0'>
                                    <CardBody className='p-0'>
                                        <div className='d-flex justify-content-between align-items-center p-3'>
                                            <div className='d-flex align-items-center p-1'>
                                                <div className='profile'>
                                                    <div className={`active-status ${viewData?.status?.toLowerCase()}`}></div>
                                                    {viewData?.profileImg ? <span className='profile-img'><img src={viewData?.profileImg} alt="profile" /></span> :
                                                        <span>{viewData?.firstName?.charAt(0)?.toUpperCase()}</span>
                                                    }
                                                </div>
                                                <div className='ps-3 name-head'>
                                                    <h5 className='m-0 text-capitalize'>{viewData?.firstName}&nbsp;{viewData?.lastName}</h5>
                                                </div>
                                            </div>
                                            <div className={`view-status ${viewData?.status.toLowerCase()}`}>
                                                <span className={`text-capitalize`}> {viewData?.status.toLowerCase() === "active" ? "active" : "suspended"}</span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='advisor-body'>
                                            <div className='crd-details'>
                                                <div>
                                                    <span>First Name</span>
                                                    <p className='text-capitalize'>{viewData?.firstName}</p>
                                                </div>
                                                <div>
                                                    <span>Last name</span>
                                                    <p className='text-capitalize'>{viewData?.lastName}</p>
                                                </div>
                                                <div>
                                                    <span>Email</span>
                                                    <p>{viewData?.email}</p>
                                                </div>
                                                <div>
                                                    <span>Mobile Number</span>
                                                    <p>{viewData?.mobileNumber}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        )
                        }

                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserDetails;