import React, { useEffect, useState } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { Container, Row, Button, Input, Col } from "reactstrap"
import { debounce, map } from "lodash"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useSelector, useDispatch } from "react-redux"
import { getAllAdmin } from "../../store/actions"
import NoDataFound from "components/Common/NoDataFound";
import SkeletonUserCard from '../../components/Common/SkeletonUserCard';
import { handleFilters } from "constants/SearchAndFilter";
import SecountryBtn from "components/Common/Buttons/SecountryBtn"
import AdminCard from "components/Common/Cards/AdminCard"
import PrimaryButton from "components/Common/Buttons/PrimaryButton"
import Pagination from "components/Common/pagination/Pagination"
import { dataPerPage } from "constants/dataCount"

const AdminList = () => {
  //meta title
  document.title = "Admins | FiDeal AdminPanel"
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const adminList = useSelector(state => (state?.GetAdmin?.admin?.user));
  const { totalCount } = useSelector(state => (state?.GetAdmin?.admin));

  const onChangeStatus = useSelector(state => (state?.ChangeStatus));

  const getAll = () => {
    dispatch(getAllAdmin({ search: { search: searchValue }, currentPage, dataPerPage }))
  }

  useEffect(() => {
    getAll()
  }, [searchValue, currentPage])

  useEffect(() => {
    if (onChangeStatus?.success?.status === "SUCCESS") {
      getAll()
    }
  }, [onChangeStatus]);

  const handleSearch = debounce((value) => {
    setSearchValue(value);
    setCurrentPage(1)
  }, 1000)

  return (
    <React.Fragment>
      <div className="page-content admins-list">
        <Container fluid>
          <div className="title-grp">
            <h1 className='page-title'>Admins</h1>
            <div className="search-addNew-div">
              <div className='d-flex justify-content-between filter-section'>
                <div className="action-field">
                  <div className="search-box d-inline-block me-2 field">
                    <div className="position-relative">
                      <div className="search-label m-0">
                        <input
                          onChange={e => {
                            handleSearch(e.target.value)
                          }}
                          id="search-bar-0"
                          type="text"
                          className="form-control"
                          placeholder={`Search by name / email`}
                        />
                      </div>
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
                <PrimaryButton btnName="Add New" showIcon={false} btnType={'button'} onClick={() => {
                  history.push("/admin/add-new-admin")
                }} />
              </div>
            </div>
          </div>
          {!adminList ? <Row className="user-card-view skeleton px-2">
            {[...Array(12)].map((item, index) => {
              return <SkeletonUserCard key={index} />;
            })}
          </Row> : <>{adminList?.length === 0 ? <NoDataFound /> : <Row className="user-card-view px-2"> {adminList?.map((user, key) => {
            return (<AdminCard user={user} key={"_user_" + key} setCurrentPage={setCurrentPage} currentPage={currentPage} totalCount={totalCount} customPageSize={dataPerPage} />);
          })}
          </Row>
          }</>}
          <div>{
            totalCount > dataPerPage &&
            <Pagination
              className="pagination-bar custom-pagination"
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={dataPerPage}
              onPageChange={page => setCurrentPage(page)}
            />
          }
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(AdminList)
