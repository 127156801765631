import moment from 'moment/moment';
import React, { useState } from 'react';
import { Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Confirmation from '../Modals/Confirmation';
import { changeTipsStatus, closeTip, deleteTips, tipTargetUpdate } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import dislike from '../../../assets/images/svgicons/dislike.svg';
import like from '../../../assets/images/svgicons/like.svg';
import update_batch from '../../../assets/images/svg/update_batch.svg';
import ViewTips from '../Modals/ViewTips';
import UpdateTip from '../Modals/UpdateTip';

const TipsBlogCard = ({ tips, onUpdate }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const [changeStatusModal, setChangeStatusModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isViewTip, setviewTip] = useState(false);
    const [openUpdateModel, setOpenUpdateModel] = useState(false);
    const [closeTipModel, setCloseTipModel] = useState(false);
    const [tipTargetModel, setTipTargetModel] = useState(false);

    const endValue = "true", maxLength = 160;

    const userInfo = useSelector(state => (state?.authInfo?.data?.user));

    function activeToggleModal() {
        setChangeStatusModal(!changeStatusModal);
    }

    // const editTipsPath = (id) => {
    //     history.push(`/tips/update-tips/${id}`);
    // }

    const viewTipsPath = () => {
        setviewTip(!isViewTip);
        // history.push(`/tips/view-geniustalk/${id}`);
    }
    const viewTipModalClose = () => {
        setviewTip(false);
    }
    //Update the Status
    const updateTipsStatus = () => {
        let status
        if (tips?.status === "INACTIVE") {
            status = "ACTIVE"
        } else {
            status = "INACTIVE"
        }
        dispatch(changeTipsStatus(status, tips?._id, onUpdate));
        setChangeStatusModal(false);
    };

    //Delete Tips
    const deleteToogleModal = () => {
        setDeleteModal(!deleteModal);
    };

    const deleteTipsItem = () => {
        dispatch(deleteTips(tips?._id, history));
        setDeleteModal(false);
    };

    const handleUpdateModel = () => {
        setOpenUpdateModel(!openUpdateModel)
    }

    const toggleCloseTipModel = () => {
        setCloseTipModel(!closeTipModel);
    }

    const handleCloseTip = () => {
        setCloseTipModel(true);
    }

    const afterSuccess = () => {
        setCloseTipModel(false);
        setTipTargetModel(false)
        onUpdate();
    }

    const handleCloseTipSuccess = () => {
        const data = {};
        data['tipStatus'] = "closed";
        data['tipId'] = tips?._id;
        dispatch(closeTip(data, afterSuccess))
    }

    const toggleTargetModel = () => {
        setTipTargetModel(!tipTargetModel);
    }

    const handleUpdateTargetStatus = () => {
        setTipTargetModel(true);
    }

    const handleTargetStatusChange = (val) => {
        const data = {};
        data['tipId'] = tips?._id;
        data['isTargetAchieved'] = val;
        dispatch(tipTargetUpdate(data, afterSuccess))
    }

    return (
        <div>
            <div className={`tip-card p-0 m-0 ${tips?.status?.toLowerCase()} ${tips?.tipStatus === "CLOSED" ? 'closed-tip' : ""}`}>
                <div className='closed-text'>
                    {userInfo?.role === "ADVISOR" ?
                        <p>
                            Closed
                        </p>
                        : <>
                            {tips?.isTargetAchieved === "YES" ?
                                <p>Achieved </p> : <p> Not achieved </p>
                            }
                        </>
                    }

                </div>
                <Card className={`p-0 m-0 ${userInfo?.role === "ADVISOR" ? '' : `${tips?.isTargetAchieved === "YES" ? "achieved" : tips?.isTargetAchieved === "NO" ? "not-achieved" : ""}`} `}>
                    <CardBody className='p-0'>
                        <div className='tip-group'>
                            <div className='p-4 tip-content'>
                                <div className='blog-header'>
                                    <div className='d-flex w-100 genius-talk-heading'>
                                        <ul>
                                            <li>
                                                <p className='m-0 text-capitalize main-cat'>{tips?.category?.label}&nbsp;&nbsp;<span className='text-capitalize sub-cat'>{tips?.subcategory?.label}</span></p>

                                            </li>

                                        </ul>
                                        <div className='updated-badge'>
                                            <div className='badge'>
                                                <p className={`stock-tiptype m-0 ${tips?.stockTip?.toLowerCase()}`}>
                                                    {tips?.stockTip}
                                                </p>
                                            </div>
                                            <div>
                                                <img style={{ width: "90px" }} src={update_batch} alt='profile' />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <ul>
                                            {tips?.description?.length > 1 && <li className='updated-badge'>
                                                <img style={{ width: "90px" }} src={update_batch} alt='profile' />
                                            </li>}
                                            <li className='updated-badge'>
                                                <div className='badge'>
                                                    <p className={`stock-tiptype m-0 ${tips?.stockTip?.toLowerCase()}`}>
                                                        {tips?.stockTip?.replace(/_/g, ' ')}
                                                    </p>
                                                </div>
                                            </li>
                                            <li className='me-0'>
                                                <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'} end={endValue.toString()}>
                                                    <DropdownToggle caret className='more-option'>
                                                        <i className='bx bx-dots-vertical-rounded'></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => { viewTipsPath() }}>View</DropdownItem>
                                                        {tips?.tipStatus === "CLOSED" && tips?.isTargetAchieved === "NULL" && userInfo?.role !== "ADVISOR" &&
                                                            <DropdownItem onClick={() => handleUpdateTargetStatus()}>Set GeniusTalk Target</DropdownItem>
                                                        }
                                                        {tips?.tipStatus !== "CLOSED" &&
                                                            <>
                                                                {userInfo?.role === "ADVISOR" ? <>
                                                                    <DropdownItem onClick={() => { handleUpdateModel() }}>Update</DropdownItem>
                                                                    <DropdownItem onClick={() => handleCloseTip()}>Close geniustalk</DropdownItem>
                                                                </> :
                                                                    <>
                                                                        <DropdownItem disabled={tips?.status?.toUpperCase() === "ACTIVE" ? true : false}>
                                                                            <div onClick={() => {
                                                                                activeToggleModal();
                                                                            }}>Activate</div>
                                                                        </DropdownItem>
                                                                        <DropdownItem disabled={tips?.status?.toUpperCase() === "INACTIVE" ? true : false}>
                                                                            <div onClick={() => {
                                                                                activeToggleModal();
                                                                            }}>Suspend </div>
                                                                        </DropdownItem>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className='blog-header'>
                                    <div className='d-flex w-100'>
                                        <ul>
                                            <li>
                                                <p className='m-0'>
                                                    {tips?.description?.length > 0 ? moment(tips?.description[0]?.updatedAt).format('LT') : ""}{" "}{tips?.description[0]?.updatedAt ? moment(tips?.description[0]?.updatedAt).format('ll') : ""}
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className='descriptions'>
                                    <div className='tip-desc'>
                                        {tips?.description?.length > 0 ? <h5 className='mb1 pb-1'>
                                            {tips?.description[0]?.description.length > maxLength ? tips?.description[0]?.description?.substring(0, maxLength) + "...." : tips?.description[0]?.description}
                                        </h5> : ""}
                                    </div>
                                </div>
                                <div className='advisor-card-bottom'>
                                    {userInfo?.role === "SUPER_ADMIN" && (
                                        <ul className='tips-auth-info'>
                                            <>
                                                <li className='text-capitalize d-flex align-items-center'>
                                                    <div className='rounded-circle ad-profile'>
                                                        {tips?.advisor?.profilePath ? <img src={tips?.advisor?.profilePath} alt="profile-img" /> :
                                                            <span>{tips?.advisor?.firstName?.charAt(0).toUpperCase()}</span>
                                                        }
                                                    </div>
                                                    <p className='ad-name'>{tips?.advisor?.firstName + " " + tips?.advisor?.lastName}</p>
                                                </li>
                                            </>
                                        </ul>
                                    )}
                                    <ul className='like-dislike'>
                                        <li className='d-flex me-3'>
                                            <img src={like} alt='profile' className='me-2' />
                                            {tips?.likesCount}</li>
                                        <li className='d-flex'>
                                            <img src={dislike} alt='profile' className='me-2' />
                                            {tips?.disLikesCount}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
            <Confirmation
                showModal={changeStatusModal}
                toggle={activeToggleModal}
                primaryAction={updateTipsStatus}
                modalTitle={`Confirmation!`}
                primaryBtn={`${tips?.status === "INACTIVE" ? 'Activate' : 'suspend '}`}
                secountBtn="Cancel"
                description={`Are you sure, do you want to  ${tips?.status === "ACTIVE" ? 'suspend' : 'activate'} this geniustalk?`}
            />
            {/* Delete modal */}
            <Confirmation
                showModal={deleteModal}
                toggle={deleteToogleModal}
                primaryAction={deleteTipsItem}
                modalTitle={`Delete GeniusTalk`}
                primaryBtn={`Delete`}
                secountBtn="Cancel"
                description={`Are you sure, do you want to  Delete this geniustalk?`}
            />

            <ViewTips
                showModal={isViewTip}
                toggle={viewTipModalClose}
                modalTitle="View GeniusTalk"
                tipData={tips}
            />

            <UpdateTip
                showModal={openUpdateModel}
                toggle={handleUpdateModel}
                modalTitle="Update GeniusTalk"
                tipData={tips}
                onUpdate={onUpdate}
            />

            {/* Close tip */}
            <Confirmation
                showModal={closeTipModel}
                toggle={toggleCloseTipModel}
                primaryAction={handleCloseTipSuccess}
                modalTitle={`Close GeniusTalk`}
                primaryBtn={`Close GeniusTalk`}
                secountBtn="Cancel"
                description={`Are you sure, do you want to  close this geniustalk?`}
            />

            {/* tip target status*/}
            <Confirmation
                showModal={tipTargetModel}
                toggle={toggleTargetModel}
                primaryAction={() => handleTargetStatusChange("yes")}
                secondaryAction={() => handleTargetStatusChange("no")}
                modalTitle={`GeniusTalk Target`}
                primaryBtn={`Achieved`}
                secountBtn="Not Achieved"
                description={`Please make sure before updating geniustalk target status`}
            />
        </div >
    );
};

export default TipsBlogCard;