import React, { useEffect, useState } from 'react';
import { Modal, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addSubCategory, getMainCategory, getSubCategory, getSubCategoryId } from 'store/actions';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecountryBtn from '../Buttons/SecountryBtn';

const SubCategoryModal = ({ showModal, toggle, modalTitle, primaryBtn, hideCancel, subCategoryData, categoryId, isEdit }) => {

    const dispatch = useDispatch();
    // Form validation

    const subCategoryForm = {
        label: '',
        isDefault: '',
    };
    const categoryFormSchema = Yup.object().shape({
        label: Yup.string().required('Enter Sub-Category').trim(),
    });
    const { register, handleSubmit, reset, formState: { errors }, control, setValue, getValues } = useForm({
        resolver: yupResolver(categoryFormSchema),
        defaultValues: subCategoryForm,
    });
    const onSubmit = (values) => {
        const Data = {}
        Data['label'] = values.label
        Data['isDefault'] = values.isDefault
        Data['category'] = categoryId
        Data["isDefault"] = values.isDefault ? "YES" : "NO";
        dispatch(addSubCategory(Data, categoryId, subCategoryData?._id));
        reset()
        toggle();
    };

    useEffect(() => {
        if (categoryId) {
            setSubCategoryValues();
        }

    }, [subCategoryData, categoryId]);

    //For handling form values
    const setSubCategoryValues = () => {
        setValue('label', subCategoryData?.label || '')
        setValue('isDefault', subCategoryData?.isDefault?.toLowerCase() === 'yes' ? true : false)
    };

    useEffect(() => {
        dispatch(getSubCategory(categoryId));
    }, [categoryId]);

    useEffect(() => {
        dispatch(getMainCategory());
    }, []);


    return (
        <div>
            <Modal size="sm" isOpen={showModal} centered={true}
                className='doc-modal confirmation'
                toggle={toggle}
            >
                <div className="modal-header px-0 border-0">
                    <div className='head-item'>
                        <h1 className='page-title font-size-18 p-0'>{modalTitle}</h1>
                        <span onClick={() => {
                            if (subCategoryData) {
                                setSubCategoryValues()
                            } else {
                                reset()
                            }
                            toggle()
                        }}
                            aria-hidden="true" className='close-icon'><i className='bx bx-x'></i></span>
                    </div>
                </div>
                <div className="modal-body px-0">
                    <div>
                        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}
                            className="form-horizontal">
                            <div className="modal-body">
                                <div>
                                    <div className="mb-3">
                                        <Label className="form-label f-size-14  text-start">Sub-Category <span>*</span></Label>
                                        <input type="text" {...register('label')} className={errors.label ? 'form-control is-invalid' : 'form-control'} />
                                        {errors.label && <p className={`invalid-feedback`}>{errors.label.message}</p>}
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="form-check form-check-inline">
                                        {!(
                                            subCategoryData?.status === "INACTIVE" ||
                                            subCategoryData?.isDefault === "YES"
                                        ) && (
                                                <>
                                                    <input
                                                        type="checkbox"
                                                        name="isDefault"
                                                        id="isDefault"
                                                        {...register('isDefault')}
                                                        className={`form-check-input ${errors.isDefault ? 'is-invalid' : ''}`}
                                                    />
                                                    {/* {errors.isDefault?.message ? <p className={`error-field`} >{errors.isDefault?.message}</p> : null} */}
                                                    <Label htmlFor='isDefault' className="form-label f-size-14 text-input">Set as default</Label>
                                                </>
                                            )}
                                    </div>
                                </div>
                                <div className='filter-btn d-flex justify-content-center mt-3'>
                                    <PrimaryButton btnName={primaryBtn} showIcon={false} btnType="submit" />
                                    {!hideCancel && (
                                        <span>
                                            <SecountryBtn btnType='button' onClick={() => {
                                                if (categoryId) {
                                                    setSubCategoryValues()
                                                } else {
                                                    reset()
                                                }
                                            }} btnName='Clear' showIcon={false} />
                                        </span>
                                    )}
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </Modal>

        </div>
    );
};


export default SubCategoryModal;
