import React, { useEffect, useRef, useState } from 'react';
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom"
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup"
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import { addTips, getActiveAdvisor, getMainCategory, getSubCategory, getTipType, getTipsById } from 'store/actions';

import Breadcrumbs from "../../components/Common/Breadcrumb"
import { StockTip } from 'constants/DefaultSelectValue';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';

import calendarIcon from '../../assets/images/svgicons/calendar-lg.svg'
const AddTips = () => {

    let { id } = useParams()
    const dispatch = useDispatch();
    const history = useHistory();

    document.title = `${!id ? "Add New GeniusTalk" : "Update GeniusTalk"} | FiDeal AdminPanel`;

    const mainCategory = useSelector(state => (state?.GetMainCategory?.category?.category));
    const tipTypeData = useSelector(state => (state?.GetTipType?.tipType));
    const subcategory = useSelector(state => (state?.GetSubCategory?.subcat?.subcategory));
    const userInfo = useSelector(state => (state?.authInfo?.data?.user));
    const tipsData = useSelector(state => (state?.GetTipsById?.tipsData?.tips));
    const activeAdvisors = useSelector(state => (state?.GetActiveAdvisors?.advisorList?.advisors));

    const [isStockTip, setStockTip] = useState();
    const [isTipType, setTipType] = useState();
    const [isCategory, setCategory] = useState();
    const [isSubcategory, setSubcategory] = useState({ label: 'Select category', value: "" });
    const [focusInput, setFocusInput] = useState({ startDate: false, endDate: false });
    const [startDateValue, setStartDateValue] = useState();

    const setCategoryValue = () => {
        if (mainCategory && mainCategory?.length > 0) {
            const setDefaultVale = mainCategory?.filter((item) => item?.isDefault === 'YES')
            if (setDefaultVale?.length > 0) {
                setCategory(setDefaultVale[0]);
                setValue("category", setDefaultVale[0]?._id)
                if (setDefaultVale[0]?.callType === 'INTRADAY') {
                    setValue("startDate", new Date())
                    setValue("endDate", new Date())
                }
                dispatch(getSubCategory(setDefaultVale[0]?._id));
            } else {
                setCategory({ label: "Select Category" })
            }
        } else {
            setCategory({ label: "Select Category" })
        }
    }

    useEffect(() => {
        setCategoryValue();
    }, [mainCategory]);

    const setSubcategoryValue = () => {
        if (subcategory && subcategory?.length > 0) {
            const setDefaultSubCat = subcategory?.filter((item) => item?.isDefault === 'YES')
            if (setDefaultSubCat?.length > 0) {
                setSubcategory(setDefaultSubCat[0]);
                setValue("subcategory", setDefaultSubCat[0]?._id)
                clearErrors('subcategory')
            } else {
                setSubcategory({ label: 'Select subcategory', value: "" });
            }
        } else {
            setSubcategory({ label: 'Select subcategory', value: "" });
        }
    }

    useEffect(() => {
        setSubcategoryValue();
    }, [subcategory]);

    const tipForm = {
        advisor: "",
        stockTip: "",
        description: "",
        tipType: "",
        category: "",
        subcategory: "",
        startDate: "",
        endDate: "",
    }

    const tipSchema = Yup.object().shape({
        advisor: Yup.string().required("Select marketgenius"),
        stockTip: Yup.string().required("Select stock tip"),
        description: Yup.string().required("Enter your description").trim().max(150, "Answer should not exceed 150 characters"),
        tipType: Yup.string().required("Select tips type"),
        category: Yup.string().required("Select category"),
        subcategory: Yup.string().required("Select subcategory"),
        startDate: Yup.string().required("Choose start date"),
        endDate: Yup.string().required("Choose start date"),
    })

    const { register, handleSubmit, setValue, getValues, reset, control, clearErrors, formState: { errors, }, } = useForm({
        resolver: yupResolver(tipSchema),
        defaultValues: tipForm
    });
    const onSubmit = (values) => {
        const tipData = {}
        tipData["stockTip"] = values.stockTip
        tipData["description"] = values.description
        tipData["tipType"] = values.tipType
        tipData["category"] = values.category
        tipData["subcategory"] = values.subcategory
        tipData["startDate"] = values.startDate
        tipData["endDate"] = values.endDate
        tipData["advisor"] = values?.advisor

        if (id) {
            dispatch(addTips(tipData, history, id));
        } else {
            dispatch(addTips(tipData, history));
        }
    }

    useEffect(() => {
        dispatch(getMainCategory());
        dispatch(getTipType());
    }, []);

    //====Tips Data Update=====
    useEffect(() => {
        if (id) {
            dispatch(getTipsById(id));
        }
    }, [id])

    const setAdvisorValue = () => {
        if (!tipsData?.advisor?._id) {
            setValue('advisor', userInfo?.id)
        }
    }

    useEffect(() => {
        setAdvisorValue();
    }, [userInfo, tipsData])

    const handleCategoryChange = (value) => {
        setCategory(value)
        setValue('category', value?._id);
        dispatch(getSubCategory(value?._id));
        clearErrors("category")
        if (value?.callType === 'INTRADAY') {
            setValue("startDate", new Date())
            setValue("endDate", new Date())
        } else {
            setValue("startDate", "")
            setValue("endDate", "")
        }
        setFocusInput({ startDate: false, endDate: false })
    }

    useEffect(() => {
        window.addEventListener("click", myFunction);
    }, [isCategory])

    function myFunction(e) {
        if (isCategory?.callType !== "INTRADAY") {
            if (["startDateInput", "startDateIcon", "iconStartDate"].includes(e.target.id)) {
                setFocusInput({ startDate: true, endDate: false })
            } else if (["endDateInput", "endDateIcon", "iconEndDate"].includes(e.target.id)) {
                setFocusInput({ startDate: false, endDate: true })
            } else {
                setFocusInput({ startDate: false, endDate: false })
            }
        } else {
            setFocusInput({ startDate: false, endDate: false })
        }
    }

    const handleClear = () => {
        reset();
        setSubcategoryValue();
        setCategoryValue();
        setAdvisorValue();
        setStockTip({ label: "Select stock geniustalk", value: "" });
        setTipType({ label: "Selec geniustalk type", value: "" });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="GeniusTalk" dashurl="/geniustalk" breadcrumbItem={!id ? "Add New GeniusTalk" : "Update GeniusTalk"} />
                    <div>
                        <Row>
                            <Col xl="12">
                                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                                    className="form-horizontal" >
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col lg="8">
                                                    <Row>

                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <div className={`position-relative drop-down`}>
                                                                    <Label className="form-label f-size-14">Category <span>*</span></Label>
                                                                    <Select
                                                                        options={mainCategory}
                                                                        className={`form-control p-0 ${errors.category?.message ? 'is-invalid' : ''}`}
                                                                        classNamePrefix="custom_select"
                                                                        search={true}
                                                                        value={isCategory}
                                                                        name="category"
                                                                        placeholder={`Select category`}
                                                                        onChange={(value) => {
                                                                            handleCategoryChange(value)
                                                                        }}
                                                                    />
                                                                    {errors.category?.message ? <p className={`error-field`} >{errors.category?.message}</p> : null}
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <div className={`position-relative drop-down`}>
                                                                    <Label className="form-label f-size-14">Subcategory <span>*</span></Label>
                                                                    <Select
                                                                        options={subcategory}
                                                                        className={`form-control p-0 ${errors.subcategory?.message ? 'is-invalid' : ''}`}
                                                                        classNamePrefix="custom_select"
                                                                        search={true}
                                                                        value={isSubcategory}
                                                                        name="subcategory"
                                                                        placeholder={`Select category`}
                                                                        onChange={(value) => {
                                                                            setSubcategory(value)
                                                                            setValue('subcategory', value?._id)
                                                                            clearErrors("subcategory")
                                                                        }}
                                                                    />
                                                                    {errors.subcategory?.message ? <p className={`error-field`} >{errors.subcategory?.message}</p> : null}
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <div className={`position-relative drop-down`}>
                                                                    <Label className="form-label f-size-14">Stock tip <span>*</span></Label>
                                                                    <Select
                                                                        options={StockTip}
                                                                        className={`form-control p-0 ${errors.stockTip?.message ? 'is-invalid' : ''}`}
                                                                        classNamePrefix="custom_select"
                                                                        search={true}
                                                                        value={isStockTip}
                                                                        name="stockTip"
                                                                        placeholder={`Select stock geniustalk `}
                                                                        onChange={(value) => {
                                                                            setStockTip(value)
                                                                            setValue('stockTip', value?.value)
                                                                            clearErrors("stockTip")
                                                                        }}
                                                                    />
                                                                    {errors.stockTip?.message ? <p className={`error-field`} >{errors.stockTip?.message}</p> : null}
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <div className={`position-relative drop-down`}>
                                                                    <Label className="form-label f-size-14">Tips Type <span>*</span></Label>
                                                                    <Select
                                                                        options={tipTypeData}
                                                                        className={`form-control p-0 ${errors.tipType?.message ? 'is-invalid' : ''}`}
                                                                        classNamePrefix="custom_select"
                                                                        search={true}
                                                                        value={isTipType}
                                                                        name="tipType"
                                                                        placeholder={`Select geniustalk type`}
                                                                        onChange={(value) => {
                                                                            setTipType(value)
                                                                            setValue('tipType', value?._id)
                                                                            clearErrors("tipType")
                                                                        }}
                                                                    />
                                                                    {errors.tipType?.message ? <p className={`error-field`} >{errors.tipType?.message}</p> : null}
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col md="6">
                                                            <div className="mb-3">
                                                                <Label className="form-label f-size-14">Start date <span>*</span></Label>
                                                                <div className='d-flex'>
                                                                    <div className='w-100'>
                                                                        <Controller
                                                                            name="startDate"
                                                                            control={control}
                                                                            defaultValue={null} // Initial value
                                                                            render={({ field: { onChange, value } }) => (
                                                                                <DatePicker
                                                                                    minDate={new Date()}
                                                                                    selected={value}
                                                                                    id='startDateInput'
                                                                                    open={focusInput?.startDate}
                                                                                    disabled={isCategory?.callType === "INTRADAY" ? true : false}
                                                                                    onChange={(value) => {
                                                                                        onChange(value);
                                                                                        setStartDateValue(value);
                                                                                        setFocusInput({ startDate: false, endDate: false })
                                                                                    }}
                                                                                    className={errors.startDate?.message ? 'form-control is-invalid' : '' + 'form-control'}
                                                                                // dateFormat="DD,MM,YYYY h:mm aa"
                                                                                />
                                                                            )}
                                                                        />
                                                                    </div>
                                                                    <div id='startDateIcon' className={`calendar-icon ${isCategory?.callType !== "INTRADAY" && "cursor-pointer "}`} >
                                                                        <img id='iconStartDate' src={calendarIcon} alt='calendarIcon' />
                                                                    </div>
                                                                </div>
                                                                {errors.startDate?.message ? <p className={`invalid-feedback error-field ${errors.startDate?.message ? 'd-block' : ''}`} >{errors.startDate?.message}</p> : null}

                                                            </div>
                                                        </Col>

                                                        {
                                                            isCategory?.callType !== "INTRADAY" && startDateValue &&
                                                            < Col md="6">
                                                                <div className="mb-3">
                                                                    <Label className="form-label f-size-14">End date <span>*</span></Label>
                                                                    <div className='d-flex'>
                                                                        <div className='w-100'>
                                                                            <Controller
                                                                                name="endDate"
                                                                                control={control}
                                                                                defaultValue={null} // Initial value
                                                                                render={({ field: { onChange, value } }) => (
                                                                                    <DatePicker
                                                                                        minDate={new Date(startDateValue)}
                                                                                        selected={value}
                                                                                        id='endDateInput'
                                                                                        onChange={(value) => {
                                                                                            let date = new Date(value)
                                                                                            date.setHours(23, 59, 0, 0)
                                                                                            onChange(date)
                                                                                            setFocusInput({ startDate: false, endDate: false })
                                                                                        }}
                                                                                        open={focusInput?.endDate}
                                                                                        className={errors.startDate?.message ? 'form-control is-invalid' : '' + 'form-control'}
                                                                                    // dateFormat="DD,MM,YYYY h:mm aa"
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </div>
                                                                        {/* <div className='calendar-icon cursor-pointer'>
                                                                            <img src={calendarIcon} alt='calendarIcon' />
                                                                        </div> */}
                                                                        <div id='endDateIcon' className={`calendar-icon ${isCategory?.callType !== "INTRADAY" && "cursor-pointer "}`}>
                                                                            <img id='iconEndDate' src={calendarIcon} alt='calendarIcon' />
                                                                        </div>
                                                                    </div>

                                                                    {errors.endDate?.message ? <p className={`invalid-feedback error-field ${errors.endDate?.message ? 'd-block' : ''}`} >{errors.endDate?.message}</p> : null}
                                                                    {/* <input type="date" {...register("endDate")} className={errors.endDate?.message ? 'form-control is-invalid' : '' + 'form-control'} /> */}
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Col>

                                                <Col lg="4">

                                                    <div className="mb-3">
                                                        <Label className="form-label f-size-14">Description <span>*</span></Label>
                                                        <textarea style={{ height: '230px' }} {...register("description")} className={errors.description?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                                        {errors.description?.message ? <p className={`invalid-feedback`} >{errors.description?.message}</p> : null}
                                                    </div>

                                                </Col>

                                            </Row>
                                            <div className="mb-3 pt-3 d-flex btn-group">
                                                <PrimaryButton btnName="Save" showIcon={false} btnType={'submit'} />
                                                <SecountryBtn btnType='button' onClick={() => handleClear()} btnName='Clear' showIcon={false} />

                                            </div>
                                        </CardBody>
                                    </Card>
                                </form>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div >
        </React.Fragment >
    );
};

export default AddTips;