import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Button, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getAllUser } from "../../store/actions";
import NoDataFound from "components/Common/NoDataFound";
import ManageUserCard from "components/Common/Cards/ManageUserCard";
import PrimaryButton from "components/Common/Buttons/PrimaryButton";
import ManageCardSkeleton from "components/Common/Skeleton/ManageCardSkeleton";
import Pagination from "components/Common/pagination/Pagination";
import { dataPerPage } from "constants/dataCount";
import { debounce } from "lodash";

const Users = () => {
  document.title = "Users | FiDeal AdminPanel";

  const dispatch = useDispatch();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { totalCount } = useSelector((state) => state?.GetAllUser?.users);
  const usersList = useSelector((state) => state?.GetAllUser?.users?.user);
  const usersListLoading = useSelector((state) => state?.GetAllUser?.loading);
  const usersReload = useSelector((state) => state?.UserStatus);
  const getUser = () => {
    dispatch(getAllUser({ search: { search: searchValue }, currentPage, dataPerPage }));
  };

  useEffect(() => {
    getUser()
  }, [searchValue, currentPage]);

  // reload active de-active
  useEffect(() => {
    if (usersReload?.user?.status === "SUCCESS") {
      getUser();
    }
  }, [usersReload]);

  const handleSearch = debounce((value) => {
    setSearchValue(value);
    setCurrentPage(1)
  }, 1000)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="title-grp">
            <h1 className="page-title">Users</h1>
            <div className="search-addNew-div">
              <div className="d-flex justify-content-between filter-section">
                <div className="d-flex action-field">
                  <div className="search-box d-inline-block me-2 field">
                    <div className="position-relative">
                      <div className="search-label">
                        <span id="search-bar-0-label" className="sr-only">
                          Search this table
                        </span>
                        <input
                          onChange={(e) => {
                            handleSearch(e.target.value)
                          }}
                          id="search-bar-0"
                          type="text"
                          className="form-control"
                          placeholder={`Search by name / email`}
                        // value={searchValue ? searchValue : ""}
                        />
                      </div>
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </div>
                </div>
                <PrimaryButton
                  btnType="button"
                  btnName="Add New"
                  showIcon={false}
                  onClick={() => {
                    history.push("users/add-new-user");
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            {usersListLoading ? (
              <Row className="user-card-view skeleton">
                {[...Array(12)].map((item, index) => {
                  return <ManageCardSkeleton key={index} />;
                })}
              </Row>
            ) : (
              <>
                {usersList?.length === 0 ? (
                  <NoDataFound />
                ) : (
                  <div className="user-card-view">
                    {usersList?.map((user, key) => {
                      return <ManageUserCard user={user} key={"_user_" + key} type="approved" />;
                    })}
                  </div>
                )}
              </>
            )}

          </div>
          <div>{
            totalCount > dataPerPage &&
            <Pagination
              className="pagination-bar custom-pagination"
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={dataPerPage}
              onPageChange={page => setCurrentPage(page)}
            />
          }
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
