
import { ADD_FAQ, DEL_FAQ, FAQ_STATUS, GET_ALL_FAQ, GET_FAQ_BY_ID } from "./actionTypes";
import { call, put, takeEvery } from "redux-saga/effects"
import {
    getAllFaqSuccess,
    getAllFaqFail,
    getFaqByIdSuccess,
    getFaqIdFail,
    addNewFaqSuccess,
    addNewFaqError,
    faqLoading,
    DeleteFaqSuccess,
    DeleteFaqError,
    changeFaqStatusSuccess,
    changeFaqStatusError,
} from "./actions";
import { addNewFaq, deleteFaq, faqActiveStatus, getAllFaq, getFaqId, updateFaq } from "helpers/backend_helper";
import toast from "react-hot-toast";

//Get All Admnins
function* fetchFaq() {
    try {
        const response = yield call(getAllFaq);
        yield put(getAllFaqSuccess(response))
    } catch (error) {
        yield put(getAllFaqFail(error))
    }
}
export function* FaqSaga() {
    yield takeEvery(GET_ALL_FAQ, fetchFaq)
}

//Get   faq   id
function* faqId({ payload: id }) {
    try {
        const response = yield call(getFaqId, id)
        yield put(getFaqByIdSuccess(response))
    } catch (error) {
        yield put(getFaqIdFail(error))
    }
}
export function* GetFaqIdSaga() {
    yield takeEvery(GET_FAQ_BY_ID, faqId)
}

//Delete faq by id
function* deleteFaqId({ payload: id }) {
    try {
        const response = yield call(deleteFaq, id?.id)
        if (response.status.toLowerCase() === 'error') {
            toast.error(response.message)
        } else {
            yield put(DeleteFaqSuccess(response))
            toast.success(response.message)
        }
    } catch (error) {
        yield put(DeleteFaqError(error))
    }
}
export function* DeleteFaqIdSaga() {
    yield takeEvery(DEL_FAQ, deleteFaqId)
}

//add faq
function* newFaq({ payload: { faq, history, id } }) {
    try {
        yield put(faqLoading(true));

        const apiCall = id ? updateFaq : addNewFaq;
        const response = yield call(apiCall, faq, id);

        yield put(faqLoading(false))
        if (response.status === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(addNewFaqSuccess(response))
            history.push("/faq");
        }
    } catch (error) {
        yield put(faqLoading(false))
        yield put(addNewFaqError(error))
        toast.error(error.response.data.message)
    }
}
export function* AddFaqSaga() {
    yield takeEvery(ADD_FAQ, newFaq)
}

//faq status 

function* faqStatus({ payload: { status, id, history } }) {
    try {
        const response = yield call(faqActiveStatus, status, id);

        if (response.status?.toLowerCase() === "success") {
            toast.success(response.message);
            history.push('/faq');
            yield put(changeFaqStatusSuccess(response));
        } else {
            yield put(changeFaqStatusError(response));
            toast.error(response.message);
        }
    } catch (error) {
        yield put(changeFaqStatusError(error));
        toast.error(error.message);
    }
}

export function* FaqtStatusSaga() {
    yield takeEvery(FAQ_STATUS, faqStatus);
}