import {
    GET_CATEGORY,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    GET_SUB_CATEGORY,
    GET_SUB_CATEGORY_SUCCESS,
    GET_SUB_CATEGORY_FAIL,
    ADD_NEW_CATEGORY,
    ADD_NEW_CATEGORY_SUCCESS,
    ADD_NEW_CATEGORY_FAIL,
    ADD_SUB_CATEGORY,
    ADD_SUB_CATEGORY_SUCCESS,
    ADD_SUB_CATEGORY_FAIL,
    GET_SUBCATEGORY_ID,
    GET_SUBCATEGORY_ID_SUCCESS,
    GET_SUBCATEGORY_ID_FAIL,
    GET_CATEGORY_ID,
    GET_CATEGORY_ID_SUCCESS,
    GET_CATEGORY_ID_FAIL,
    GET_SUBCATEGORY_STATUS,
    GET_SUBCATEGORY_STATUS_SUCCESS,
    GET_SUBCATEGORY_STATUS_FAIL,
    GET_CATEGORY_STATUS,
    GET_CATEGORY_STATUS_SUCCESS,
    GET_CATEGORY_STATUS_FAIL,
    GET_SUBCATEGORY_DEFAULT,
    GET_SUBCATEGORY_DEFAULT_SUCCESS,
    GET_SUBCATEGORY_DEFAULT_FAIL,
    GET_CATEGORY_DEFAULT,
    GET_CATEGORY_DEFAULT_SUCCESS,
    GET_CATEGORY_DEFAULT_FAIL
} from "./actionTypes";

// Get Main Category
export const getMainCategory = () => ({
    type: GET_CATEGORY,
});
export const getMainCategorySuccess = mainCat => ({
    type: GET_CATEGORY_SUCCESS,
    payload: mainCat,
});
export const getMainCategoryFail = error => ({
    type: GET_CATEGORY_FAIL,
    payload: error,
});

//add Category
export const AddNewCategory = (category, id) => {
    return {
        type: ADD_NEW_CATEGORY,
        payload: category, id
    }
}
export const addNewCategorySuccess = category => {
    return {
        type: ADD_NEW_CATEGORY_SUCCESS,
        payload: category,
    }
}
export const addNewCategoryError = error => {
    return {
        type: ADD_NEW_CATEGORY_FAIL,
        payload: error,
    }
}

//Get Category by ID
export const getCategoryId = id => ({
    type: GET_CATEGORY_ID,
    payload: id,
})
export const getCategoryIdSuccess = category => ({
    type: GET_CATEGORY_ID_SUCCESS,
    payload: category,
})
export const getCategoryIdFail = error => ({
    type: GET_CATEGORY_ID_FAIL,
    payload: error,
})
//Get Category status 
export const categoryStatusChange = (status, id) => ({
    type: GET_CATEGORY_STATUS,
    payload: { status, id }
})
export const CategoryStatusSuccess = category => ({
    type: GET_CATEGORY_STATUS_SUCCESS,
    payload: category,
})
export const CategoryStatusError = error => ({
    type: GET_CATEGORY_STATUS_FAIL,
    payload: error
})

// Get SubCategory
export const getSubCategory = (id) => ({
    type: GET_SUB_CATEGORY,
    payload: id
});
export const getSubCategorySuccess = subcategory => ({
    type: GET_SUB_CATEGORY_SUCCESS,
    payload: subcategory,
});
export const getSubCategoryFail = error => ({
    type: GET_SUB_CATEGORY_FAIL,
    payload: error,
});

//Add SubCategory
export const addSubCategory = (subcategory, id, subCategoryId) => {
    return {
        type: ADD_SUB_CATEGORY,
        payload: { subcategory, id, subCategoryId }
    }
}
export const addSubCategorySuccess = subcategory => {
    return {
        type: ADD_SUB_CATEGORY_SUCCESS,
        payload: subcategory,
    }
}
export const addSubCategoryError = error => {
    return {
        type: ADD_SUB_CATEGORY_FAIL,
        payload: error,
    }
}
// Get SubCategory by ID
export const getSubCategoryId = id => ({
    type: GET_SUBCATEGORY_ID,
    payload: id,
})
export const getSubCategoryIdSuccess = subcategory => ({
    type: GET_SUBCATEGORY_ID_SUCCESS,
    payload: subcategory,
})
export const getSubCategoryIdFail = error => ({
    type: GET_SUBCATEGORY_ID_FAIL,
    payload: error,
})
//Get SubCategory status 
export const subcategoryStatusChange = (status, id) => ({
    type: GET_SUBCATEGORY_STATUS,
    payload: { status, id }
})
export const subCategoryStatusSuccess = subcategory => ({
    type: GET_SUBCATEGORY_STATUS_SUCCESS,
    payload: subcategory,
})
export const subCategoryStatusError = error => ({
    type: GET_SUBCATEGORY_STATUS_FAIL,
    payload: error
})

//Get Subcategory Default
export const subcategoryDefaultChange = (isDefault, id,handleReload) => ({
    type: GET_SUBCATEGORY_DEFAULT,
    payload: { isDefault, id, handleReload },
})
export const subCategoryDefaultSuccess = subcategory => ({
    type: GET_SUBCATEGORY_DEFAULT_SUCCESS,
    payload: subcategory,
})
export const subCategoryDefaultError = error => ({
    type: GET_SUBCATEGORY_DEFAULT_FAIL,
    payload: error
})

//category Default
export const CategoryDefaultChange = (isDefault, id, handleReloadCategory) => ({
    type: GET_CATEGORY_DEFAULT,
    payload: { isDefault, id, handleReloadCategory },
})
export const CategoryDefaultSuccess = category => ({
    type: GET_CATEGORY_DEFAULT_SUCCESS,
    payload: category,
})
export const CategoryDefaultError = error => ({
    type: GET_CATEGORY_DEFAULT_FAIL,
    payload: error
})