import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./application_url_helper";

// Login Method
export const applicationLogin = data => post(url.APPLICATION_LOGIN, data);
export const forgetPwd = data => post(url.FORGET_PASSWORD, data);
export const forgetPwdResendCode = data => post(url.FORGET_PASSWORD_RESEND_CODE, data);
export const resetPwd = data => post(url.RESET_PASSWORD_URL, data);

//Admin
export const getAllAdmins = (search, currentPage, dataPerPage) => post(url.GET_ALL_ADMIN + "/" + currentPage + "/" + dataPerPage, search);
export const postNewAdmin = data => post(url.POST_NEW_ADMIN, data);
export const updateAdmin = (data, id) => put(`${url.UPDATE_ADMIN}/${id}`, data);
export const getAdminById = (id) => { return get(`${url.GET_ADMIN_BY_ID}/${id}`, { params: { id }, }); };
export const updateStatus = (data, id) => put(`${url.UPDATE_STATUS}/${id}`, data);

//Users
export const getAllUserList = (search, currentPage, dataPerPage) => post(`${url.GET_ALL_USERS}/${currentPage}/${dataPerPage}`, search);
export const addNewUser = (data) => post(url.ADD_NEW_USER, data);
export const getByUserId = (id) => { return get(`${url.GET_BY_USER_ID}/${id}`, { params: { id } }) };
export const updateUser = (data, id) => put(`${url.UPDATE_USER}/${id}`, data);
export const changeUserStatus = (data, id) => put(`${url.USER_UPDATE_STATUS}/${id}`, data);

//advisor
export const GetAdvisors = (search, currentPage, dataPerPage) => post(url.GET_MANAGE_ADVISORS + "/" + currentPage + "/" + dataPerPage, search);
export const addNewAdvisor = data => post(url.ADD_NEW_ADVISOR, data);
export const updateAdvisor = (data, id) => put(`${url.UPDATE_ADVISOR}/${id}`, data)
export const getAdvisorId = (id) => { return get(`${url.GET_ADVISOR_ID}/${id}`, { params: { id }, }); }
export const changeAdvoicerStatus = (data, id) => put(`${url.ADVAICER_UPDATE_STATUS}/${id}`, data);
export const activeAdvisors = () => get(url.GET_ACTIVE_ADVISORS);
export const submmitedAdvisors = (data) => post(url.SUBMITTED_ADVISORS, data);
export const getsubmmitedAdvisor = (id) => { return get(`${url.GET_SUBMITTED_ADVISOR_ID}/${id}`, { params: { id }, }); }
export const approveOrRejectRequest = (data) => { return put(`${url.APPROVE_OR_REJECT_SIGNUP_REQUEST}`, data); }

//Manage Tips
export const getAllTips = (data, currentPage, dataPerPage) => post(`${url.GET_TIPS}/${currentPage}/${dataPerPage}`, data)
export const addNewTips = (data) => post(url.ADD_NEW_TIPS, data);
export const getByTipsId = (id) => { return get(`${url.GET_BY_TIPS_ID}/${id}`, { params: { id }, }); };
export const updateTips = (data, id) => put(`${url.UPDATE_TIPS}/${id}`, data)
export const tipsStatus = (data, id) => put(`${url.CHANGE_TIP_ACTIVE_STATUS}/${id}`, data);
export const deleteTips = (id) => del(`${url.DELETE_TIPS}/${id}`);
export const getAllTipType = () => get(url.GET_ALL_TIPTYPE);

//Category
export const getAllCategory = () => get(url.GET_MAIN_CATEGORY);
export const getSubCategory = (id) => get(`${url.GET_SUBCATEGORY}/${id}`);
export const addCategory = data => post(url.ADD_NEW_CATEGORY, data);
export const updateCategory = (data, id) => put(`${url.UPDATE_CATEGORY}/${id}`, data)
export const getCategoryId = (id) => { return get(`${url.GET_BY_CATEGORY_ID}/${id}`, { params: { id }, }); };
export const addSubCategory = data => post(url.ADD_SUBCATEGORY, data);
export const getSubCategoryId = (id) => { return get(`${url.GET_BY_SUBCATEGORY_ID}/${id}`, { params: { id }, }); };
export const updateSubCategory = (data, id) => put(`${url.UPDATE_SUBCATEGORY}/${id}`, data)
export const changeSubCategoryStatus = (data, id) => put(`${url.SUBCATEGORY_UPDATE_STATUS}/${id}`, data);
export const changeCategoryStatus = (data, id) => put(`${url.CATEGORY_UPDATE_STATUS}/${id}`, data);
export const updateDefaultCategory = (data, id) => put(`${url.UPDATE_DEFAULT_CATEGORY}/${id}`, data)
export const updateDefaultSubCategory = (data, id) => put(`${url.UPDATE_DEFAULT_SUBCATEGORY}/${id}`, data)

//Faq
export const getAllFaq = () => get(url.GET_ALL_FAQ);
export const addNewFaq = data => post(url.ADD_NEW_FAQ, data);
export const updateFaq = (data, id) => put(`${url.UPDATE_FAQ}/${id}`, data)
export const getFaqId = (id) => { return get(`${url.GET_FAQ_ID}/${id}`, { params: { id }, }); }
export const deleteFaq = (id) => { return del(`${url.DELETE_FAQ}/${id}`) }
export const faqActiveStatus = (data, id) => put(`${url.FAQ_STATUS}/${id}`, data);

//Generate password for user and admin
export const generatePwd = data => post(url.GENERATE_PASSWORD, data);
export const updateProfile = (data, id) => put(`${url.UPDATE_PROFILE}/${id}`, data);
export const changeLoginPassword = data => post(url.CHANGE_PASSWORD, data);

//Update profile image
export const updateProfileImg = (header, data) => post(url.UPDATE_PROFILE_IMG, data, header);
export const getDecodeImage = (data) => post(`${url.GET_DECODE_IMAGE}`, data)

//Settings
export const postSettingsUpdate = (data, id) => put(`${url.SETTINGS_UPDATE}/${id}`, data)
export const postSettingsUpdateToemail = (data, id) => put(`${url.SETTINGS_UPDATE_TOEMAIL}/${id}`, data)
export const getSettings = () => get(url.GET_SETTINGS);
export const getNotificationUrl = () => get(url.GET_NOTIFICATION);
export const postTradingUpdate = (data, id) => put(`${url.TRADINGMETER_STSTUS}/${id}`, data)
export const getNetwork = () => get(url.SOCIAL_LINKS);
export const updateSocialInfo = (data) => put(url.UPDATE_SOCIAL_LINKS, data);

//Roles
export const getAllAdminRoles = () => get(url.GET_ALL_ADMIN_ROLES);
export const adminRoleType = (type) => get(`${url.ADMIN_ROLE_TYPE}/${type}`);

//Reviews 
export const getReviewPendingList = (currentPage, dataPerPage) => post(`${url.GET_PENDING_REVIEWS}/${currentPage}/${dataPerPage}`);
export const getReviewDetails = (data) => get(`${url.GET_REVIEWS_DETAILS}/${data}`);
export const getReviewPublish = (data) => post(`${url.GET_REVIEWS_STATUS}`, data);

//Marketto Mater
export const getMarkettoMeter = () => get(url.GET_MARKETTO_METER);
//Add SuggestionType
export const postSuggestionType=(data)=>post(url.ADD_SUGGESTION_TYPE,data)
//close tip
export const closeTip = (data) => put(url.CLOSE_TIP, data)
export const updateTarget = (data) => put(url.TIP_TARGET_STATUS_CHANGE, data)

//Get Count
export const getDashBoardCount = () => get(url.GET_DASHBOARDTOTAL_COUNT)

