import {
    GET_ALL_ADVISOR,
    GET_ALL_ADVISOR_SUCCESS,
    GET_ALL_ADVISOR_FAIL,
    ADD_ADVISOR,
    ADD_ADVISOR_SUCCESS,
    ADD_ADVISOR_FAIL,
    ADVISOR_LOADING,
    GET_ADVISOR_BY_ID,
    GET_ADVISOR_BY_ID_SUCCESS,
    GET_ADVISOR_BY_ID_FAIL,
    ADVISOR_STATUS,
    ADVISOR_STATUS_SUCCESS,
    ADVISOR_STATUS_FAIL,
    ACTIVE_ADVISROR,
    ACTIVE_ADVISROR_SUCCESS,
    ACTIVE_ADVISROR_FAIL,
    GET_SUBMITTED_ADVISROR,
    GET_SUBMITTED_ADVISROR_SUCCESS,
    GET_SUBMITTED_ADVISROR_FAIL,
    SUBMITTED_ADVISROR_ID,
    SUBMITTED_ADVISROR_ID_SUCCESS,
    SUBMITTED_ADVISROR_ID_FAIL,
    APPROVE_OR_REJECT_SIGNUP_REQUEST,
    APPROVE_OR_REJECT_SIGNUP_REQUEST_SUCCESS,
    APPROVE_OR_REJECT_SIGNUP_REQUEST_FAIL,
} from "./actionTypes"

//Get All Advisors
const initialState = {
    advisors: [],
    error: {},
    loading: false
}
export const GetManageAdvisors = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_ADVISOR:
            return {
                ...state,
                loading: true,
            }

        case GET_ALL_ADVISOR_SUCCESS:
            return {
                ...state,
                advisors: action.payload,
                loading: false
            }

        case GET_ALL_ADVISOR_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}
//add advisor 
const addAdvisorInitialState = {
    error: "",
    success: "",
    loading: false
}
export const AddAdvisor = (state = addAdvisorInitialState, action) => {
    switch (action.type) {
        case ADD_ADVISOR:
            state = {
                ...state,
            }
            break
        case ADD_ADVISOR_SUCCESS:
            state = { ...state, success: action.payload, loading: false }
            break
        case ADD_ADVISOR_FAIL:
            state = { ...state, error: action.payload, loading: false }
            break
        case ADVISOR_LOADING:
            state = {
                loading: action.payload
            }
    }
    return state
}

// Get Advisor Id
const getAdvisorIdInitialState = {
    error: "",
    success: "",
    loading: false
}
export const GetAdvisorById = (state = getAdvisorIdInitialState, action) => {
    switch (action.type) {
        case GET_ADVISOR_BY_ID:
            return {
                ...state,
                loading: true,
            }
        case GET_ADVISOR_BY_ID_SUCCESS:
            return {
                ...state,
                advisor: action.payload,
                loading: false
            }

        case GET_ADVISOR_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
    }
    return state
}

//Change Advisor Status
const statusInitialState = {
    error: "",
    success: "",
    loader: false,
}
export const AdvisorStatus = (state = statusInitialState, action) => {
    switch (action.type) {
        case ADVISOR_STATUS:
            state = {
                ...state,
                success: "",
                loader: true,
            }
            break
        case ADVISOR_STATUS_SUCCESS:
            state = { ...state, advisor: action.payload, loader: false, }
            break
        case ADVISOR_STATUS_FAIL:
            state = { ...state, error: action.payload, loader: false, }
            break
    }
    return state
}

//Get Active Advisors
const activeAnitialState = {
    advisorList: [],
    error: {},
    loading: false
}
export const GetActiveAdvisors = (state = activeAnitialState, action) => {
    switch (action.type) {
        case ACTIVE_ADVISROR:
            return {
                ...state,
                loading: true,
            }

        case ACTIVE_ADVISROR_SUCCESS:
            return {
                ...state,
                advisorList: action.payload,
                loading: false
            }

        case ACTIVE_ADVISROR_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//Get Submitted Advisors
const submittedinitialState = {
    submittedList: [],
    error: {},
    loading: false
}
export const GetSubmittedAdvisors = (state = submittedinitialState, action) => {
    switch (action.type) {
        case GET_SUBMITTED_ADVISROR:
            return {
                ...state,
                loading: true,
            }

        case GET_SUBMITTED_ADVISROR_SUCCESS:
            return {
                ...state,
                submittedList: action.payload,
                loading: false
            }

        case GET_SUBMITTED_ADVISROR_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

// Get Submitted Advisor Id
const getSubmmitedIdInitialState = {
    error: "",
    submittedList: "",
    loading: false
}
export const GetSubmittedById = (state = getSubmmitedIdInitialState, action) => {
    switch (action.type) {
        case SUBMITTED_ADVISROR_ID:
            return {
                error: "",
                submittedList: "",
                loading: true,
            }
        case SUBMITTED_ADVISROR_ID_SUCCESS:
            return {
                error: '',
                submittedList: action.payload,
                loading: false
            }

        case SUBMITTED_ADVISROR_ID_FAIL:
            return {
                submittedList: "",
                error: action.payload,
                loading: false
            }
    }
    return state
}

// approved or reject signup request
const initialState_signUpRequest = {
    error: "",
    data: "",
    loading: false
}

export const ApproveOrRejectSignupRequest = (state = initialState_signUpRequest, action) => {
    switch (action.type) {
        case APPROVE_OR_REJECT_SIGNUP_REQUEST:
            return {
                error: "",
                data: "",
                loading: true,
            }
        case APPROVE_OR_REJECT_SIGNUP_REQUEST_SUCCESS:
            return {
                error: "",
                data: action.payload,
                loading: false
            }

        case APPROVE_OR_REJECT_SIGNUP_REQUEST_FAIL:
            return {
                data: "",
                error: action.payload,
                loading: false
            }
    }
    return state
}