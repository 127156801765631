import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, CardBody, Card, Container, Label } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";

import AppLogo from '../../assets/images/logo.png'
//redux
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../store/actions";

// Components
import AuthLoader from "components/Common/AuthLoader";
import { withTranslation } from "react-i18next";

const Login = (props) => {
  //meta title
  document.title = "Login | FiDeal AdminPanel";
  const dispatch = useDispatch();
  const redirectUrl = (sessionStorage.getItem("redirectUrl"));

  const loginForm = {
    email: "",
    password: ""
  }
  const loginSchema = Yup.object().shape({
    email: Yup.string().required("Enter email address").test('email', "Enter valid email address", val => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) return false
      else return true
    }).trim(),
    password: Yup.string().required("Enter password").trim(),
  })

  const { register, handleSubmit, formState: { errors, }, } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: loginForm
  });

  const onSubmit = (values) => {
    dispatch(loginUser(values, props.history, redirectUrl));
  }

  const [passwordShow, setPasswordShow] = useState(false);
  const { error, loader } = useSelector(state => ({
    error: state.Login.error,
    loader: state.Login.loading,
  }));

  return (
    <React.Fragment>
      <div className="account-pages">
        <Row className="h-100 w-100">
          <Col lg={8} className="login-bg">
            <div className="logo">
              <img src={AppLogo} alt="AppLogoWhite" />
            </div>
            <div className="welcome-content">
              <h1>Welcome to <br />FiDeal AdminPanel</h1>
            </div>
          </Col>
          <Col lg={4}>
            <div className="welcome-form">
              <div className="text-center auth-logo">
                <img src={AppLogo} alt="Logo" />
              </div>
              <div className="text-center auth-content">
                <h4>Login</h4>
                <p>Enter your login credential to access your account</p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                className="form-horizontal" >
                <div className="mb-3">
                  <Label className="form-label f-size-14">Email Address<span>*</span></Label>
                  <input type="text" {...register("email")} placeholder={'email@address.com'} className={errors.email?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                  {errors.email?.message ? <p className={`invalid-feedback`} >{errors.email?.message}</p> : null}
                </div>
                <div className="mb-3">
                  <div className="">
                    <Label className="form-label f-size-14">Password<span>*</span></Label>

                  </div>
                  <div className="input-group auth-pass-inputgroup">
                    <input type={passwordShow ? "text" : "password"} {...register("password")} placeholder="*******" className={errors.password?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                    {errors.password?.message ? <p className={`invalid-feedback`} >{errors.password?.message}</p> : null}
                    <div className="show-password">  <button onClick={() => setPasswordShow(prevState => !prevState)} type="button" id="password-addon">
                      {passwordShow ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                    </button></div>
                  </div>
                </div>
                <div className="forgot-password d-flex justify-content-end">
                  <Link to="/forgot-password" className="f-size-14">
                    Forgot Password?
                  </Link>
                </div>
                {loader === true ? <div className="d-grid"><AuthLoader /></div> :
                  <div className="d-grid">
                    <button
                      className="btn btn-primary btn-block primary-button"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                }

              </form>

            </div>
          </Col>

        </Row>
      </div>
    </React.Fragment>
  );
};


export default withRouter(withTranslation()(Login));
Login.propTypes = {
  history: PropTypes.object,
};
