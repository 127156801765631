import React from 'react';
import { Card, CardBody, } from 'reactstrap';
import Skeleton from "react-loading-skeleton";

const CategoryDetailsSkeleton = () => {
    return (
        <div className='manage-card'>
            <Card className='p-0'>
                <CardBody className='p-0'>
                    <div className='pt-3'>
                        <div className='advisor-body'>

                            <div className='d-flex justify-content-between align-items-center p-3 mb-4'>
                                <div className='d-flex p-1'>
                                    {/* <div className='profile-image'>
                                        <Skeleton height={"55px"} width={'55px'} borderRadius={'50px'} />
                                    </div> */}
                                    <div className='ps-3 mt-3'>
                                        <Skeleton height={"16px"} width={'120px'} />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='mt-3 ps-1 '>
                                        <Skeleton height={"18px"} width={'18px'} />
                                    </div>
                                    <div className='d-flex p-1'>
                                        <Skeleton height={"35px"} width={'200px'} borderRadius={'50px'} />
                                    </div>
                                    <div className='d-flex p-1'>
                                        <Skeleton height={"35px"} width={'110px'} borderRadius={'50px'} />
                                    </div>
                                </div>
                            </div>
                            <hr></hr>

                            <div className='card-details'>
                                {/* Subcategory card part  */}
                                {[...Array(12)].map((_, index) => (
                                    <div key={index} className='d-flex justify-content-between align-items-center p-3 mt-4'>
                                        <div className='d-flex p-1'>
                                            {/* <div className='profile-image'>
                                                <Skeleton height={"55px"} width={'55px'} borderRadius={'50px'} />
                                            </div> */}
                                            <div className='ps-0'>
                                                <Skeleton height={"16px"} width={'120px'} />
                                                <Skeleton height={"15px"} width={'90px'} />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='mt-3 ps-1 '>
                                                <Skeleton height={"18px"} width={'18px'} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default CategoryDetailsSkeleton;
