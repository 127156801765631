
import {
    GET_ALL_FAQ,
    GET_ALL_FAQ_SUCCESS,
    GET_ALL_FAQ_FAIL,
    GET_FAQ_BY_ID,
    GET_FAQ_BY_ID_SUCCESS,
    GET_FAQ_BY_ID_FAIL,
    ADD_FAQ,
    ADD_FAQ_SUCCESS,
    ADD_FAQ_FAIL,
    FAQ_LOADING,
    DEL_FAQ,
    DEL_FAQ_SUCCESS,
    DEL_FAQ__FAIL,
    FAQ_STATUS,
    FAQ_STATUS_SUCCESS,
    FAQ_STATUS_FAIL,
} from "./actionTypes";

//Get Faq
export const getAllFaq = () => ({
    type: GET_ALL_FAQ,
});
export const getAllFaqSuccess = faq => ({
    type: GET_ALL_FAQ_SUCCESS,
    payload: faq,
});
export const getAllFaqFail = error => ({
    type: GET_ALL_FAQ_FAIL,
    payload: error,
});

//get faq by ID
export const getFaqById = id => ({
    type: GET_FAQ_BY_ID,
    payload: id,
})
export const getFaqByIdSuccess = faq => ({
    type: GET_FAQ_BY_ID_SUCCESS,
    payload: faq,
})
export const getFaqIdFail = error => ({
    type: GET_FAQ_BY_ID_FAIL,
    payload: error,
})

//Add Faq
export const AddNewFaq = (faq, history, id) => {
    return {
        type: ADD_FAQ,
        payload: { faq, history, id },
    }
}
export const addNewFaqSuccess = faq => {
    return {
        type: ADD_FAQ_SUCCESS,
        payload: faq,
    }
}
export const addNewFaqError = error => {
    return {
        type: ADD_FAQ_FAIL,
        payload: error,
    }
}
export const faqLoading = (loading) => {
    return {
        type: FAQ_LOADING,
        payload: loading,
    }
}

//Delete Faq
export const DeleteFaq = (id, history) => {
    return {
        type: DEL_FAQ,
        payload: { id, history },
    }
}
export const DeleteFaqSuccess = faq => {
    return {
        type: DEL_FAQ_SUCCESS,
        payload: faq,
    }
}
export const DeleteFaqError = error => {
    return {
        type: DEL_FAQ__FAIL,
        payload: error,
    }
}

//Faq Status

export const changeFaqStatus = (status, id, history) => {
    return {
        type: FAQ_STATUS,
        payload: { status, id, history },
    }
}
export const changeFaqStatusSuccess = faq => {
    return {
        type: FAQ_STATUS_SUCCESS,
        payload: faq,
    }
}
export const changeFaqStatusError = error => {
    return {
        type: FAQ_STATUS_FAIL,
        payload: error,
    }
}