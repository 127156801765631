import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import NoDataFound from 'components/Common/NoDataFound';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';

import CategoryCardSkeleton from 'components/Common/Skeleton/CategoryCardSkeleton';
import CategoryCard from 'components/Common/Cards/CategoryCard';
import CategoryModal from 'components/Common/Modals/CategoryModal';
import { getMainCategory } from 'store/actions';

const Category = () => {

    const dispatch = useDispatch();

    document.title = "Manage Categories | FiDeal AdminPanel";

    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
    const [catagory, setCategory] = useState({});
    const CategoryList = useSelector(state => (state?.GetMainCategory?.category?.category));
    const Categoryloading = useSelector(state => (state?.GetMainCategory?.loading))

    const statusReload = useSelector(state => (state?.ChangeCategoryStatus));
    const defaultReload = useSelector(state => (state?.GetCategoryDefaultById));


    useEffect(() => {
        dispatch(getMainCategory());
    }, []);

    const openModal = () => {
        setCategory({})
        setShowAddCategoryModal(true);
    };
    const closeModal = () => {
        setShowAddCategoryModal(false);
    };

    //status reload
    useEffect(() => {
        if (statusReload?.category?.status === "SUCCESS") {
            dispatch(getMainCategory());
        }
    }, [statusReload])

    //handle popup
    const handlePopup = (data) => {
        setCategory(data)
        setShowAddCategoryModal(true)
    }

    return (
        <React.Fragment>
            <div className="page-content  category-screen ">
                <Container fluid>
                    <div className='title-grp'>
                        <h1 className='page-title'>Categories</h1>
                        <div>
                            <PrimaryButton btnName="Add New" showIcon={false} btnType={'button'} onClick={openModal} />
                        </div>
                    </div>
                    <div className='mt-5'>
                        {Categoryloading ? <Row className="user-card-view skeleton">
                            {[...Array(12)].map((item, index) => {
                                return <CategoryCardSkeleton key={index} />;
                            })}
                        </Row> : <>
                            {CategoryList?.length === 0 ? <NoDataFound /> : <div className=" user-card-view">
                                {CategoryList?.map((category, key) => {
                                    return (<CategoryCard pop={handlePopup} category={category} key={"_user_" + key} type="approved" />);
                                })}</div>}
                        </>}
                    </div>
                </Container>
            </div>
            <CategoryModal
                showModal={showAddCategoryModal}
                toggle={closeModal}
                modalTitle={catagory?._id ? "Edit Category" : "Add Category"}
                primaryBtn="Save"
                secountBtn="Close"
                categoryData={catagory}
                onClosed={() => reset()}
            />
        </React.Fragment>
    );
};

export default Category;
