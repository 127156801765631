import React, { useState } from 'react';
import { Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useHistory } from "react-router-dom"
import { changeAdvisorStatus, userStatusChange } from 'store/actions';
import { useDispatch } from 'react-redux';
import Confirmation from '../Modals/Confirmation';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import StarRatings from 'react-star-ratings';

const ManageCard = ({ user, type }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isadvisorId, setadvisorId] = useState();
    const [changeStatusModal, setChangeStatusModal] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    console.log(user, "user");
    const viewAdvisiorPath = (id) => {
        history.push(`/view-marketgenius/${id}`);
    }
    function activeToggleModal() {
        setChangeStatusModal(!changeStatusModal);
    }
    const updateStatus = () => {
        let status
        if (user?.status === "INACTIVE") {
            status = "ACTIVE"
        } else {
            status = "INACTIVE"
        }
        dispatch(changeAdvisorStatus(status, isadvisorId));
        setChangeStatusModal(false);
    }


    const handleEditClick = (id) => {
        history.push(`/marketgenius/update-marketgenius/${id}`);
    }

    const handleViewSubmitted = (id) => {
        history.push(`/marketgenius/view-submitted/${id}`);
    }

    return (
        <>
            <div className='manage-card'>
                <Card className='p-0 m-0'>
                    <CardBody className='p-0'>
                        <div className='d-flex justify-content-between align-items-center p-3'>
                            <div className='d-flex p-1'>
                                <div className={`profile ${user?.status?.toLowerCase()}`}>
                                    {type !== "submitted" && <div className={`active-status`}></div>}
                                    {user?.profilePath ? <span className='profile-img'><img src={user?.profilePath} alt="profile-path" /> </span> :
                                        <span>{user?.firstName?.charAt(0)?.toUpperCase()}</span>}
                                </div>
                                <div className='ps-3'>
                                    <h5 className='m-0 text-capitalize'>{user?.firstName}</h5>
                                    <span>{user?.email}</span>
                                </div>
                            </div>
                            <div className=''>
                                {type === "submitted" ? (
                                    <div className='more-option cursor-pointer' onClick={() => { handleViewSubmitted(user?._id) }}>
                                        <i className='dripicons-arrow-thin-right pt-2'></i>
                                    </div>
                                ) : (
                                    <div className="d-flex">
                                        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'top'} end={true}>
                                            <DropdownToggle caret className='more-option'>
                                                <i className='bx bx-dots-vertical-rounded'></i>
                                            </DropdownToggle>
                                            <DropdownMenu >
                                                <DropdownItem onClick={() => { viewAdvisiorPath(user?._id) }}>View</DropdownItem>
                                                <DropdownItem onClick={() => { handleEditClick(user?._id) }}>Edit</DropdownItem>
                                                <DropdownItem disabled={user?.status === "ACTIVE" ? true : false}>
                                                    <div onClick={() => {
                                                        activeToggleModal();
                                                        setadvisorId(user?._id)
                                                    }}>Activate</div>
                                                </DropdownItem>
                                                <DropdownItem disabled={user?.status === "INACTIVE" ? true : false}>
                                                    <div onClick={() => {
                                                        activeToggleModal();
                                                        setadvisorId(user?._id)
                                                    }}>Suspend</div>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                )}
                            </div>
                        </div>
                        <hr />
                        <div className='crd-details p-3'>
                            {user?.sebiRegisterd === "YES" ? (

                                <div>
                                    <span>SEBI</span>
                                    <p>{user?.sebiNumber}</p>
                                </div>
                            ) : (
                                <div>
                                    <span>Type</span>
                                    <p>Market experence</p>
                                </div>

                            )}

                            <div className='self-end'>
                                {type === "submitted" ? (
                                    <>
                                        <span>Request Status</span>
                                        <div>
                                            <p className='m-0 text-capitalize'>{user?.requestStatus?.toLowerCase()}</p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span>Avg rating</span>
                                        <div>
                                            <StarRatings
                                                rating={Number(user?.overallRating)}
                                                starEmptyColor="#d2d2d2"
                                                starRatedColor="#ffd701"
                                                // changeRating={2}
                                                numberOfStars={5}
                                                name='rating'
                                                starDimension="23px"
                                                starSpacing="0"

                                            />
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Confirmation
                    showModal={changeStatusModal}
                    toggle={activeToggleModal}
                    primaryAction={updateStatus}
                    modalTitle={`Confirmation!`}
                    primaryBtn={`${user?.status === "INACTIVE" ? 'Activate' : 'Suspend'}`}
                    secountBtn="Cancel"
                    description={`Are you sure, do you want to  ${user?.status === "ACTIVE" ? 'suspend' : 'activate'} this marketgenius "${user?.firstName} ${user?.lastName}"?`}
                />

            </div>
        </>
    );
};

export default ManageCard;