//Get  Marketto Meter
export const GET_MARKETTO = "GET_MARKETTO"
export const GET_MARKETTO__SUCCESS = "GET_MARKETTO_SUCCESS"
export const GET_MARKETTO_FAIL = "GET_MARKETTO_FAIL"
//Add  SuggestionType
export const ADD_SUGGESTION_TYPE = "ADD_SUGGESTION_TYPE"
export const ADD_SUGGESTION_TYPE_SUCCESS = "ADD_SUGGESTION_TYPE_SUCCESS "
export const ADD_SUGGESTION_TYPE_FAIL = "ADD_SUGGESTION_TYPE_FAIL"
//DashboardCount
export const GET_DASHBOARD_COUNT = "GET_DASHBOARD_COUNT"
export const GET_DASHBOARD_COUNT_SUCCESS = "GET_DASHBOARD_COUNT_SUCCESS "
export const GET_DASHBOARD_COUNT_FAIL = "GET_DASHBOARD_COUNT_FAIL"