// Get All Advisors
export const GET_ALL_ADVISOR = "GET_ALL_ADVISOR"
export const GET_ALL_ADVISOR_SUCCESS = "GET_ALL_ADVISOR_SUCCESS"
export const GET_ALL_ADVISOR_FAIL = "GET_ALL_ADVISOR_FAIL"

/* Get Advisors By ID */
export const GET_ADVISOR_BY_ID = "GET_ADVISOR_BY_ID"
export const GET_ADVISOR_BY_ID_SUCCESS = "GET_ADVISOR_BY_ID_SUCCESS"
export const GET_ADVISOR_BY_ID_FAIL = "GET_ADVISOR_BY_ID_FAIL"

//Change Advisors Status
export const ADVISOR_STATUS = "ADVISOR_STATUS"
export const ADVISOR_STATUS_SUCCESS = "ADVISOR_STATUS_SUCCESS"
export const ADVISOR_STATUS_FAIL = "ADVISOR_STATUS_FAIL"

//Add Advisor
export const ADD_ADVISOR = "ADD_ADVISOR"
export const ADD_ADVISOR_SUCCESS = "ADD_ADVISOR_SUCCESS"
export const ADD_ADVISOR_FAIL = "ADD_ADVISOR_FAIL"
export const ADVISOR_LOADING = "ADVISOR_LOADING"

//Get Active Advisor
export const ACTIVE_ADVISROR = "ACTIVE_ADVISROR"
export const ACTIVE_ADVISROR_SUCCESS = "ACTIVE_ADVISROR_SUCCESS"
export const ACTIVE_ADVISROR_FAIL = "ACTIVE_ADVISROR_FAIL"

//Submitted Advisors
export const GET_SUBMITTED_ADVISROR = "GET_SUBMITTED_ADVISROR"
export const GET_SUBMITTED_ADVISROR_SUCCESS = "GET_SUBMITTED_ADVISROR_SUCCESS"
export const GET_SUBMITTED_ADVISROR_FAIL = "GET_SUBMITTED_ADVISROR_FAIL"

//Submitted Advisors Id
export const SUBMITTED_ADVISROR_ID = "GET_SUBMITTED_ADVISROR_ID"
export const SUBMITTED_ADVISROR_ID_SUCCESS = "GET_SUBMITTED_ADVISROR_ID_SUCCESS"
export const SUBMITTED_ADVISROR_ID_FAIL = "GET_SUBMITTED_ADVISROR_ID_FAIL"

//approve or reject signup request
export const APPROVE_OR_REJECT_SIGNUP_REQUEST = "GET_APPROVE_OR_REJECT_SIGNUP_REQUEST"
export const APPROVE_OR_REJECT_SIGNUP_REQUEST_SUCCESS = "GET_APPROVE_OR_REJECT_SIGNUP_REQUEST_SUCCESS"
export const APPROVE_OR_REJECT_SIGNUP_REQUEST_FAIL = "GET_APPROVE_OR_REJECT_SIGNUP_REQUEST_FAIL"
