import React, { useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAdvisor } from 'store/actions';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import ManageCard from 'components/Common/Cards/ManageCard';
import ManageCardSkeleton from 'components/Common/Skeleton/ManageCardSkeleton';
import { useHistory } from "react-router-dom";
import NoDataFound from 'components/Common/NoDataFound';
import { dataPerPage } from 'constants/dataCount';
import Pagination from 'components/Common/pagination/Pagination';
import { debounce } from 'lodash';

const Advisors = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    document.title = "Manage MarketGenius | FiDeal AdminPanel"

    const manageAdvisors = useSelector(state => (state?.GetManageAdvisors?.advisors?.advisors));
    const { totalCount } = useSelector(state => (state?.GetManageAdvisors?.advisors));
    const reloadAdvisors = useSelector(state => (state?.AdvisorStatus));
    const advisorsLoading = useSelector(state => (state?.GetManageAdvisors?.loading));

    const [searchValue, setSearchValue] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const getAdvisor = () => {
        dispatch(getAllAdvisor({ search: { search: searchValue }, currentPage, dataPerPage }))
    }
    useEffect(() => {
        getAdvisor();
    }, [searchValue, currentPage]);

    useEffect(() => {
        if (reloadAdvisors?.advisor?.status === "SUCCESS") {
            getAdvisor();
        }
    }, [reloadAdvisors])

    const handleSearch = debounce((value) => {
        setSearchValue(value);
        setCurrentPage(1)
    }, 1000)

    return (
        <React.Fragment>
            <div className="advisors-screen">
                <Container fluid>

                    <div className="title-grp">
                        <h1 className='page-title'></h1>
                        <div className="search-addNew-div">
                            <div className='d-flex justify-content-between filter-section'>
                                <div className="action-field">
                                    <div className="search-box d-inline-block me-2 field">
                                        <div className="position-relative">
                                            <div className="search-label m-0">
                                                <input
                                                    onChange={e => {
                                                        handleSearch(e.target.value)
                                                    }}
                                                    id="search-bar-0"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={`Search by name / email`}
                                                />
                                            </div>
                                            <i className="bx bx-search-alt search-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {!manageAdvisors ? <Row className="user-card-view skeleton px-2">
                            {[...Array(12)].map((item, index) => {
                                return <ManageCardSkeleton key={index} />;
                            })}
                        </Row> : <>{manageAdvisors?.length === 0 ? <NoDataFound /> : <div className="user-card-view"> {manageAdvisors?.map((user, key) => {
                            return (<ManageCard user={user} key={"_user_" + key} type="approved" />);
                        })}</div>}</>}
                    </div>
                    <div>{
                        totalCount > dataPerPage &&
                        <Pagination
                            className="pagination-bar custom-pagination"
                            currentPage={currentPage}
                            totalCount={totalCount}
                            pageSize={dataPerPage}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    }
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Advisors;