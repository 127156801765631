import { ADD_SUGGESTION_TYPE, ADD_SUGGESTION_TYPE_FAIL, ADD_SUGGESTION_TYPE_SUCCESS, GET_DASHBOARD_COUNT, GET_DASHBOARD_COUNT_FAIL, GET_DASHBOARD_COUNT_SUCCESS, GET_MARKETTO, GET_MARKETTO_FAIL, GET_MARKETTO__SUCCESS } from "./actionTypes"

const initialState = {
    tradingMeter: [],
    error: null,
    loading: false
}
export const GetMarketto = (state = initialState, action) => {
    switch (action.type) {
        case GET_MARKETTO:
            return {
                ...state,
                loading: true,
            }

        case GET_MARKETTO__SUCCESS:
            return {
                ...state,
                tradingMeter: action.payload,
                loading: false
            }

        case GET_MARKETTO_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//add suggestionType

const suggestionTypeInitialState = {
    tradingMeter: [],
    error: null,
    loading: false
}
export const SuggestionType = (state = suggestionTypeInitialState, action) => {
    switch (action.type) {
        case ADD_SUGGESTION_TYPE:
            return {
                ...state,
                loading: true,
            }

        case ADD_SUGGESTION_TYPE_SUCCESS:
            return {
                ...state,
                tradingMeter: action.payload,
                loading: false
            }

        case ADD_SUGGESTION_TYPE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//get dashboard count 

const dashboardcountinitialState = {
    count: [],
    error: null,
    loading: false
}
export const GetCount = (state = dashboardcountinitialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_COUNT:
            return {
                ...state,
                loading: true,
            }

        case GET_DASHBOARD_COUNT_SUCCESS:
            return {
                ...state,
                count: action.payload,
                loading: false
            }

        case GET_DASHBOARD_COUNT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}