import React, { memo } from 'react';

const PrimaryButton = ({btnType, btnName, btnIcon, showIcon, onClick, disabled,customClass }) => {
    return (
        <>
            <button className={`btn-common btn-primary btn-block primary-button ${customClass}`} type={btnType} onClick={onClick} disabled={disabled}>
                {showIcon ? <img src={btnIcon} alt='btnicon' /> : ''}
                {btnName}
            </button>
        </>
    );
};

export default memo(PrimaryButton);