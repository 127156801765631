//Get User List
export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL"
// export const CLEAR_USERS= "CLEAR_USERS"

//Add User
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_NEW_USER_SUCCESS = "ADD_NEW_USER_SUCCESS"
export const ADD_NEW_USER_FAIL = "ADD_NEW_USER_FAIL"
export const USER_LOADING = "USER_LOADING"
//Get By User Id
export const GET_USER_ID = "GET_USER_ID"
export const GET_USER_ID_SUCCESS = "GET_USER_ID_SUCCESS"
export const GET_USER_ID_FAIL = "GET_USER_ID_FAIL"

//Update the status
export const USER_STATUS = "USER_STATUS"
export const USER_STATUS_SUCCESS = "USER_STATUS_SUCCESS"
export const USER_STATUS_FAIL = "USER_STATUS_FAIL"