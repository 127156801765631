import { addNewTips, deleteTips, getAllTipType, getAllTips, getByTipsId, tipsStatus, updateTips, closeTip, updateTarget } from "helpers/backend_helper";
import { call, takeEvery, put } from "redux-saga/effects";
import { ADD_TIPS, CLOSE_TIP, DELETE_TIPS, GET_ALL_TIPS, GET_TIPS_ID, GET_TIPTYPE, TIPS_STATUS, TIP_TARGET_STATUS_CHANGE } from "./actionTypes";
import { addTipsError, addTipsSuccess, changeTipsStatusError, changeTipsStatusSuccess, closeTipFail, closeTipSuccess, deleteTipsError, deleteTipsSuccess, getAllTipsFail, getAllTipsSuccess, getTipTypeFail, getTipTypeSuccess, getTipsByIdSuccess, getTipsyIdFail, tipTargetUpdateFail, tipTargetUpdateSuccess } from "./actions";
import toast from "react-hot-toast";

//Get Tips
function* fetchAllTips({ payload: { filterPayload, currentPage, dataPerPage } }) {
    try {
        const response = yield call(getAllTips, filterPayload, currentPage, dataPerPage);
        yield put(getAllTipsSuccess(response))
        if (response?.status?.toLowerCase() === "success") {
            setOpenFilter(false)
        } else {
            toast.error(response?.message)
        }
    } catch (error) {
        yield put(getAllTipsFail(error))
    }
}
export function* TipsSaga() {
    yield takeEvery(GET_ALL_TIPS, fetchAllTips)
}

//Add Tips
function* newTipsData({ payload: { tipData, history, id, handleUpdate } }) {
    try {

        let response
        if (id) {
            response = yield call(updateTips, tipData, id)
        } else {
            response = yield call(addNewTips, tipData)
        }
        if (response.status === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(addTipsSuccess(response))
            history.push("/geniustalk");
            if (handleUpdate) {
                handleUpdate();
            }
        }
    } catch (error) {
        yield put(addTipsError(error))
        toast.error(error.response.data.message)
    }
}
export function* AddTipsSaga() {
    yield takeEvery(ADD_TIPS, newTipsData)
}

//Get Tips ID
function* fetchTipsId({ payload: id }) {
    try {
        const response = yield call(getByTipsId, id)
        yield put(getTipsByIdSuccess(response))
    } catch (error) {
        yield put(getTipsyIdFail(error))
    }
}
export function* GetTipsIdSaga() {
    yield takeEvery(GET_TIPS_ID, fetchTipsId)
}

// Change Tips Status
function* tipsUpdate({ payload: { status, id, onUpdate } }) {
    try {
        const data = {}
        data["status"] = status
        const response = yield call(tipsStatus, data, id)
        if (response.status?.toLowerCase() === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(changeTipsStatusSuccess(response))
            onUpdate();
            // history.push(`/${url}`);
        }
    } catch (error) {
        yield put(changeTipsStatusError(error))
        toast.error(error.response.data.message)
    }
}
export function* TipsStatusSaga() {
    yield takeEvery(TIPS_STATUS, tipsUpdate)
}

//Delete Tips
function* deleteTipsRecord({ payload: { id, history } }) {
    try {
        const response = yield call(deleteTips, id)
        if (response.status === 'error') {
            toast.error(response.message)
        } else {
            yield put(deleteTipsSuccess(response))
            toast.success(response.message)
            history.push("/geniustalk");
        }
    } catch (error) {
        yield put(deleteTipsError(error))
        toast.error(error.response.data.message)
    }
}

export function* DeleteTipsSaga() {
    yield takeEvery(DELETE_TIPS, deleteTipsRecord)
}

//Get TipType
function* fetchAllTipType() {
    try {
        const response = yield call(getAllTipType);
        yield put(getTipTypeSuccess(response));
    } catch (error) {
        yield put(getTipTypeFail(error))
    }
}
export function* TipTypeSaga() {
    yield takeEvery(GET_TIPTYPE, fetchAllTipType)
}

//Close tip
function* closeTips({ payload: { data, afterSuccess } }) {
    try {
        const response = yield call(closeTip, data);
        if (response?.status?.toLowerCase() === "success") {
            yield put(closeTipSuccess(response));
            afterSuccess();
        } else {
            toast.error(response?.message);
        }
    } catch (error) {
        yield put(closeTipFail(error))
    }
}
export function* CloseTipSaga() {
    yield takeEvery(CLOSE_TIP, closeTips)
}

//tip target status change
function* updateTipTarget({ payload: { data, afterSuccess } }) {
    try {
        const response = yield call(updateTarget, data);
        if (response?.status?.toLowerCase() === "success") {
            yield put(tipTargetUpdateSuccess(response));
            afterSuccess();
        } else {
            toast.error(response?.message);
        }
    } catch (error) {
        yield put(tipTargetUpdateFail(error))
    }
}
export function* SetTipTargetSaga() {
    yield takeEvery(TIP_TARGET_STATUS_CHANGE, updateTipTarget)
}