import {
    GET_ALL_TIPS,
    GET_ALL_TIPS_FAIL,
    GET_ALL_TIPS_SUCCESS,
    ADD_TIPS,
    ADD_TIPS_SUCCESS,
    ADD_TIPS_FAIL,
    GET_TIPS_ID,
    GET_TIPS_ID_SUCCESS,
    GET_TIPS_ID_FAIL,
    TIPS_STATUS,
    TIPS_STATUS_SUCCESS,
    TIPS_STATUS_FAIL,
    DELETE_TIPS,
    DELETE_TIPS_SUCCESS,
    DELETE_TIPS_FAIL,
    GET_TIPTYPE,
    GET_TIPTYPE_FAIL,
    GET_TIPTYPE_SUCCESS,
    CLOSE_TIP,
    CLOSE_TIP_SUCCESS,
    CLOSE_TIP_FAIL,
    TIP_TARGET_STATUS_CHANGE,
    TIP_TARGET_STATUS_CHANGE_SUCCESS,
    TIP_TARGET_STATUS_CHANGE_FAIL,
} from "./actionTypes";

//Get All Tips
const initialState = {
    tips: [],
    error: {},
    loading: false
}
export const GetManageTips = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TIPS:
            return {
                ...state,
                loading: true,
            }

        case GET_ALL_TIPS_SUCCESS:
            return {
                ...state,
                tips: action.payload,
                loading: false
            }

        case GET_ALL_TIPS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//Add Admin 
const addTipInitialState = {
    error: "",
    success: "",
    loading: false
}
export const AddNewTips = (state = addTipInitialState, action) => {
    switch (action.type) {
        case ADD_TIPS:
            state = {
                error: "",
                loading: false,
                success: ""
            }
            break
        case ADD_TIPS_SUCCESS:
            state = { ...state, success: action.payload, loading: false }
            break
        case ADD_TIPS_FAIL:
            state = { ...state, error: action.payload, loading: false }

    }
    return state
}

//Get Tips Id
const getTipsIdInitialState = {
    error: "",
    success: "",
}
export const GetTipsById = (state = getTipsIdInitialState, action) => {
    switch (action.type) {
        case GET_TIPS_ID_SUCCESS:
            return {
                ...state,
                tipsData: action.payload,
            }

        case GET_TIPS_ID_FAIL:
            return {
                ...state,
                error: action.payload,
            }
    }
    return state
}

//Change Tips Status
const statusInitialState = {
    error: "",
    success: "",
    loader: false,
}
export const TipsStatus = (state = statusInitialState, action) => {
    switch (action.type) {
        case TIPS_STATUS:
            state = {
                ...state,
                success: "",
                loader: true,
            }
            break
        case TIPS_STATUS_SUCCESS:
            state = { ...state, tips: action.payload, loader: false, }
            break
        case TIPS_STATUS_FAIL:
            state = { ...state, error: action.payload, loader: false, }
            break
    }
    return state
}

//Delete Tips
const deleteInitialState = {
    error: "",
    success: "",
}

export const DeleteTips = (state = deleteInitialState, action) => {
    switch (action.type) {
        case DELETE_TIPS:
            state = {
                ...state,
            }
            break
        case DELETE_TIPS_SUCCESS:
            state = { ...state, success: action.payload }
            break
        case DELETE_TIPS_FAIL:
            state = { ...state, error: action.payload }
            break
    }
    return state
}

//Get TipType
const typeInitialState = {
    tipType: [],
    error: {},
    loading: false
}
export const GetTipType = (state = typeInitialState, action) => {
    switch (action.type) {
        case GET_TIPTYPE:
            return {
                ...state,
                loading: true,
            }

        case GET_TIPTYPE_SUCCESS:
            return {
                ...state,
                tipType: action.payload,
                loading: false
            }

        case GET_TIPTYPE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//Close tip
const CloseTipState = {
    data: null,
    error: null,
    loading: false
}
export const CloseTip = (state = CloseTipState, action) => {
    switch (action.type) {
        case CLOSE_TIP:
            return {
                data: null,
                error: null,
                loading: true,
            }

        case CLOSE_TIP_SUCCESS:
            return {
                error: null,
                data: action.payload,
                loading: false
            }

        case CLOSE_TIP_FAIL:
            return {
                data: null,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//tip target status change
const targetTipState = {
    data: null,
    error: null,
    loading: false
}
export const SetTipTarget = (state = targetTipState, action) => {
    switch (action.type) {
        case TIP_TARGET_STATUS_CHANGE:
            return {
                data: null,
                error: null,
                loading: true,
            }

        case TIP_TARGET_STATUS_CHANGE_SUCCESS:
            return {
                error: null,
                data: action.payload,
                loading: false
            }

        case TIP_TARGET_STATUS_CHANGE_FAIL:
            return {
                data: null,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}