import React from 'react';
// import Lottie from 'react-lottie';
// import lottie from "lottie-web";
// import NoData from '../../assets/lottie/no-data.json';
import NoData from "../../assets/images/empty-box.png";
const NoDataFound = () => {
    // lottie.loadAnimation({
    //     container: document.querySelector("#datanotfound"),
    //     animationData: NoData,
    //     renderer: "svg", // "canvas", "html"
    //     loop: true, // boolean
    //     autoplay: true, // boolean
    //  }, []);
    return (
        <div className='no-data'>
             {/* <div id='datanotfound'></div> */}
             <img src={NoData} alt={"No Data"}/>
             <p className='text-center font-size-15'>No data is available</p>
        </div>
    );
};

export default NoDataFound;