import React from 'react';
import moment from 'moment/moment';
import singleStar from '../../assets/images/svgicons/single-star.svg';

const Comments = ({ comments }) => {
    return (
        <div className='comments-group'>
            <div>
                {comments && comments?.map((item) => {
                    return (
                        <div className='comments-items' key={item?.userId?._id}>
                            <div className='cmt-head'>
                                <span>{item?.rating} <img src={singleStar} alt='singleStar' /></span>
                                <h4>{item?.userId?.firstName}{' '}{item?.userId?.lastName}</h4>
                            </div>
                            <div className='cmt-body'>
                                <p>{item?.review}</p>

                                <div className=''>
                                    <span>{moment(item?.updatedAt).format('ll')}</span>
                                    <span className='ps-3'>{moment(item?.updatedAt).format('LT')}</span>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    );
};

export default Comments;