import {
    GET_CATEGORY,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
    GET_SUB_CATEGORY,
    GET_SUB_CATEGORY_SUCCESS,
    GET_SUB_CATEGORY_FAIL,
    ADD_NEW_CATEGORY,
    ADD_NEW_CATEGORY_SUCCESS,
    ADD_NEW_CATEGORY_FAIL,
    ADD_SUB_CATEGORY,
    ADD_SUB_CATEGORY_SUCCESS,
    ADD_SUB_CATEGORY_FAIL,
    GET_SUBCATEGORY_ID_SUCCESS,
    GET_SUBCATEGORY_ID_FAIL,
    GET_CATEGORY_ID_SUCCESS,
    GET_CATEGORY_ID_FAIL,
    GET_SUBCATEGORY_STATUS,
    GET_SUBCATEGORY_STATUS_SUCCESS,
    GET_SUBCATEGORY_STATUS_FAIL,
    GET_CATEGORY_STATUS,
    GET_CATEGORY_STATUS_SUCCESS,
    GET_CATEGORY_STATUS_FAIL,
    GET_SUBCATEGORY_DEFAULT_SUCCESS,
    GET_SUBCATEGORY_DEFAULT_FAIL,
    GET_CATEGORY_DEFAULT_SUCCESS,
    GET_CATEGORY_DEFAULT_FAIL,
    GET_CATEGORY_DEFAULT,
    GET_SUBCATEGORY_DEFAULT,
} from "./actionTypes";

const initialState = {
    category: [],
    error: null,
    loading: false
}
export const GetMainCategory = (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORY:
            return {
                ...state,
                loading: true,
            }

        case GET_CATEGORY_SUCCESS:
            return {
                ...state,
                category: action.payload,
                loading: false
            }

        case GET_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//add category

const addCategoryInitialState = {
    error: "",
    success: "",
    loading: false
}
export const AddCategory = (state = addCategoryInitialState, action) => {
    switch (action.type) {
        case ADD_NEW_CATEGORY:
            return {
                ...state,
                loading: true,
            }

        case ADD_NEW_CATEGORY_SUCCESS:
            return {
                ...state,
                category: action.payload,
                loading: false
            }

        case ADD_NEW_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//Get Category by Id 

const GetCategoryInitialState = {
    error: "",
    success: "",
}
export const GetCategoryById = (state = GetCategoryInitialState, action) => {
    switch (action.type) {
        case GET_CATEGORY_ID_SUCCESS:
            return {
                ...state,
                label: action.payload,
            }

        case GET_CATEGORY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
            }
    }
    return state
}

//Get Category Status 
const categoryStatusInitialState = {
    error: "",
    success: "",
    loader: false,
}

export const ChangeCategoryStatus = (state = categoryStatusInitialState, action) => {
    switch (action.type) {
        case GET_CATEGORY_STATUS:
            return {
                ...state,
                success: "",
                loader: true,
            };
        case GET_CATEGORY_STATUS_SUCCESS:
            return {
                ...state,
                category: action.payload,
                loader: false,
            };
        case GET_CATEGORY_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                loader: false
            };
        default:
            return state;
    }
}
//Sub Category
const subCatinitialState = {
    subcat: [],
    error: {},
    loading: false
}
export const GetSubCategory = (state = subCatinitialState, action) => {
    switch (action.type) {
        case GET_SUB_CATEGORY:
            return {
                ...state,
                loading: true,
            }

        case GET_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                subcat: action.payload,
                loading: false
            }

        case GET_SUB_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//Add SubCategory
const addsubCategoryInitialState = {
    error: "",
    success: "",
    loading: false
}
export const AddSubCategory = (state = addsubCategoryInitialState, action) => {
    switch (action.type) {
        case ADD_SUB_CATEGORY:
            return {
                ...state,
                loading: true,
            }

        case ADD_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                subcategory: action.payload,
                loading: false
            }

        case ADD_SUB_CATEGORY_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state
    }
}

//Get SubCategory By Id 
const GetSubCategoryInitialState = {
    error: "",
    success: "",
}
export const GetSubCategoryById = (state = GetSubCategoryInitialState, action) => {
    switch (action.type) {
        case GET_SUBCATEGORY_ID_SUCCESS:
            return {
                ...state,
                subcategory: action.payload,
            }

        case GET_SUBCATEGORY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
            }
    }
    return state
}
//Get SubCategory Status 
const subcategoryStatusInitialState = {
    error: "",
    success: "",
    loader: false,
}

export const SubCategoryStatus = (state = subcategoryStatusInitialState, action) => {
    switch (action.type) {
        case GET_SUBCATEGORY_STATUS:
            return {
                ...state,
                success: "",
                loader: true,
            };
        case GET_SUBCATEGORY_STATUS_SUCCESS:
            return {
                ...state,
                subcategory: action.payload,
                loader: false,
            };
        case GET_SUBCATEGORY_STATUS_FAIL:
            return {
                ...state,
                error: action.payload,
                loader: false
            };
        default:
            return state;
    }
}

//get subcategory by default 

const GetSubCategoryDefaultInitialState = {
    error: "",
    success: "",
    loader: false

}
export const GetSubCategoryDefaultById = (state = GetSubCategoryDefaultInitialState, action) => {
    switch (action.type) {
        case GET_SUBCATEGORY_DEFAULT:
            return {
                ...state,
                success: "",
                loader: true,

            }
        case GET_SUBCATEGORY_DEFAULT_SUCCESS:
            return {
                ...state,
                subcategory: action.payload,
                loader:false
            }

        case GET_SUBCATEGORY_DEFAULT_FAIL:
            return {
                ...state,
                error: action.payload,
                loader:false
            }
    }
    return state
}

//category default 

const GetCategoryDefaultInitialState = {
    error: "",
    success: "",
    loader: false,
}
export const GetCategoryDefaultById = (state = GetCategoryDefaultInitialState, action) => {

    switch (action.type) {
        case GET_CATEGORY_DEFAULT:
            return {
                ...state,
                success: "",
                loader: true,

            }
        case GET_CATEGORY_DEFAULT_SUCCESS:
            return {
                ...state,
                category: action.payload,
                loader: false,
            }
        case GET_CATEGORY_DEFAULT_FAIL:
            return {
                ...state,
                error: action.payload,
                loader: false,
            }
    }
    return state
}