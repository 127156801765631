import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Form, FormFeedback, Row, Button, Label, Input, UncontrolledTooltip } from 'reactstrap';
import Breadcrumb from 'components/Common/Breadcrumb';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSettings, settingsUpdate } from 'store/actions';
import AuthLoader from 'components/Common/AuthLoader';
import { Link } from 'react-router-dom';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import TradingMeter from './components/TradingMeter';
import SocialMediaLinks from './components/SocialMediaLinks';

const Settings = () => {
    document.title = "Settings | FiDeal AdminPanel";

    const dispatch = useDispatch()

    const [settingsEdit, setSettingsEdit] = useState(true)
    const [adressEdit, setadressEdit] = useState(true)
    const [settingLoader, setSettingLoader] = useState(false)
    const [addressLoader, setAddressLoader] = useState(false)
    const [isTrading, setTradingStatus] = useState()

    const getSettingsData = useSelector(state => (state?.GetSettings?.data?.settings));

    const Loader = useSelector(state => ({
        Loader: state.SettingsUpdate
    }), shallowEqual)

    const getSettingsDataFun = () => {
        dispatch(getSettings())
    }

    useEffect(() => {
        getSettingsDataFun()
    }, [])
    // useEffect(() => {
    //     if (settings?.settings?.data) {
    //         if (settings?.settings?.data?.data) {
    //             setSettingsData(settings?.settings?.data?.data[0])
    //         } else {     
    //             setSettingsData([])
    //         }
    //     }
    // }, [])
    const settingsForm = {
        email: "",
        mobileNumber: "",

    };
    const settingsFormSchema = Yup.object().shape({
        email: Yup.string().email('Enter valid email address').required("Enter email address").max(30, 'Email should not exceed 30 characters'),
        mobileNumber: Yup.string().required("Enter phone number").max(15, 'Phone number should not exceed 15 characters')
    });
    const { register, handleSubmit, setValue, getValues, reset, clearErrors, formState: { errors, }, } = useForm({
        resolver: yupResolver(settingsFormSchema),
        defaultValues: settingsForm
    });
    const onSubmit = (values) => {
        const data = {}
        data["email"] = values.email
        data["mobileNumber"] = values.mobileNumber
        data["form"] = 'settings'
        setSettingLoader(true)
        dispatch(settingsUpdate(data, setSettingsEdit, getSettingsData?._id, getSettingsDataFun))
    };
    const settingsValues = () => {
        reset(
            {
                email: getSettingsData?.email || "",
                mobileNumber: getSettingsData?.mobileNumber || "",
            }
        )
    };
    useEffect(() => {
        if (getSettingsData) {
            settingsValues();
            toEmailValue();
        }
    }, [getSettingsData])

    //ToEmail Id Changes
    const toEmailForm = {
        toEmail: ""
    };

    const toEmailFormSchema = Yup.object().shape({
        toEmail: Yup.string().email('Enter valid email address').required("Enter TO email address").max(30, 'To email should not exceed 30 characters'),
    });

    const { register: registerToemail, handleSubmit: handleSubmitToEmail, reset: resetToEmail, formState: { errors: errorspws, }, } = useForm({
        resolver: yupResolver(toEmailFormSchema),
        defaultValues: toEmailForm,
        mode: "onSubmit",
    });

    const onSubmitToEmail = (values) => {
        const data = {}
        data["toEmail"] = values.toEmail
        data["form"] = 'address'
        setAddressLoader(true)
        dispatch(settingsUpdate(data, setadressEdit, getSettingsData?._id, getSettingsDataFun))
    };
    const toEmailValue = () => {
        resetToEmail({
            toEmail: getSettingsData?.toEmail || "",
        })
    }

    useEffect(() => {
        if (!Loader?.Loader?.loading) {
            setSettingLoader(false)
            setAddressLoader(false)
        }
    }, [Loader?.Loader?.loading]);


    return (
        <div className="page-content settings">
            <div className="container-fluid">
                <Breadcrumb title="Dashboard" dashurl="/dashboard" breadcrumbItem="Settings" />

                <Row className="justify-content-between mb-4 user-info">
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <Row className="justify-content-between user-info">
                                    <Col lg='12'>
                                        <Card color="info" className="text-white-50">
                                            <CardBody>
                                                <CardTitle className="m-0 text-white">
                                                    <i className="mdi mdi-alert-circle-outline me-3" />For any queries/assistance, clients will contact to this below phone number or email address
                                                </CardTitle>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                </Row>
                                <div className='d-flex justify-content-end'>
                                    <Link to={`#`}
                                        id={"EditEnq"}
                                        className='d-flex justify-content-center align-items-center settings-action'
                                        onClick={() => {
                                            if (!settingsEdit) {
                                                reset()
                                            }
                                            setSettingsEdit(!settingsEdit)
                                        }}
                                    >
                                        <i className={`${!settingsEdit ? 'mdi mdi-close-circle' : 'bx bx-edit-alt'} px-3 font-size-16 primary-color`}></i>
                                        <UncontrolledTooltip placement="top" target={"EditEnq"}>
                                            {!settingsEdit ? 'Click to cancel' : 'Click to edit'}
                                        </UncontrolledTooltip>
                                    </Link>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="form-horizontal" >
                                    <div className="form-group mb-4">
                                        <Label className="form-label">Enquiry email address<span>*</span></Label>
                                        <input type="email" {...register("email")} disabled={settingsEdit} className={errors.email?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                        {errors.email?.message ? <p className={`invalid-feedback`} >{errors.email?.message}</p> : null}

                                    </div>
                                    <div className="form-group mb-4">
                                        <Label className="form-label">Enquiry phone number<span>*</span></Label>
                                        <input type="number" {...register("mobileNumber")} disabled={settingsEdit} className={errors.mobileNumber?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                        {errors.mobileNumber?.message ? <p className={`invalid-feedback`} >{errors.mobileNumber?.message}</p> : null}

                                    </div>
                                    <div className="text-center mt-4 pb-2">
                                        {settingLoader === true ? <div className='action-btn-loader d-flex'><AuthLoader /></div> :
                                            <PrimaryButton btnName="Save" showIcon={false} btnType={'submit'} disabled={settingsEdit} />
                                        }

                                    </div>
                                </form>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardBody>
                                <div className='addressInfo'>
                                    <AddressInfo />
                                </div>
                                <div className='d-flex justify-content-end'>
                                    <Link to={`#`}
                                        id={"addressEdit"}
                                        className='d-flex justify-content-center align-items-center settings-action'
                                        onClick={() => {
                                            if (!adressEdit) {
                                                resetToEmail()
                                            }
                                            setadressEdit(!adressEdit)
                                        }}
                                    >
                                        <i className={`${!adressEdit ? 'mdi mdi-close-circle' : 'bx bx-edit-alt'} px-3 font-size-16 primary-color`}></i>
                                        <UncontrolledTooltip placement="top" target={"addressEdit"}>
                                            {!adressEdit ? 'Click to cancel' : 'Click to edit'}
                                        </UncontrolledTooltip>
                                    </Link>
                                </div>
                                <form onSubmit={handleSubmitToEmail(onSubmitToEmail)} autoComplete="off" className="form-horizontal"
                                >
                                    <div className="form-group mb-4">
                                        <Label className="form-label">To email address<span>*</span></Label>
                                        <input type="email" {...registerToemail("toEmail")} disabled={adressEdit} className={errors.toEmail?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                        {errors.toEmail?.message ? <p className={`invalid-feedback`} >{errors.toEmail?.message}</p> : null}

                                    </div>
                                    <div className="text-center mt-4">
                                        {addressLoader === true ? <div className='action-btn-loader d-flex'><AuthLoader /></div> :
                                            <PrimaryButton btnName="Save" showIcon={false} btnType={'submit'} disabled={adressEdit} />
                                        }
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <TradingMeter tradingData={getSettingsData} />
                        {/* <SocialMediaLinks /> */}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Settings

const AddressInfo = () => {
    return <Card color="info" className="text-white-50">
        <CardBody>
            <CardTitle className="m-0 text-white">
                <i className="mdi mdi-alert-circle-outline me-3" />All admin notifications will be sent to given email address
            </CardTitle>
        </CardBody>
    </Card>
}