import React, { useEffect } from 'react'
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap'
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PrimaryButton from 'components/Common/Buttons/PrimaryButton'
import SecountryBtn from 'components/Common/Buttons/SecountryBtn'

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup"

import { AddNewFaq, getFaqById } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import PageLoader from 'components/Common/PageLoader'
import { useParams } from 'react-router-dom'

const FaqForm = props => {

    let { id } = useParams()
    document.title = `${!id ? "Add New Faq" : "Update Faq"} | FiDeal AdminPanel`
    const dispatch = useDispatch();
    const faq = useSelector(state => state?.GetFaqById?.faq?.faq);
    const loading = useSelector(state => ({ loaderstatus: state.AddFaq.loading }));

    //form values
    const FaqForm = {
        question: "",
        answer: ""
    }

    const FaqFormSchema = Yup.object().shape({
        question: Yup.string().required("Enter question").trim(),
        answer: Yup.string().required("Enter answer").trim().max(750, "Answer should not exceed 750 characters")
    })

    const { register, handleSubmit, reset, formState: { errors, }, } = useForm({
        resolver: yupResolver(FaqFormSchema),
        defaultValues: FaqForm
    });
    const onSubmit = (values) => {
        if (id) {
            dispatch(AddNewFaq(values, props.history, id))
        } else {
            dispatch(AddNewFaq(values, props.history))
        }
    }
    //get faq by Id
    const getFaqData = () => {
        dispatch(getFaqById(id))
    }
    useEffect(() => {
        if (id) {
            getFaqData()
        }
    }, [id])
    //set form values
    const setFormValues = () => {
        reset(
            {
                question: faq?.question || "",
                answer: faq?.answer || "",
            }
        )
    }
    useEffect(() => {
        if (id) {
            setFormValues()
        }
    }, [faq, id])

    return (
        <React.Fragment>
            <div className='page-content'>
                {loading.loaderstatus ? <PageLoader /> : ""}
                <Container fluid={true}>
                    <Breadcrumbs title="FAQ" dashurl="/faq" breadcrumbItem={!id ? "Add New FAQ" : "Update FAQ"} />
                    <Row>
                        <Col xl="12">
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                                className="form-horizontal" >
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md="8">
                                                <div className="mb-3">
                                                    <Label className="form-label f-size-14">Question <span>*</span></Label>
                                                    <input type="text" {...register("question")} className={errors.question?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                                    {errors.question?.message ? <p className={`invalid-feedback`} >{errors.question?.message}</p> : null}
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="8">
                                                <div className="mb-3">
                                                    <Label className="form-label f-size-14">Answer <span>*</span></Label>
                                                    <textarea type="text" {...register("answer")} className={errors.answer?.message ? 'form-control is-invalid text-area' : '' + 'form-control text-area'} />
                                                    {errors.answer?.message ? <p className={`invalid-feedback`} >{errors.answer?.message}</p> : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="mb-3 pt-3 d-flex btn-group">
                                            <PrimaryButton btnName="Save" showIcon={false} btnType={'submit'} />
                                            <SecountryBtn btnType='button' onClick={() => {
                                                if (id) {
                                                    setFormValues()
                                                } else {
                                                    reset();
                                                }
                                            }} btnName='Clear' showIcon={false} />

                                        </div>
                                    </CardBody>
                                </Card>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default FaqForm