//update settings
export const SETTINGS_UPDATE = "SETTINGS_UPDATE"
export const SETTINGS_UPDATE_SUCCESS = "SETTINGS_UPDATE_SUCCESS"
export const SETTINGS_UPDATE_FAIL = "SETTINGS_UPDATE_FAIL"
export const SETTINGS_UPDATE_LOADING = "SETTINGS_UPDATE_LOADING"

//update settings
export const GET_SETTINGS = "GET_SETTINGS"
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS"
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL"
export const GET_SETTINGS_LOADING = "GET_SETTINGS_LOADING"

//update settings
export const GET_NOTIFICATION = "GET_NOTIFICATION"
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL"
export const GET_NOTIFICATION_LOADING = "GET_NOTIFICATION_LOADING"

//Trading Meter
export const TRADINGMETER = "TRADINGMETER"
export const TRADINGMETER_SUCCESS = "TRADINGMETER_SUCCESS"
export const TRADINGMETER_FAIL = "TRADINGMETER__FAIL"

//Social links
export const GET_SOCIAL_LINKS = 'GET_SOCIAL_LINKS'
export const GET_SOCIAL_LINKS_SUCCESS = 'GET_SOCIAL_LINKS_SUCCESS'
export const GET_SOCIAL_LINKS_FAIL = 'GET_SOCIAL_LINKS_FAIL'

//update Social links
export const UPDATE_SOCIAL_LINKS = 'UPDATE_SOCIAL_LINKS'
export const UPDATE_SOCIAL_LINKS_SUCCESS = 'UPDATE_SOCIAL_LINKS_SUCCESS'
export const UPDATE_SOCIAL_LINKS_FAIL = 'UPDATE_SOCIAL_LINKS_FAIL'