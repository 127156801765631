import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_IMAGE_PATH, PASSWORD_CHANGE, UPDATE_PROFILE, UPDATE_PROFILE_IMAGE } from "./actionTypes";
import { loginPasswordChangeSuccess, loginPasswordChangeError, updateProfileError, updateProfileSuccess, updateProfileImageSuccess, updateProfileImageError, getImagePath } from "./actions";
import jwt_decode from "jwt-decode";
//Include Both Helper File with needed methods
import {
    updateProfile,
    changeLoginPassword,
    updateProfileImg,
    getDecodeImage
}
    from "../../helpers/backend_helper";
import toast from "react-hot-toast";
import { authInfoSuccess } from "store/authInfo/action";

// update profile
function* UpdateProfile({ payload: { user, history, id } }) {
    try {
        const data = {}
        data["firstName"] = user.firstName
        data["lastName"] = user.lastName
        data["mobileNumber"] = user.mobileNumber

        const response = yield call(updateProfile, data, id)
        if (response.status === 'ERROR') {
            toast.error(response.message)
        } else if (response.status === "SUCCESS") {
            toast.success(response.message)
            yield put(updateProfileSuccess(response));
            localStorage.setItem("jwt", response.token);
            localStorage.setItem("authUser", response.token)
            const decoded = jwt_decode(response?.token);
            yield put(authInfoSuccess(decoded))
        }
    } catch (error) {
        yield put(updateProfileError(error))
        toast.error(error.response.data.message)
    }
}

export function* UpdateProfileSaga() {
    yield takeEvery(UPDATE_PROFILE, UpdateProfile)
}

// password change
function* LoginPasswordChange({ payload: { data, history } }) {
    try {
        const response = yield call(changeLoginPassword, data)
        if (response?.status?.toLowerCase() === 'error') {
            yield put(loginPasswordChangeError(response))
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(loginPasswordChangeSuccess(response))
        }
    } catch (error) {
        yield put(loginPasswordChangeError(error))
        toast.error(error.response.data.message)
    }
}

export function* LoginPasswordChangeSaga() {
    yield takeEvery(PASSWORD_CHANGE, LoginPasswordChange)
}

// update profile image
function* UpdateProfileImg({ payload: { data, dispatch } }) {
    const img = {}
    img['files'] = data
    try {
        const response = yield call(updateProfileImg, { headers: { "Content-Type": "multipart/form-data" } }, img)
        if (response.status === 'ERROR') {
            toast.error(response.message)
        } else {
            const imageKey = { key: response?.profileImg }
            dispatch(getImagePath(imageKey))
            toast.success(response.message)
            localStorage.setItem("authUser", response?.token)
        }
    } catch (error) {
        yield put(updateProfileImageError(error))
    }
}

export function* UpdateProfileImageSaga() {
    yield takeEvery(UPDATE_PROFILE_IMAGE, UpdateProfileImg)
}

// get image path
function* fetchProfileImagePath({ payload: data }) {
    try {
        const res = yield call(getDecodeImage, data)
        if (res?.status === 'SUCCESS') {
            yield put(updateProfileImageSuccess(res?.data?.file))
        }
        else {
            yield put(updateProfileImageError(res.message))
            toast.error(res.message)
        }
    } catch (error) {
        yield put(updateProfileImageError(error))
    }
}

export function* getProfileImagePathSaga() {
    yield takeEvery(GET_IMAGE_PATH, fetchProfileImagePath)
}