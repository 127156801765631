import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
    Card,
    CardBody,
    Col,
    CardFooter,
    UncontrolledTooltip,
} from "reactstrap"
import { useHistory } from "react-router-dom"
import Confirmation from "../Modals/Confirmation"
// import { statusChange } from "store/actions"
// import ConfirmationModal from "components/Common/ConfirmationModal";
import alert from "../../../assets/images/svg/alert.svg";
import password from "../../../assets/images/svg/key.svg";
import check from "../../../assets/images/svg/check.svg";
import changePass from '../../../assets/images/svg/change_password.svg';
import ViewAdmin from "../Modals/ViewAdmin"
import { statusChange } from "store/actions"
// import { checkUserAsSuperAdmin } from '../constants/utils';

const AdminCard = props => {
    const { user } = props;
    const history = useHistory();
    const dispatch = useDispatch();

    const [changeStatusModal, setChangeStatusModal] = useState(false);
    const [isUserId, setUserId] = useState();
    const [viewAdmin, setviewAdmin] = useState(false);
    const [status, setStatus] = useState();
    // const [statusModal, setStatusModal] = useState(false);
    const [generatePassword, setGeneratePassword] = useState(false);

    const userInfo = useSelector(state => (state?.authInfo?.data?.user));

    const { account, gpass } = useSelector(state => ({
        account: state.changeStatus,
        gpass: state.GeneratePasswordReducer
    }))

    // useEffect(() => {
    //     if (gpass.success.status === "SUCCESS") {
    //         setGeneratePassword(false);
    //     }
    // }, [gpass])
    function activeToggleModal() {
        setChangeStatusModal(!changeStatusModal);
    }
    const changeActiveStatus = () => {
        let status
        if (user?.status === "INACTIVE") {
            status = "ACTIVE"
        } else {
            status = "INACTIVE"
        }
        dispatch(statusChange(status, isUserId));
        setChangeStatusModal(false);
    }
    // useEffect(() => {
    //     if (account.success.status === "success") {
    //         setStatusModal(false);
    //     }
    // }, [account])

    return (
        <React.Fragment>
            <Col className="p-0">
                <Card className={`text-center user-details avatar mb-0`}>
                    <CardBody>
                        <div className="user-info">
                            {user ? (
                                <div className={`avatar ${user?.status === "ACTIVE" ? "active" : "deactive"}`}>
                                    {!user.profilePath ? (
                                        <div className="avatar-sm-lg mx-auto">
                                            <span
                                                className="border-line avatar-title rounded-circle bg-soft">
                                                {user?.firstName.charAt(0).toUpperCase()}
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="avatar-sm-lg profile-image rounded-circle border-line">
                                            <img
                                                className="rounded-circle avatar-title"
                                                src={user?.profilePath}
                                                alt=""
                                            />
                                        </div>
                                    )}
                                </div>
                            ) : ""}
                            <div className="user-bio">
                                <h5 className="font-size-17 mb-0 fw-600">
                                    {user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)}

                                </h5>
                                <p className="text-muted mb-1 font-size-12 email-field" id={`email-${user._id}`}>
                                    {user.email}
                                    <UncontrolledTooltip placement="top" target={`email-${user._id}`}>
                                        {user.email}
                                    </UncontrolledTooltip>
                                </p>
                                <p className="badge badge-pill mb-0 font-size-13 py-2 px-3 badge-pill-role" id={`badge-${user._id}`}
                                >
                                    <span className="text-capitalize">
                                        {user?.role?.label}
                                        <UncontrolledTooltip placement="top" target={`badge-${user._id}`} className="text-capitalize">
                                            {user?.role?.label}
                                        </UncontrolledTooltip>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </CardBody>

                    <CardFooter className="bg-transparent border-top">
                        <div className="admin-card-footer">
                            {userInfo?.id !== user?._id &&
                                <Link to={`/admin/update-admin/${user._id}`}
                                    id={"Edit"}
                                >
                                    <i className="bx bx-edit-alt"></i>
                                    <UncontrolledTooltip placement="top" target={"Edit"}>
                                        Click to edit
                                    </UncontrolledTooltip>
                                </Link>
                            }
                            <Link to={`#`} id={"view"} onClick={() => setviewAdmin(!viewAdmin)}>
                                <i className="mdi mdi-eye" />
                                <UncontrolledTooltip placement="top" target={"view"}>
                                    Click to view
                                </UncontrolledTooltip>
                            </Link>

                            {/* <Link to={`#`} id={`generate-${user._id}`}
                                onClick={() => {
                                    setGeneratePassword(true)
                                }}>
                                <img src={changePass} />

                                <UncontrolledTooltip placement="top" target={`generate-${user._id}`}>
                                    Click to generate a new password
                                </UncontrolledTooltip>
                            </Link> */}
                            {userInfo?.id === user?._id ? (
                                ''
                            ) : (
                                <>
                                    <Link to={`#`} id={`Status${user._id}`} onClick={() => {
                                        activeToggleModal(); setUserId(user._id)
                                    }}>
                                        <i className={`bx ${user?.status === "INACTIVE" ? 'bx-lock' : 'bx-lock-open'}`} />
                                        <UncontrolledTooltip placement="top" target={`Status${user._id}`}>
                                            {user?.status === "INACTIVE" ? 'Click to Activate' : 'Click to Suspend'}
                                        </UncontrolledTooltip>
                                    </Link></>
                            )}

                        </div>


                    </CardFooter>
                </Card>

                <ViewAdmin modal={viewAdmin} setModal={setviewAdmin} user={user} />
                <Confirmation
                    showModal={changeStatusModal}
                    toggle={activeToggleModal}
                    primaryAction={changeActiveStatus}
                    modalTitle={`Confirmation!`}
                    primaryBtn={`${user?.status === "INACTIVE" ? 'Activate' : 'Suspend'}`}
                    secountBtn="Cancel"
                    description={`Are you sure, do you want to  ${user?.status === "ACTIVE" ? 'suspend' : 'activate'} this admin "${user?.firstName} ${user?.lastName}"?`}
                />
            </Col>
        </React.Fragment>
    )
}

AdminCard.propTypes = {
    user: PropTypes.object,
}

export default AdminCard
