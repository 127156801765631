import React, { useEffect, useState } from 'react';
import { Card, CardBody, Label, UncontrolledTooltip } from 'reactstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import { addTradingMeter } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AuthLoader from 'components/Common/AuthLoader';

const TradingMeter = ({ tradingData }) => {
    const dispatch = useDispatch();

    const updateTradingMeter = useSelector(state => state.TradingSettingsUpdate)

    const [settingsEdit, setSettingsEdit] = useState(true);
    const [isTrading, setIsTrading] = useState("");
    const tradingForm = {
        openTime: "",
        openTimeMM: "",
        closeTime: "",
        closeTimeMM: "",
        isEnabled: ""

    };
    const tradingFormSchema = Yup.object().shape({
        openTime: Yup.string().required("Enter email address"),
        closeTime: Yup.string().required("Enter phone number"),
    });
    const { register, handleSubmit, setValue, getValues, reset, clearErrors, formState: { errors, }, } = useForm({
        resolver: yupResolver(tradingFormSchema),
        defaultValues: tradingForm
    });
    const onSubmit = (values) => {
        const data = {}
        data["openTime"] = values?.openTime + ':' + values?.openTimeMM
        data["closeTime"] = values?.closeTime + ':' + values?.closeTimeMM
        data["isEnabled"] = values?.isEnabled
        // setSettingLoader(true)
        dispatch(addTradingMeter(data, setSettingsEdit, tradingData?._id))
    };

    const handleChange = (field, e, start, end) => {
        let newValue = e.target.value;
        if (e.target.value < start) {
            newValue = start;
        } else if (e.target.value > end) {
            newValue = end;
        }
        setValue(field, newValue);
    }
    const tradingValues = () => {
        const openTime = tradingData?.openTime?.split(":")
        const closeTime = tradingData?.closeTime?.split(":")

        reset(
            {
                openTime: openTime[0] || 0,
                openTimeMM: openTime[1] || 0,
                closeTime: closeTime[0] || 0,
                closeTimeMM: closeTime[1] || 0,
                isEnabled: tradingData?.isEnabled
            }
        )
        setIsTrading(tradingData?.isEnabled)
    };
    useEffect(() => {
        if (tradingData) {
            tradingValues()
        }
    }, [tradingData]);

    return (
        <div>
            <Card>
                <CardBody>
                    <div className="settings-headalign">

                        <h5>Trading Meter</h5>
                        <div className='d-flex justify-content-end'>
                            <Link to={`#`}
                                id={"EditTrading"}
                                className='d-flex justify-content-center align-items-center settings-action'
                                onClick={() => setSettingsEdit(!settingsEdit)}
                            >
                                <i className={`${!settingsEdit ? 'mdi mdi-close-circle' : 'bx bx-edit-alt'} px-3 font-size-16 primary-color`}></i>
                                <UncontrolledTooltip placement="top" target={"EditTrading"}>
                                    {!settingsEdit ? 'Click to cancel' : 'Click to edit'}
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="form-horizontal"
                    >
                        <div className='d-flex'>
                            {/* <div className='pe-4'>
                                <label>Enable / Disable</label>
                                <div className="square-switch">
                                    <input
                                        type="checkbox"
                                        // eslint-disable-next-line react/no-unknown-property
                                        switch="bool"
                                        id='isEnabled'
                                        checked={isTrading === "YES" ? true : false}
                                        disabled={settingsEdit}
                                        onClick={(e) => {
                                            if (!e.target.checked) {
                                                setValue("isEnabled", 'YES');
                                                setIsTrading('YES')
                                            } else {
                                                setValue("isEnabled", "NO");
                                                setIsTrading('NO')
                                            }
                                        }}
                                    />
                                    <label
                                        htmlFor={`isEnabled`}
                                        data-on-label="Yes"
                                        data-off-label="No"
                                        className={`m-0 ${settingsEdit && 'switch-disabled'}`}
                                    />
                                </div>
                            </div> */}
                            <div className='trading-grp'>
                                <div className='d-flex'>
                                    <div>
                                        <Label className="form-label">Open Time(HH:MM)<span>*</span></Label>
                                        <div className='d-flex time-set'>
                                            <input type="number" {...register("openTime")} disabled={settingsEdit} className={'form-control'} onChange={(e) => {
                                                handleChange('openTime', e, 0, 24)
                                            }} placeholder='HH' />
                                            <input type="number" {...register("openTimeMM")} disabled={settingsEdit} className={'form-control'} onChange={(e) => {
                                                handleChange('openTimeMM', e, 0, 59)
                                            }} placeholder='MM' />
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div>
                                        <Label className="form-label">Close Time(HH:MM)<span>*</span></Label>
                                        <div className='d-flex time-set'>
                                            <input type="number" {...register("closeTime")} disabled={settingsEdit} className={'form-control'} onChange={(e) => {
                                                handleChange('closeTime', e, 0, 24)
                                            }} placeholder='HH' />
                                            <input type="number" {...register("closeTimeMM")} disabled={settingsEdit} className={'form-control'} onChange={(e) => {
                                                handleChange('closeTimeMM', e, 0, 59)
                                            }} placeholder='MM' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mt-4'>
                            {updateTradingMeter?.loading ?
                                <div className='action-btn-loader d-flex'><AuthLoader /></div> :
                                <PrimaryButton btnName="Save" showIcon={false} btnType={'submit'} disabled={settingsEdit} />
                            }
                        </div>

                    </form>

                </CardBody>
            </Card>
        </div>
    );
};

export default TradingMeter;