import React from 'react';
import { Card, CardBody, Progress } from 'reactstrap';
import singleStar from '../../assets/images/svgicons/single-star.svg';
import Comments from './Comments';
const RatingProgress = ({ rating, ratingCounts, viewData }) => {

    const totalRatings = ratingCounts[`1stars`] + ratingCounts[`2stars`] + ratingCounts[`3stars`] + ratingCounts[`4stars`] + ratingCounts[`5stars`];

    const percentageOne = (ratingCounts[`1stars`] / totalRatings) * 100 || 0;
    const percentageTwo = (ratingCounts[`2stars`] / totalRatings) * 100 || 0;
    const percentageThree = (ratingCounts[`3stars`] / totalRatings) * 100 || 0;
    const percentageFour = (ratingCounts[`4stars`] / totalRatings) * 100 || 0;
    const percentageFive = (ratingCounts[`5stars`] / totalRatings) * 100 || 0;

    return (
        <Card>
            <CardBody>
                <div className='overall-rating'>
                    <div className='progress-group'>
                        <div className="animated-progess">
                            <div className='progress-item'>
                                <div className='rating-count'>
                                    <p className='m-0'>5</p>
                                    <img src={singleStar} alt='singleStar' />
                                </div>
                                <Progress
                                    value={percentageFive}
                                    className={`star5`}
                                ></Progress>
                            </div>
                        </div>
                        <div className="animated-progess">
                            <div className='progress-item'>
                                <div className='rating-count'>
                                    <p className='m-0'>4</p>
                                    <img src={singleStar} alt='singleStar' />
                                </div>
                                <Progress
                                    value={percentageFour}
                                    className={`star4`}
                                ></Progress>
                            </div>
                        </div>
                        <div className="animated-progess">
                            <div className='progress-item'>
                                <div className='rating-count'>
                                    <p className='m-0'>3</p>
                                    <img src={singleStar} alt='singleStar' />
                                </div>
                                <Progress
                                    value={percentageThree}
                                    className={`star3`}
                                ></Progress>
                            </div>
                        </div>
                        <div className="animated-progess">
                            <div className='progress-item'>
                                <div className='rating-count'>
                                    <p className='m-0'>2</p>
                                    <img src={singleStar} alt='singleStar' />
                                </div>
                                <Progress
                                    value={percentageTwo}
                                    className={`star2`}
                                ></Progress>
                            </div>
                        </div>
                        <div className="animated-progess">
                            <div className='progress-item'>
                                <div className='rating-count'>
                                    <p className='m-0'>1</p>
                                    <img src={singleStar} alt='singleStar' />
                                </div>
                                <Progress
                                    value={percentageOne}
                                    className={`star1`}
                                ></Progress>
                            </div>
                        </div>
                    </div>
                    <div className='rating-total'>
                        <div>
                            <h4>{viewData?.overallRating || 0}<img src={singleStar} alt='singleStar' className='ms-2' width={'15px'} /></h4>
                            <span>{viewData?.reviewCount || 0} Reviews</span>
                        </div>
                        {/* <div>
                            <h4>45%</h4>
                            <span>Recommended</span>
                        </div> */}
                    </div>
                </div>
                <hr />
                <div>
                    <Comments comments={rating?.length > 0 && rating} />
                </div>
            </CardBody>
        </Card>
    );
};

export default RatingProgress;