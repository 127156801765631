
import React, { useEffect } from 'react';
import { Container } from 'reactstrap'
import ReviewCard from './ReviewCard';
import ReviewCardSkelrton from 'components/Common/Skeleton/ReviewCardSkelrton';
import { getReviewDetails, getReviewList, getReviewPublish } from 'store/review/actions';
import { useDispatch, useSelector } from 'react-redux';
import NoDataFound from 'components/Common/NoDataFound';
import Pagination from 'components/Common/pagination/Pagination';
import { dataPerPage } from 'constants/dataCount';
import { useState } from 'react';
import PrimaryButton from "components/Common/Buttons/PrimaryButton"
import SecountryButton from "components/Common/Buttons/SecountryBtn"
import UnpublishDescription from 'components/Common/Modals/UnpublishDescription';

const Review = () => {
    const dispatch = useDispatch();

    const review = useSelector(state => state?.GetReviewList?.data);
    const reviewLoading = useSelector(state => state?.GetReviewList);
    const totalCount = useSelector(state => (state?.GetReviewList?.data?.totalCount));
    const reviewPublish = useSelector(state => state.GetReviewPublish);
    const userInfo = useSelector(state => (state?.authInfo?.data?.user));
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false)
    const [selectedReviewId, setSelectedReviewId] = useState(null);
    const [reviewData, setreviewData] = useState([]);
    const [sellectAll, setSelectAll] = useState(null)

    const getList = () => {
        dispatch(getReviewList(currentPage, dataPerPage))
    }


    useEffect(() => {
        getList();
    }, [reviewPublish?.data, currentPage]);

    const handleMUltipleId = (id) => {
        setreviewData(prev => {
            if (prev?.length > 0 && prev?.includes(id)) {
                return prev.filter((old) => id !== old)
            } else {
                return [...prev, id];
            }
        })
    }

    const sideEffect = () => {
        setreviewData([])
        setSelectAll(null)
    }

    const publish = (status) => {
        const data = {}
        data["status"] = status
        data['reviewId'] = sellectAll === true ? "APPROVED_ALL" : reviewData
        dispatch(getReviewPublish(data, sideEffect))
    }

    const toggle = (message) => {
        if (message === "UNPUBLISH_SUCCESS") {
            sideEffect()
        }
        setShowModal(!showModal)
    }

    const bulkSelect = (type) => {
        if (type === "SELECT_ALL") {
            setSelectAll(true)
        } else {

            setSelectAll(null)
        }
    }
    return (
        <React.Fragment>

            <div className='page-content review'>
                <Container fluid>
                    <div className='title-grp'>
                        <h1 className='page-title'>{
                            userInfo?.role === "SUPER_ADMIN" ? "View MarketGenius Rating Reviews" : "My Rating Reviews"
                        }</h1>

                        {
                            userInfo?.role === "SUPER_ADMIN" &&
                            <>
                                {
                                    reviewData?.length > 0 &&
                                    <div className="button-actions">
                                        <SecountryButton
                                            btnName="Un Publish"
                                            showIcon={false}
                                            btnType={"button"}
                                            onClick={() => {
                                                toggle()
                                            }}
                                            disabled={sellectAll ? false : reviewData?.length === 0 ? true : false}

                                        />
                                        <PrimaryButton
                                            btnName="Publish"
                                            showIcon={false}
                                            btnType={"button"}
                                            onClick={() => {
                                                publish("APPROVED");
                                            }}
                                            disabled={sellectAll ? false : reviewData?.length === 0 ? true : false}
                                        />

                                        {/*
                                     TODO FOR SELECT ALL
                                    review?.totalCount > 0 &&
                                    <SecountryButton
                                        btnName={reviewData?.length === review?.totalCount || sellectAll ? "Un select All" : "Select All"}
                                        showIcon={false}
                                        btnType={"button"}
                                        onClick={() => bulkSelect(reviewData?.length === review?.totalCount || sellectAll ? "UNSELECT_ALL" : "SELECT_ALL")}
                                        disabled={review?.totalCount === 0 ? true : false}

                                    />
                                    */ }

                                    </div>
                                }

                            </>
                        }
                    </div>
                    <div className='cardContent'>
                        {reviewLoading?.loading === true ? <>
                            {[...Array(9)].map((item, index) => {
                                return <ReviewCardSkelrton key={index} />;
                            })} </> : review?.ratings?.map((items, index) => {
                                return <ReviewCard items={items} key={index} onCheckboxChange={(id) => setSelectedReviewId(id)} handleMUltipleId={handleMUltipleId} sellectAll={sellectAll} />
                            })
                        }
                    </div>
                    {review?.ratings?.length === 0 && <NoDataFound />}
                    <div>{
                        totalCount > dataPerPage &&
                        <Pagination
                            className="pagination-bar custom-pagination"
                            currentPage={currentPage}
                            totalCount={totalCount}
                            pageSize={dataPerPage}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    }
                    </div>
                </Container>
                <UnpublishDescription
                    showModal={showModal}
                    toggle={toggle}
                    modalTitle={"Reason for Unpublish"}
                    reviewId={reviewData}
                    sellectAll={sellectAll}
                />
            </div>
        </React.Fragment>
    )
}

export default Review;